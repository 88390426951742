import React, { useEffect, useState } from "react";
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonCardSubtitle, IonRow, IonIcon } from "@ionic/react";
import { call, earthSharp, locationSharp, closeCircle } from "ionicons/icons";
import { Event, Contact } from "../../../models";
import { formatAddress } from "../../../utilities/address/FormatAddress";
import { getContactById } from "../../../utilities/contact/Contact";
import Spinner from "../../Spinners/Spinner";
import { formatPhoneNumberForDisplay } from "../../../utilities/contact/FormatPhoneNumber";

interface EventInfoSectionProps {
    event: Event;
}

const EventInfoSection: React.FC<EventInfoSectionProps> = ({ event }) => {
    const [eventContacts, setEventContacts] = useState<Contact[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    async function getContact(contactId: string): Promise<Contact | undefined> {
        const queryResult = await getContactById(contactId);
        if (queryResult.isSuccess) {
            return queryResult.result;
        }
    }
    
    async function getEventContacts(contactIds: (string | null)[]) {
        setIsLoading(true);
        const contactArray: Contact[] = [];
        for (let i = 0; i < contactIds.length; i++) {
            const contactId = contactIds[i];
            if (contactId) {
                const contact = await getContact(contactId);
                if (contact) contactArray.push(contact);
            }
        }
        setEventContacts(contactArray);
        setIsLoading(false);
    }

    useEffect(() => {
        if (event?.contactIds) {
            getEventContacts(event.contactIds);
        } else if (event?.contactId) {
            getEventContacts([event.contactId]);
        }
    }, [event]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <IonRow className="info-section-boarder-rd">
            <h1 className="info-card-descriptor-rd">Event Info</h1>
            <IonCard color="white" className="info-card-container-rd">
                <IonCardHeader className="info-card-header-rd">
                    <IonCardSubtitle className="info-card-subtitle-rd">Management</IonCardSubtitle>
                    <IonCardTitle className="info-card-title-rd">{event?.name || "Event Location Name"}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="info-card-content-rd">
                    <IonRow className="info-card-rows-rd">
                        <IonIcon className="info-card-icon-rd" icon={locationSharp}></IonIcon>
                        <div className="info-card-text-container">
                            <p className="info-card-text-rd">{event?.address ? formatAddress(event.address) : "Not Provided"}</p>
                        </div>
                    </IonRow>
                    {eventContacts.length > 0 ? (
                        eventContacts.map((contact, index) => (
                            <React.Fragment key={index}>
                                <IonRow className="info-card-rows-rd">
                                    <IonIcon className="info-card-icon-rd" icon={call}></IonIcon>
                                    <div className="info-card-text-container-rd">
                                        <p className="info-card-text-rd">{`${contact.name} | ` || ""} {formatPhoneNumberForDisplay(contact.work) || "Phone Number Not Provided"}</p>
                                    </div>
                                </IonRow>
                                <IonRow className="info-card-rows-rd">
                                    <IonIcon className="info-card-icon-rd" icon={earthSharp}></IonIcon>
                                    <div className="info-card-text-container-rd">
                                        {contact.website ? (
                                            <a href={contact.website} target="_blank" rel="noopener noreferrer" className="info-card-text-rd">
                                                {contact.website}
                                            </a>
                                        ) : (
                                            <p className="info-card-text-rd">Website Not Provided</p>
                                        )}
                                    </div>
                                </IonRow>
                            </React.Fragment>
                        ))
                    ) : (
                        <IonRow className="info-card-rows-rd">
                            <IonIcon className="info-card-icon-rd" icon={closeCircle}></IonIcon>
                            <p className="info-card-text-rd">No Contact Info Provided</p>
                        </IonRow>
                    )}
                </IonCardContent>
            </IonCard>
        </IonRow>
    );
};

export default EventInfoSection;
