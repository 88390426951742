// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CountryCode = {
  "AD": "AD",
  "AE": "AE",
  "AF": "AF",
  "AG": "AG",
  "AI": "AI",
  "AL": "AL",
  "AM": "AM",
  "AO": "AO",
  "AQ": "AQ",
  "AR": "AR",
  "AS": "AS",
  "AT": "AT",
  "AU": "AU",
  "AW": "AW",
  "AX": "AX",
  "AZ": "AZ",
  "BA": "BA",
  "BB": "BB",
  "BD": "BD",
  "BE": "BE",
  "BF": "BF",
  "BG": "BG",
  "BH": "BH",
  "BI": "BI",
  "BJ": "BJ",
  "BL": "BL",
  "BM": "BM",
  "BN": "BN",
  "BO": "BO",
  "BQ": "BQ",
  "BR": "BR",
  "BS": "BS",
  "BT": "BT",
  "BV": "BV",
  "BW": "BW",
  "BY": "BY",
  "BZ": "BZ",
  "CA": "CA",
  "CC": "CC",
  "CD": "CD",
  "CF": "CF",
  "CG": "CG",
  "CH": "CH",
  "CI": "CI",
  "CK": "CK",
  "CL": "CL",
  "CM": "CM",
  "CN": "CN",
  "CO": "CO",
  "CR": "CR",
  "CU": "CU",
  "CV": "CV",
  "CW": "CW",
  "CX": "CX",
  "CY": "CY",
  "CZ": "CZ",
  "DE": "DE",
  "DJ": "DJ",
  "DK": "DK",
  "DM": "DM",
  "DO": "DO",
  "DZ": "DZ",
  "EC": "EC",
  "EE": "EE",
  "EG": "EG",
  "EH": "EH",
  "ER": "ER",
  "ES": "ES",
  "ET": "ET",
  "FI": "FI",
  "FJ": "FJ",
  "FK": "FK",
  "FM": "FM",
  "FO": "FO",
  "FR": "FR",
  "GA": "GA",
  "GB": "GB",
  "GD": "GD",
  "GE": "GE",
  "GF": "GF",
  "GG": "GG",
  "GH": "GH",
  "GI": "GI",
  "GL": "GL",
  "GM": "GM",
  "GN": "GN",
  "GP": "GP",
  "GQ": "GQ",
  "GR": "GR",
  "GS": "GS",
  "GT": "GT",
  "GU": "GU",
  "GW": "GW",
  "GY": "GY",
  "HK": "HK",
  "HM": "HM",
  "HN": "HN",
  "HR": "HR",
  "HT": "HT",
  "HU": "HU",
  "ID": "ID",
  "IE": "IE",
  "IL": "IL",
  "IM": "IM",
  "IN": "IN",
  "IO": "IO",
  "IQ": "IQ",
  "IR": "IR",
  "IS": "IS",
  "IT": "IT",
  "JE": "JE",
  "JM": "JM",
  "JO": "JO",
  "JP": "JP",
  "KE": "KE",
  "KG": "KG",
  "KH": "KH",
  "KI": "KI",
  "KM": "KM",
  "KN": "KN",
  "KP": "KP",
  "KR": "KR",
  "KW": "KW",
  "KY": "KY",
  "KZ": "KZ",
  "LA": "LA",
  "LB": "LB",
  "LC": "LC",
  "LI": "LI",
  "LK": "LK",
  "LR": "LR",
  "LS": "LS",
  "LT": "LT",
  "LU": "LU",
  "LV": "LV",
  "LY": "LY",
  "MA": "MA",
  "MC": "MC",
  "MD": "MD",
  "ME": "ME",
  "MF": "MF",
  "MG": "MG",
  "MH": "MH",
  "MK": "MK",
  "ML": "ML",
  "MM": "MM",
  "MN": "MN",
  "MO": "MO",
  "MP": "MP",
  "MQ": "MQ",
  "MR": "MR",
  "MS": "MS",
  "MT": "MT",
  "MU": "MU",
  "MV": "MV",
  "MW": "MW",
  "MX": "MX",
  "MY": "MY",
  "MZ": "MZ",
  "NA": "NA",
  "NC": "NC",
  "NE": "NE",
  "NF": "NF",
  "NG": "NG",
  "NI": "NI",
  "NL": "NL",
  "NO": "NO",
  "NP": "NP",
  "NR": "NR",
  "NU": "NU",
  "NZ": "NZ",
  "OM": "OM",
  "PA": "PA",
  "PE": "PE",
  "PF": "PF",
  "PG": "PG",
  "PH": "PH",
  "PK": "PK",
  "PL": "PL",
  "PM": "PM",
  "PN": "PN",
  "PR": "PR",
  "PS": "PS",
  "PT": "PT",
  "PW": "PW",
  "PY": "PY",
  "QA": "QA",
  "RE": "RE",
  "RO": "RO",
  "RS": "RS",
  "RU": "RU",
  "RW": "RW",
  "SA": "SA",
  "SB": "SB",
  "SC": "SC",
  "SD": "SD",
  "SE": "SE",
  "SG": "SG",
  "SH": "SH",
  "SI": "SI",
  "SJ": "SJ",
  "SK": "SK",
  "SL": "SL",
  "SM": "SM",
  "SN": "SN",
  "SO": "SO",
  "SR": "SR",
  "SS": "SS",
  "ST": "ST",
  "SV": "SV",
  "SX": "SX",
  "SY": "SY",
  "SZ": "SZ",
  "TC": "TC",
  "TD": "TD",
  "TF": "TF",
  "TG": "TG",
  "TH": "TH",
  "TJ": "TJ",
  "TK": "TK",
  "TL": "TL",
  "TM": "TM",
  "TN": "TN",
  "TO": "TO",
  "TR": "TR",
  "TT": "TT",
  "TV": "TV",
  "TW": "TW",
  "TZ": "TZ",
  "UA": "UA",
  "UG": "UG",
  "UM": "UM",
  "US": "US",
  "UY": "UY",
  "UZ": "UZ",
  "VA": "VA",
  "VC": "VC",
  "VE": "VE",
  "VG": "VG",
  "VI": "VI",
  "VN": "VN",
  "VU": "VU",
  "WF": "WF",
  "WS": "WS",
  "YE": "YE",
  "YT": "YT",
  "ZA": "ZA",
  "ZM": "ZM",
  "ZW": "ZW"
};

const OrganizationType = {
  "INDIVIDUAL": "individual",
  "COMPANY": "company",
  "NONPROFIT": "nonprofit"
};

const MembershipTypeCategory = {
  "INDIVIDUAL": "individual",
  "GROUP": "group",
  "HORSE": "horse"
};

const MembershipTypeSchedule = {
  "MONTHLY": "monthly",
  "SEASON": "season",
  "ANNUAL": "annual",
  "LIFE": "life"
};

const TeamRole = {
  "HEAD_COACH": "headCoach",
  "ASSISTANT_COACH": "assistantCoach",
  "PARENT_VOLUNTEER": "parentVolunteer",
  "ATHLETE": "athlete",
  "PARENT": "parent"
};

const Gender = {
  "MALE": "Male",
  "FEMALE": "Female",
  "OTHER": "Other",
  "PREFER_NOT_TO_SAY": "Prefer_Not_To_Say"
};

const AuditorStatus = {
  "UNVERIFIED": "unverified",
  "VERIFIED": "verified",
  "FAILED": "failed",
  "STOPPED": "stopped"
};

const BlockStatus = {
  "VALID": "valid",
  "INVALID": "invalid",
  "EXPIRED": "expired",
  "EMPTY": "empty"
};

const ChargeStatusOption = {
  "INCOMPLETE_INFO": "incomplete_info",
  "UNCAPTURED": "uncaptured",
  "INITIATED": "initiated",
  "SUCCESS": "success",
  "ERROR": "error",
  "VOID": "void",
  "REFUNDED": "refunded"
};

const PaymentMethodType = {
  "CHECK": "check",
  "DEBIT_CARD": "debit_card",
  "CREDIT_CARD": "credit_card",
  "ACH": "ach",
  "VENMO": "venmo",
  "GIFT_CARD": "gift_card"
};

const EntryOption = {
  "CLASS_ONLY": "classOnly",
  "DIVISION_ONLY": "divisionOnly",
  "CLASS_OR_DIVISION": "classOrDivision"
};

const JudgeType = {
  "R": "r",
  "S": "S",
  "C": "C",
  "I": "I",
  "O": "O"
};

const JudgeLicense = {
  "EVENTING": "Eventing",
  "EVENTING_CD": "Eventing_CD",
  "EVENTING_JCD": "Eventing_JCD",
  "EVENTING_TD": "Eventing_TD",
  "FEI_DRESSAGE": "FEI_Dressage",
  "FEI_EVENTING": "FEI_Eventing",
  "FEI_VETERINARIAN": "FEI_Veterinarian",
  "HUNTER": "Hunter",
  "HUNTER_BREEDING": "Hunter_Breeding",
  "HUNTER_CD": "Hunter_CD",
  "HUNTER_JUMPING_SEAT_EQUITATION": "Hunter_Jumping_Seat_Equitation",
  "JUMPER": "Jumper",
  "JUMPER_CD": "Jumper_CD",
  "WESTERN": "Western",
  "WESTERN_DRESSAGE": "Western_Dressage"
};

const ClinicianStatus = {
  "AVAILABLE": "AVAILABLE",
  "PENDING": "PENDING",
  "PAUSED": "PAUSED",
  "INACTIVE": "INACTIVE",
  "BANNED": "BANNED"
};

const FeedbackStatus = {
  "UNPAID": "unpaid",
  "PENDING": "pending",
  "DRAFT": "draft",
  "COMPLETE": "complete",
  "DECLINED": "declined",
  "ERROR": "error"
};

const SubmissionStatus = {
  "UNPAID": "unpaid",
  "PENDING": "pending",
  "COMPLETE": "complete",
  "DECLINED": "declined",
  "VOID": "void",
  "ERROR": "error"
};

const InvitationStatus = {
  "PENDING": "pending",
  "ACCEPTED": "accepted",
  "DECLINED": "declined"
};

const Title = {
  "MR": "Mr",
  "MRS": "Mrs",
  "MS": "Ms",
  "MX": "Mx"
};

const PostStatus = {
  "DRAFT": "DRAFT",
  "PUBLISHED": "PUBLISHED"
};

const TeamStatus = {
  "PENDING": "pending",
  "IN_PROGRESS": "inProgress",
  "ACTIVE": "active",
  "INACTIVE": "inactive"
};

const TeamPermissionLevel = {
  "ADMIN": "admin",
  "MEMBER": "member"
};

const TextStatus = {
  "PENDING": "pending",
  "SENT": "sent",
  "FAILED": "failed"
};

const { Address, Alert, Event, Organization, Contact, EventOfficial, Auditor, Person, Membership, OrganizationMembershipType, Season, Rider, Barn, BarnMember, Horse, Owner, Trainer, PersonalInformation, BacklogEventResult, OrganizationDivision, Division, PointTable, PrizeMoneyTable, OrganizationClass, Class, Block, Charge, ChargeStatus, ClassOrder, EventClass, EventDivision, EventResult, EventEntry, StablingRequest, EventStallType, EventStallSet, Payer, PaymentMethod, BeddingRequest, TaxRate, EventRulesAgreement, EventRules, EventClassEntry, EventFee, Fee, Clinician, EventAdd, EventAlertSetting, EventAuditor, EventAuditorFilter, OrganizationAuditor, EventFilter, EventBeddingType, EventBreak, EventDay, EventEntryFee, EventEntryNumbers, EventInvoice, EventJudge, EventRing, Ring, EventScratch, Feedback, SubmissionFeedback, Submission, GameInput, Pedigree, MeasurementRecord, HorseShow, HorseShowRating, Invitation, OrganizationBeddingType, OrganizationStallSet, OrganizationStallType, PersonName, Poll, Response, Post, PromoCode, ScheduleItem, Team, TeamMember, Text, OrganizationMembershipTypeSeasons, S3Object, OrganizationOptions, Logo, EventOptions, PaymentOptions, InvoiceOptions, EntryOptions, ApplicationFields, ApplicationOptions, OrganizationDocument, OrganizationRule, OrganizationAgreements, DivisionRules, PointTier, PrizeMoneyTiers, JudgeRating } = initSchema(schema);

export {
  Address,
  Alert,
  Event,
  Organization,
  Contact,
  EventOfficial,
  Auditor,
  Person,
  Membership,
  OrganizationMembershipType,
  Season,
  Rider,
  Barn,
  BarnMember,
  Horse,
  Owner,
  Trainer,
  PersonalInformation,
  BacklogEventResult,
  OrganizationDivision,
  Division,
  PointTable,
  PrizeMoneyTable,
  OrganizationClass,
  Class,
  Block,
  Charge,
  ChargeStatus,
  ClassOrder,
  EventClass,
  EventDivision,
  EventResult,
  EventEntry,
  StablingRequest,
  EventStallType,
  EventStallSet,
  Payer,
  PaymentMethod,
  BeddingRequest,
  TaxRate,
  EventRulesAgreement,
  EventRules,
  EventClassEntry,
  EventFee,
  Fee,
  Clinician,
  EventAdd,
  EventAlertSetting,
  EventAuditor,
  EventAuditorFilter,
  OrganizationAuditor,
  EventFilter,
  EventBeddingType,
  EventBreak,
  EventDay,
  EventEntryFee,
  EventEntryNumbers,
  EventInvoice,
  EventJudge,
  EventRing,
  Ring,
  EventScratch,
  Feedback,
  SubmissionFeedback,
  Submission,
  GameInput,
  Pedigree,
  MeasurementRecord,
  HorseShow,
  HorseShowRating,
  Invitation,
  OrganizationBeddingType,
  OrganizationStallSet,
  OrganizationStallType,
  PersonName,
  Poll,
  Response,
  Post,
  PromoCode,
  ScheduleItem,
  Team,
  TeamMember,
  Text,
  OrganizationMembershipTypeSeasons,
  CountryCode,
  OrganizationType,
  MembershipTypeCategory,
  MembershipTypeSchedule,
  TeamRole,
  Gender,
  AuditorStatus,
  BlockStatus,
  ChargeStatusOption,
  PaymentMethodType,
  EntryOption,
  JudgeType,
  JudgeLicense,
  ClinicianStatus,
  FeedbackStatus,
  SubmissionStatus,
  InvitationStatus,
  Title,
  PostStatus,
  TeamStatus,
  TeamPermissionLevel,
  TextStatus,
  S3Object,
  OrganizationOptions,
  Logo,
  EventOptions,
  PaymentOptions,
  InvoiceOptions,
  EntryOptions,
  ApplicationFields,
  ApplicationOptions,
  OrganizationDocument,
  OrganizationRule,
  OrganizationAgreements,
  DivisionRules,
  PointTier,
  PrizeMoneyTiers,
  JudgeRating
};