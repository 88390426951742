import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, GameInput, Person } from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Spinner from "../../../../../components/Spinners/Spinner";
import { FormGroup, Input, Table } from "reactstrap";
import { addCircleOutline, close, createOutline, phonePortraitOutline } from "ionicons/icons";
import { createGameInput, getGameInputsByEvent, updateGameInput } from "../../../../../utilities/gameInput/GameInput";
import { CreateGameInputInput, UpdateGameInputInput } from "../../../../../API";
import { getPersonByPersonId } from "../../../../../utilities/person/Person";
import { formatDisplayName } from "../../../../../utilities/person/PersonNameFormat";
import RequiredInputIndicator from "../../../../../components/Forms/RequiredInputIndicator";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

interface FormattedTableData {
    id: string
    backNumber: string
    roundOneJudgeScore: string
    roundTwoJudgeScore: string
    totalJudgeScore: string
    gameInputs: GameInput[]
}

interface FormattedUserData {
    createdById: string
    createdByName: string
    backNumber: string
    roundOneUserScore: string
    roundTwoUserScore: string
    totalUserScore: string
    gameInputs: GameInput[]
}

const EventJudgeGameMasterPage: React.FC<EventPageProps> = ({match}) => {

    const [event, setEvent] = useState<Event>();
    const [masterGameInputs, setMasterGameInputs] = useState<GameInput[]>([]);
    const [formattedData, setFormattedData] = useState<FormattedTableData[] | null | undefined>();
    const [formattedUserData, setFormattedUserData] = useState<FormattedUserData[] | null | undefined>();
    const [selectedRow, setSelectedRow] = useState<FormattedTableData | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [backNumber, setBackNumber] = useState<number | null | undefined>(null);
    const [score, setScore] = useState<number | null | undefined>(null);
    const [round, setRound] = useState("1");

    const [showEditModal, setShowEditModal] = useState(false);
    const [round1Score, setRound1Score] = useState<number | null | undefined>(null);
    const [round2Score, setRound2Score] = useState<number | null | undefined>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    async function getEvent() {
        const queryResult = await getEventById(match.params.id);
        if (queryResult.isSuccess) {
            const foundEvent: Event = queryResult.result;
            setEvent(foundEvent);
            return foundEvent;
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getPreviousGameInputs(e: Event) {
        const queryResult = await getGameInputsByEvent(e.id);
        if (queryResult.isSuccess) {
            const gameInputData: GameInput[] = queryResult.result;
            return gameInputData;
        }
    }

    function formatTableData(masterGameInputList: GameInput[]) {
        let formattedDataArray: FormattedTableData[] = [];
        for (let i = 0; i < masterGameInputList.length; i++) {
            // Grab the current input from the master list
            const masterGameInput = masterGameInputList[i];
            let gameInputs = [masterGameInput];

            // If it is for a round 1 score
            if (masterGameInput.round === "1") {
                // Find the round 2 score if it exists
                const masterGameInputRound2 = masterGameInputList.find((input) => input.backNumber === masterGameInput.backNumber && input.round === "2");
                if (masterGameInputRound2) gameInputs.push(masterGameInputRound2);

                // Create the total
                const masterTotal = (masterGameInput?.score || 0) + (masterGameInputRound2?.score || 0);

                // Format all of the data
                const formattedData: FormattedTableData = {
                    id: masterGameInput.id,
                    backNumber: masterGameInput.backNumber || "",
                    roundOneJudgeScore: masterGameInput?.score ? masterGameInput.score.toString() : "",
                    roundTwoJudgeScore: masterGameInputRound2?.score ? masterGameInputRound2.score.toString() : "",
                    totalJudgeScore: masterTotal ? masterTotal.toString() : "",
                    gameInputs: gameInputs
                };
                formattedDataArray.push(formattedData);
            }
        }
        const sorted = formattedDataArray.sort((a, b) => {
            const judgeScore = (b.totalJudgeScore ? parseFloat(b.totalJudgeScore) : 0) - (a.totalJudgeScore ? parseFloat(a.totalJudgeScore) : 0);
            return judgeScore;
        });
        return sorted || formattedDataArray;
    }

    async function formatUserData(userGameInputList: GameInput[]) {
        let formattedDataArray: FormattedUserData[] = [];
        for (let i = 0; i < userGameInputList.length; i++) {
            // Grab the current input from the user list
            const userGameInput = userGameInputList[i];
            let gameInputs = [userGameInput];

            // If it is for a round 1 score
            if (userGameInput.round === "1") {
                // Find the round 2 score if it exists
                const userGameInputRound2 = userGameInputList.find((input) => input.backNumber === userGameInput.backNumber && input.round === "2");
                if (userGameInputRound2) gameInputs.push(userGameInputRound2);

                // Create the total
                const userTotal = (userGameInput?.score || 0) + (userGameInputRound2?.score || 0);

                let personName = "";
                const personQuery = await getPersonByPersonId(userGameInput.createdBy);
                if (personQuery.isSuccess) {
                    const person: Person = await personQuery.result;
                    personName = formatDisplayName(person.firstName || "", "", person.lastName || "");
                }

                // Format all of the data
                const formattedData: FormattedUserData = {
                    createdById: userGameInput.createdBy,
                    createdByName: personName,
                    backNumber: userGameInput.backNumber || "",
                    roundOneUserScore: userGameInput?.score ? userGameInput.score.toString() : "",
                    roundTwoUserScore: userGameInputRound2?.score ? userGameInputRound2.score.toString() : "",
                    totalUserScore: userTotal ? userTotal.toString() : "",
                    gameInputs: gameInputs
                };
                formattedDataArray.push(formattedData);
            }
        }
        const sorted = formattedDataArray.sort((a, b) => {
            const userName = a.createdByName.localeCompare(b.createdByName);
            const userScore = (b.totalUserScore ? parseFloat(b.totalUserScore) : 0) - (a.totalUserScore ? parseFloat(a.totalUserScore) : 0);
            return userName || userScore;
        });
        return sorted || formattedDataArray;
    }

    async function getData() {
        setIsLoading(true);
        const foundEvent = await getEvent();
        if (foundEvent) {
            const eventGameInputs = await getPreviousGameInputs(foundEvent);
            if (eventGameInputs) {
                const masterGameInputs = eventGameInputs.filter((input) => input.isMasterRecord === true);
                setMasterGameInputs(masterGameInputs);

                const formattedData = formatTableData(masterGameInputs);
                setFormattedData(formattedData);

                const userGameInputs = eventGameInputs.filter((input) => input.isMasterRecord !== true);

                const formattedUserData = await formatUserData(userGameInputs);
                setFormattedUserData(formattedUserData);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getData();
    }, [match.params.id]);

    const verifyNewScoreForm = () => {
        if (!round) {
            setError("Please select a round.");
            return false;
        }
        if (!backNumber) {
            setError("Please include an entry number (back number).");
            return false;
        }
        if (!score) {
            setError("Please include your score.");
            return false;
        }
        return true;
    }

    const handleRecordNewScore = async () => {
        setIsLoading(true);
        const input: CreateGameInputInput = {
            eventId: event?.id || "",
            backNumber: backNumber ? backNumber.toString() : "",
            score: score,
            round: round,
            isMasterRecord: true,
            createdBy: "test"
        };
        const createResult = await createGameInput(input);
        if (createResult.isSuccess) {
            const newGameInput: GameInput = createResult.result;

            // Update the master game inputs
            const updatedMasterGameInputs = (masterGameInputs || []).concat([newGameInput]);
            setMasterGameInputs((prevValue) => (prevValue || []).concat([newGameInput]));

            // Re-format the data
            const updatedTableData = formatTableData(updatedMasterGameInputs);
            setFormattedData(updatedTableData);

            setBackNumber(0);
            setScore(0.0);
            setShowModal(false);
        } else {
            const msg = "Error - the score could not be saved. " + createResult.message;
            setError(msg); 
        }
        setIsLoading(false);
    }

    const handleRowClick = (row: FormattedTableData) => {
        setSelectedRow(row);
        setBackNumber(row.backNumber ? parseInt(row.backNumber) : 0);
        setRound1Score(row.roundOneJudgeScore ? parseFloat(row.roundOneJudgeScore) : 0);
        setRound2Score(row.roundTwoJudgeScore ? parseFloat(row.roundTwoJudgeScore) : 0);
        setShowEditModal(true);
    }

    const verifyEditScoreForm = () => {
        if (!backNumber) {
            setError("Please include an entry number (back number).");
            return false;
        }
        if (round1Score === undefined || round1Score === null) {
            setError("Please include the score for at least round 1.");
            return false;
        }
        return true;
    }

    const handleUpdateScore = async (row: FormattedTableData) => {
        setIsLoading(true);
        for (let i = 0; i < row.gameInputs.length; i++) {
            const gameInput = row.gameInputs[i];
            const input: UpdateGameInputInput = {
                id: gameInput.id,
                backNumber: backNumber ? backNumber.toString() : "",
                score: (gameInput.round === "1" ? round1Score : round2Score),
            };
            await updateGameInput(input);
        }
        setBackNumber(0);
        setRound1Score(0.0);
        setRound2Score(0.0);
        setShowEditModal(false);
        await getData();
    }

    const handleSave = async () => {
        if (selectedRow) {
            const isValid = verifyEditScoreForm();
            if (isValid) {
                await handleUpdateScore(selectedRow);
            }
        } else {
            const isValid = verifyNewScoreForm();
            if (isValid) {
                await handleRecordNewScore();
            }
        }
        
    }

    return (
        <IonPage className="bg-light">
            <Header isEventPage={event ? true : false} event={event ? event : undefined} />
            <IonContent>
                <PageTitle title={event ? event.name : "Judge Game"} />
                    {event && (
                        <>
                            {error && <ErrorAlert error={error} />}
                            {isLoading ? 
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="6" className="ion-text-center">
                                        <Spinner />
                                    </IonCol>
                                </IonRow>  
                                :
                                <IonRow className="ion-justify-content-center">
                                    <IonCol sizeXs="12" sizeMd="12">
                                        <IonCard color="white" className="ion-padding">
                                            <IonCardTitle>
                                                <IonItem lines="none">
                                                    <IonIcon icon={phonePortraitOutline} slot="start"/>
                                                    <IonLabel> Ride Along</IonLabel>
                                                </IonItem>
                                            </IonCardTitle>
                                            <IonCardContent>
                                                <IonRow className="ion-justify-content-center">
                                                    <IonCol className="ion-text-center font-weight-bold">
                                                        <h1 className="text-dark">$5,000 Hunter Classic</h1>
                                                        <h2>Enter the scores from the judge below!</h2>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-justify-content-center">
                                                    <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                                        <IonIcon icon={addCircleOutline}/>
                                                        Record New Score
                                                    </IonButton>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        {(formattedData && formattedData.length > 0) ?
                                                            <>
                                                                <Table responsive hover striped>
                                                                    <thead>
                                                                        <th>Back Number</th>
                                                                        <th>Round 1 - Judge</th>
                                                                        <th>Round 2 - Judge</th>
                                                                        <th>Total - Judge</th>
                                                                        <th>Current Rank</th>
                                                                        <th>Edit</th>
                                                                    </thead>
                                                                    <tbody>
                                                                        {formattedData.map((data, index) => (
                                                                            <tr key={index}>
                                                                                <td className="ion-text-wrap">{data.backNumber}</td>
                                                                                <td className="ion-text-wrap font-weight-bold">{data.roundOneJudgeScore}</td>
                                                                                <td className="ion-text-wrap font-weight-bold">{data.roundTwoJudgeScore}</td>
                                                                                <td className="ion-text-wrap font-weight-bold">{data.totalJudgeScore}</td>
                                                                                <td className="ion-text-wrap">{index+1}</td>
                                                                                <td className="ion-text-wrap" onClick={() => handleRowClick(data)}><IonIcon icon={createOutline} /></td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </>
                                                            :
                                                            <h2>No scores have been entered yet.</h2>
                                                        }
                                                    </IonCol> 
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            }
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <IonCard color="white" className="ion-padding">
                                        <IonCardTitle>
                                            <IonItem lines="none">
                                                <IonLabel>Scores</IonLabel>
                                            </IonItem>
                                        </IonCardTitle>
                                        <IonCardContent>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol className="ion-text-center font-weight-bold">
                                                    <h2>Review the scores entered by users.</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    {(formattedUserData && formattedUserData.length > 0) ?
                                                            <>
                                                                <Table responsive hover striped>
                                                                    <thead>
                                                                        <th>User</th>
                                                                        <th>Back Number</th>
                                                                        <th>Round 1</th>
                                                                        <th>Round 2</th>
                                                                        <th>Total</th>
                                                                    </thead>
                                                                    <tbody>
                                                                        {formattedUserData.map((data, index) => (
                                                                            <tr key={index}>
                                                                                <td className="ion-text-wrap">{data.createdByName}</td>
                                                                                <td className="ion-text-wrap">{data.backNumber}</td>
                                                                                <td className="ion-text-wrap">{data.roundOneUserScore}</td>
                                                                                <td className="ion-text-wrap">{data.roundTwoUserScore}</td>
                                                                                <td className="ion-text-wrap">{data.totalUserScore}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </>
                                                            :
                                                            <h2>No scores have been entered yet.</h2>
                                                        }
                                                </IonCol> 
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    <IonModal backdropDismiss={false} isOpen={showModal} id="recordNewScoreForJudgeGameModal">
                        <IonToolbar color="light">
                            <IonTitle className="ion-text-center">
                                Record New Score
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    fill="clear"
                                    onClick={() => setShowModal(false)}
                                >
                                    <p id="closeRecordNewScoreModalBtn" className="font-weight-normal text-medium text-capitalize">
                                        <IonIcon icon={close} />
                                    </p>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <IonContent className="ion-padding">
                            {error && <ErrorAlert width="12" error={error} />}
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <FormGroup>
                                        <IonLabel className="description pl-3">Round <RequiredInputIndicator showWordRequired /></IonLabel>
                                        <Input
                                            name="type" 
                                            type="select" 
                                            value={round} 
                                            onChange={e => {
                                                setRound(e.target.value);
                                            }}
                                        >
                                            <option value="1">Round 1</option>
                                            <option value="2">Round 2</option>
                                        </Input>
                                    </FormGroup>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <FormGroup>
                                        <IonLabel className="description pl-3">Entry Number <RequiredInputIndicator showWordRequired /></IonLabel>
                                        <Input
                                            name="entryNumber" 
                                            type="number" 
                                            value={backNumber || undefined} 
                                            onChange={e => {
                                                setBackNumber(e.target.value ? parseInt(e.target.value) : undefined);
                                            }}
                                        />
                                    </FormGroup>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <FormGroup>
                                        <IonLabel className="description pl-3">Judge's Score <RequiredInputIndicator showWordRequired /></IonLabel>
                                        <Input
                                            name="judgeScore" 
                                            type="number" 
                                            value={score || undefined} 
                                            onChange={e => {
                                                setScore(e.target.value ? parseFloat(e.target.value) : undefined);
                                            }}
                                        />
                                    </FormGroup>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                    <IonButton expand="block" color="tertiary" onClick={handleSave}>
                                        Save
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonContent>
                    </IonModal>
                    <IonModal backdropDismiss={false} isOpen={showEditModal} id="editScoreForJudgeGameModal">
                        <IonToolbar color="light">
                            <IonTitle className="ion-text-center">
                                Edit Score
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    fill="clear"
                                    onClick={() => setShowEditModal(false)}
                                >
                                    <p id="closeEditScoreModalBtn" className="font-weight-normal text-medium text-capitalize">
                                        <IonIcon icon={close} />
                                    </p>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <IonContent className="ion-padding">
                            {error && <ErrorAlert width="12" error={error} />}
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <FormGroup>
                                        <IonLabel className="description pl-3">Entry Number <RequiredInputIndicator showWordRequired /></IonLabel>
                                        <Input
                                            name="entryNumber" 
                                            type="number" 
                                            value={backNumber || undefined} 
                                            onChange={e => {
                                                setBackNumber(e.target.value ? parseInt(e.target.value) : undefined);
                                            }}
                                        />
                                    </FormGroup>
                                </IonCol>
                            </IonRow>
                            <hr/>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <FormGroup>
                                        <IonLabel className="description pl-3">Round <RequiredInputIndicator showWordRequired /></IonLabel>
                                        <Input
                                            name="type" 
                                            type="select" 
                                            value={1} 
                                            disabled
                                            onChange={e => {
                                                setRound(e.target.value);
                                            }}
                                        >
                                            <option value="1">Round 1</option>
                                            <option value="2">Round 2</option>
                                        </Input>
                                    </FormGroup>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <FormGroup>
                                        <IonLabel className="description pl-3">Round 1 Score <RequiredInputIndicator showWordRequired /></IonLabel>
                                        <Input
                                            name="round1Score" 
                                            type="number" 
                                            value={round1Score || undefined} 
                                            onChange={e => {
                                                setRound1Score(e.target.value ? parseFloat(e.target.value) : undefined);
                                            }}
                                        />
                                    </FormGroup>
                                </IonCol>
                            </IonRow>
                            {(round2Score !== undefined && round2Score !== null && round2Score > 0) && (
                                <>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeXs="12" sizeMd="12">
                                            <FormGroup>
                                                <IonLabel className="description pl-3">Round <RequiredInputIndicator showWordRequired /></IonLabel>
                                                <Input
                                                    name="type" 
                                                    type="select" 
                                                    value={2} 
                                                    disabled
                                                    onChange={e => {
                                                        setRound(e.target.value);
                                                    }}
                                                >
                                                    <option value="1">Round 1</option>
                                                    <option value="2">Round 2</option>
                                                </Input>
                                            </FormGroup>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeXs="12" sizeMd="12">
                                            <FormGroup>
                                                <IonLabel className="description pl-3">Round 2 Score <RequiredInputIndicator showWordRequired /></IonLabel>
                                                <Input
                                                    name="round2Score" 
                                                    type="number" 
                                                    value={round2Score || undefined} 
                                                    onChange={e => {
                                                        setRound2Score(e.target.value ? parseFloat(e.target.value) : undefined);
                                                    }}
                                                />
                                            </FormGroup>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                    <IonButton expand="block" color="tertiary" onClick={handleSave}>
                                        Save
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonContent>
                    </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default EventJudgeGameMasterPage;