import {
    IonInput,
    IonItem,
    IonLabel,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { calculateHeightInHands, calculateHeightInInches } from "../../utilities/horse/Height";
import ErrorAlert from "../Errors/ErrorAlert";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    isRequired?: boolean
    height?: number
    onChange: Function
}

const HorseHeightInput: React.FC<_Props> = ({isRequired, height, onChange}) => {
    const [hands, setHands] = useState(0.0);
    const [inches, setInches] = useState(0.0);

    useEffect(() => {
        if (height) {
            setHands(calculateHeightInHands(height));
        } else {
            setHands(0);
        }
    }, [height]);

    const handleInput = (input: number) => {
        setHands(input);
        const heightInInches = calculateHeightInInches(input);
        setInches(heightInInches);
        onChange(heightInInches);
    }

    return (
        <>
            <IonItem color="white">
                <IonLabel position="stacked">Height in Hands{isRequired && <RequiredInputIndicator />}</IonLabel>
                <IonInput 
                    id="horseHeightInput"
                    type="number"
                    value={hands}
                    aria-required={true}
                    onIonChange={e => {
                        handleInput(parseFloat(e.detail.value!))
                    }}
                />
            </IonItem>
        </>
    );
};

export default HorseHeightInput;