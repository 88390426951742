import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { BeddingRequest, Event, EventEntry, StablingRequest } from "../../../models";
import { getBeddingRequestsByEntryId } from "../../../utilities/beddingRequest/BeddingRequest";
import { getEventEntryById } from "../../../utilities/eventEntry/EventEntry";
import { getEventStallTypeById } from "../../../utilities/eventStallType/EventStallType";
import { getStablingRequestsByEventId } from "../../../utilities/stablingRequest/StablingRequest";

interface _Props {
    event: Event
    entry: EventEntry
    onCalculate?: Function
}

interface FormattedStablingRequest {
    horseName?: String
    trainerName?: String
    type?: String
    quantity?: String
    price?: String
    tax?: String
    totalPrice?: String
    splitCost?: String
    entries?: EventEntry[]
    note?: string
}

const ViewEntryStablingRequest: React.FC<_Props> = ({entry, event, onCalculate}) => {

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [totalCost, setTotalCost] = useState<number | null | undefined>();
    const [stablingRequests, setStablingRequests] = useState<StablingRequest[] | null | undefined>();
    const [formattedStablingRequests, setFormattedStablingRequests] = useState<FormattedStablingRequest[] | null | undefined>();
    const [beddingRequests, setBeddingRequests] = useState<BeddingRequest[] | null | undefined>();

    const getTotalCost = (stablingRequests: StablingRequest[], beddingRequests?: BeddingRequest[]) => {
        let total = 0;
        if (stablingRequests) {
            stablingRequests.forEach(stablingRequest => {
                if (stablingRequest.entryIds && stablingRequest.entryIds.length > 1) {
                    // If its a split cost, divide by number of entries
                    total = total + ((stablingRequest.totalPrice || 0) / stablingRequest.entryIds.length);
                } else {
                    total = total + (stablingRequest.totalPrice || 0);
                }
            });
        }
        if (beddingRequests) {
            beddingRequests.forEach(beddingRequest => {
                if (beddingRequest.entryIds && beddingRequest.entryIds.length > 1) {
                    // If its a split cost, divide by number of entries
                    total = total + ((beddingRequest.totalPrice || 0) / beddingRequest.entryIds.length);
                } else {
                    total = total + (beddingRequest.totalPrice || 0);
                }
            });
        }
        setTotalCost(total);
    }

    const formatStablingRequests = async (stablingRequests: StablingRequest[]) => {
        let formattedArray: FormattedStablingRequest[] = [];
        if (stablingRequests && stablingRequests.length) {
            for (let i = 0; i < stablingRequests.length; i++) {
                const stablingRequest = stablingRequests[i];
                
                let formattedStablingRequest: FormattedStablingRequest = {
                    quantity: stablingRequest.quantityNeeded?.toString(),
                    totalPrice: stablingRequest.totalPrice ? stablingRequest.totalPrice.toFixed(2) : "0",
                    splitCost: stablingRequest.totalPrice ? (stablingRequest.totalPrice / stablingRequests.length).toFixed(2) : "0",
                    note: stablingRequest.requestNote || ""
                };

                if (stablingRequest.stallTypeId) {
                    const queryStallTypeResult = await getEventStallTypeById(stablingRequest.stallTypeId);
                    if (queryStallTypeResult.isSuccess) {
                        const stallType = queryStallTypeResult.result;
                        formattedStablingRequest.type = stallType?.name;
                        formattedStablingRequest.price = parseFloat(stallType?.pricePerStall || "0").toFixed(2);
                        formattedStablingRequest.tax = stallType?.taxPerStall;
                    }
                }
                
                const entryIdArray = stablingRequest.entryIds;
                let entryArray: EventEntry[] = [];
                if (entryIdArray && entryIdArray.length) {
                    for (let j = 0; j < entryIdArray.length; j++) {
                        const currentEntryId = entryIdArray[j];
                        if (currentEntryId) {
                            const queryEntryResult = await getEventEntryById(currentEntryId);
                            if (queryEntryResult.isSuccess) {
                                const entry: EventEntry = queryEntryResult.result;
                                entryArray.push(entry);
                                formattedStablingRequest.horseName = entry.horseName || "";
                                formattedStablingRequest.trainerName = entry.trainerName || "";
                            }
                        }
                    }
                };
                formattedStablingRequest.entries = entryArray;

                if (entryArray.length > 1) {
                    const quantity = (stablingRequest.quantityNeeded || 1) / entryArray.length;
                    formattedStablingRequest.quantity = quantity.toFixed(2);

                    const splitCost = (stablingRequest.totalPrice || 0) * quantity;
                    formattedStablingRequest.splitCost = splitCost.toFixed(2);
                }
                
                formattedArray.push(formattedStablingRequest);
            }
        }
        setFormattedStablingRequests(formattedArray);
    }

    const getStablingRequestsByEventByEntry = async (event: Event, entry: EventEntry) => {
        let requestList: StablingRequest[] = [];
        const queryResult = await getStablingRequestsByEventId(event.id);
        if (queryResult.isSuccess) {
            const allStablingRequests = queryResult.result;
            allStablingRequests.forEach((sr: StablingRequest) => {
                const currentEntries = sr.entryIds;
                if (currentEntries && currentEntries.length) {
                    currentEntries.forEach(currentEntry => {
                        if (currentEntry === entry.id) requestList.push(sr);
                    });
                }
            });
        }
        let beddingRequestList: BeddingRequest[] = [];
        const beddingQueryResult = await getBeddingRequestsByEntryId(entry.id);
        if (beddingQueryResult.isSuccess) {
            beddingRequestList = beddingQueryResult.result;
        }
        getTotalCost(requestList, beddingRequestList);
        setStablingRequests(requestList);
        formatStablingRequests(requestList);
    }

    const getBeddingRequestsByEntry = async ( entry: EventEntry) => {
        let requestList: BeddingRequest[] = [];
        const queryResult = await getBeddingRequestsByEntryId(entry.id);
        if (queryResult.isSuccess) {
            requestList = queryResult.result;
        }
        setBeddingRequests(requestList);
    }

    useEffect(() => {
        if (event && entry) {
            getStablingRequestsByEventByEntry(event, entry);
            getBeddingRequestsByEntry(entry);
        }
    }, [entry, event]);

    return (
        <>
            <IonCard className="ion-padding" color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {stablingRequests ? <IonRow><h3>Stabling: ${totalCost?.toFixed(2)}</h3></IonRow> : <h2>Loading stabling requests...</h2> }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? <IonIcon icon={chevronDown} />  : <IonIcon icon={chevronUp} />}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {formattedStablingRequests && (
                            <IonList className="bg-white">
                                {formattedStablingRequests.map((sr, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            {(sr.entries && sr.entries?.length > 1) ? 
                                                <>
                                                    <IonRow>
                                                        <IonCol>
                                                            <h3>Bill to: Split across entries: </h3>
                                                        </IonCol>
                                                        <IonCol>
                                                            {sr.entries.map((entry, index) => (
                                                                <p key={index}>{entry?.horseName}</p>
                                                            ))}
                                                        </IonCol>
                                                    </IonRow>
                                                </>
                                                : 
                                                <>
                                                    <IonRow>
                                                        <IonCol>
                                                            <h3>Bill to: {sr.entries && sr.entries[0]?.horseName}</h3>
                                                        </IonCol>
                                                    </IonRow>
                                                </>
                                            }
                                            <IonRow>
                                                <IonCol>
                                                    <h3>Type: {sr.type}</h3>
                                                </IonCol>
                                                <IonCol>
                                                    <h3>Quantity: {sr.quantity}</h3>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <h3>Price Per Unit: ${sr.price}</h3>
                                                </IonCol>
                                                <IonCol>
                                                    <h3>Tax: {sr.tax}%</h3>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <h3>Total Cost: ${sr.totalPrice}</h3>
                                                </IonCol>
                                                {(sr.entries && sr.entries?.length > 1) && (
                                                    <IonCol>
                                                        <h3>Split Cost: ${sr.splitCost}</h3>
                                                    </IonCol>
                                                )}
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                        )}
                        {beddingRequests && (
                            <IonList className="bg-white">
                                {beddingRequests.map((br, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol>
                                                    <h3>Bedding</h3>
                                                </IonCol>
                                                <IonCol>
                                                    <h3>Quantity: {br.quantityNeeded}</h3>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <h3>Price Per Unit: ${br.basePrice}</h3>
                                                </IonCol>
                                                <IonCol>
                                                    <h3>Tax: {br.taxA || 0}%</h3>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <h3>Total Cost: ${((br.basePrice || 0) * (br.quantityNeeded || 0)).toFixed(2)}</h3>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                        )}
                        <IonList className="bg-white">
                            <IonItem>
                                <IonLabel>
                                    <p className="text-dark font-weight-bold">
                                        Total cost: ${totalCost?.toFixed(2)}
                                    </p>
                                </IonLabel>
                            </IonItem>
                        </IonList>
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default ViewEntryStablingRequest;