import React, { useState } from "react";
import { IonButton, IonIcon, IonLabel, IonSearchbar, isPlatform } from "@ionic/react";
import { search } from "ionicons/icons";
import { isWindows } from "../../utilities/platform/Platform";

interface _Props {
    onSearch: Function
    placeHolderText?: string
}

const SearchIcon: React.FC<_Props> = ({onSearch, placeHolderText}) => {

    const [displaySearchBar, setDisplaySearchBar] = useState<Boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const handleSearchInput = async () => {
        onSearch(searchQuery);
    }

    return (
        <>
            {displaySearchBar ?
                <IonSearchbar 
                    color="white"  
                    placeholder={placeHolderText || "Search"}
                    value={searchQuery} 
                    onIonChange={e => {
                        setSearchQuery(e.detail.value!)
                        handleSearchInput()
                    }}
                ></IonSearchbar>
                :
                <IonButton onClick={() => setDisplaySearchBar(true)}>
                    <IonIcon icon={search}/>
                    <IonLabel>
                    {(isWindows() || (isPlatform("desktop"))) && <IonLabel>Search</IonLabel>}
                    </IonLabel>
                </IonButton>
            }
        </>
    );
};

export default SearchIcon;
