import React from "react";
import {
    IonCard,
    IonRow,
} from "@ionic/react";
import SelectBarnSection from "./SelectBarnSection";
import { Event } from "../../../models"; 

interface EntryCardProps {
    event: Event;
}

const EntryCard: React.FC<EntryCardProps> = ({
    event
}) => {
    return (
        <SelectBarnSection event={event} />
    );
};
export default EntryCard;