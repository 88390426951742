import { CreateBeddingRequestInput, CreateEventClassEntryInput, CreateEventEntryFeeInput, CreateEventEntryInput, CreateStablingRequestInput } from "../../API"
import { BeddingRequest, Event, EventBeddingType, EventClass, EventClassEntry, EventDivision, EventEntry, EventEntryFee, EventFee, EventRulesAgreement, EventStallType, Person, StablingRequest } from "../../models"
import { createBeddingRequest } from "../beddingRequest/BeddingRequest"
import { getEventClassesByEventId } from "../eventClass/EventClass"
import { createEventClassEntry } from "../eventClassEntry/EventClassEntry"
import { getEventDivisionById, getEventDivisionsByEventId } from "../eventDivision/EventDivision"
import { createEventEntryFee } from "../eventEntryFee/EventEntryFee"
import { getEventFeesByEventId } from "../eventFee/EventFee"
import { getEventStallTypesByEventId } from "../eventStallType/EventStallType"
import { createStablingRequest } from "../stablingRequest/StablingRequest"
import { createEventEntry } from "./EventEntry"
import { sendEntryStatusSubmittedEmailWithInvoice } from "./EventEntryEmail"

export interface EntryTemplate {
    id: string
    entry: EventEntry
    classes?: EventClassEntry[]
    fees?: EventEntryFee[]
    stabling?: StablingRequest[]
    bedding?: BeddingRequest[]
    ruleAgreements?: EventRulesAgreement[]
    isSelected: boolean
}

export async function addEventEntriesFromTemplates(event: Event, templates: EntryTemplate[], person: Person) {
    let currentEventClasses: EventClass[] = [];
    let currentEventDivisions: EventDivision[] = [];
    let currentEventFees: EventFee[] = [];
    let currentEventStallTypes: EventStallType[] = [];
    // To Do - Add in bedding and rules
    // let currentEventBeddingTypes: EventBeddingType[] = [];
    // let currentEventRules: EventRules[] = [];

    let newEventDivisionIdList: string[] = [];

    const classesQueryResult = await getEventClassesByEventId(event.id);
    if (classesQueryResult.isSuccess) {
        currentEventClasses = classesQueryResult.result;
    }

    const divisionsQueryResult = await getEventDivisionsByEventId(event.id);
    if (divisionsQueryResult.isSuccess) {
        currentEventDivisions = divisionsQueryResult.result;
    }

    const eventFeesQueryResult = await getEventFeesByEventId(event.id);
    if (eventFeesQueryResult.isSuccess) {
        currentEventFees = eventFeesQueryResult.result;
    }

    const eventStallTypesQueryResult = await getEventStallTypesByEventId(event.id);
    if (eventStallTypesQueryResult.isSuccess) {
        currentEventStallTypes = eventStallTypesQueryResult.result;
    }

    // const eventBeddingTypesQueryResult = await getEventBeddingTypesByEventId(event.id);
    // if (eventBeddingTypesQueryResult.isSuccess) {
    //     currentEventBeddingTypes = eventBeddingTypesQueryResult.result;
    // }

    // const eventRulesQueryResult = await getEventRulesByEventId(event.id);
    // if (eventRulesQueryResult.isSuccess) {
    //     currentEventRules = eventRulesQueryResult.result;
    // }

    for (let i = 0; i < templates.length; i++) {
        const template = templates[i];

        if (!template.isSelected) {
            continue;
        }

        const templateEventDivisions: (string | null)[] | null | undefined = template.entry.divisionList;
        if (templateEventDivisions && templateEventDivisions.length > 0) {
            for (let j = 0; j < templateEventDivisions.length; j++) {
                const oldEventDivisionId: string | null = templateEventDivisions[j];
                
                // Match up event divisions using field: name
                if (oldEventDivisionId) {
                    const oldEventDivisionResult = await getEventDivisionById(oldEventDivisionId);
                    if (oldEventDivisionResult.isSuccess) {
                        const oldEventDivision: EventDivision = oldEventDivisionResult.result;
                        if (oldEventDivision) {
                            const newEventDivision = currentEventDivisions.find(newED => newED.name === oldEventDivision.name);
                            if (newEventDivision) {
                                newEventDivisionIdList.push(newEventDivision.id);
                            }
                        }
                    }
                }
            }
        }
        
        const entryInput: CreateEventEntryInput = {
            eventId: event.id,
            status: event?.eventOptions?.autoAcceptNewEntries ? "complete" : "submitted",
            createdBy: person.id,
            personId: person.id, 
            contactId: template.entry.contactId || "",
            number: template.entry.number || 0,
            riderId: template.entry.riderId || "", 
            riderName: template.entry.riderName || "", 
            horseId: template.entry.horseId || "",
            horseName:template.entry.horseName || "",
            ownerId: template.entry.ownerId || "", 
            ownerName: template.entry.ownerName || "",  
            trainerId: template.entry.trainerId || "", 
            trainerName: template.entry.trainerName || "", 
            secondRiderId: template.entry.secondRiderId || "", 
            secondRiderName: template.entry.secondRiderName || "", 
            barnId: template.entry.barnId || "",
            barnName: template.entry.barnName || "",
            divisionList: newEventDivisionIdList,
            note: "Submitted through an entry template.",
            beddingRequestId: " ",
            stablingRequestId: " "
        };

        const entryResult = await createEventEntry(entryInput);
        if (entryResult.isSuccess) {
            const entry: EventEntry = entryResult.result;

            const templateEventClassEntries: EventClassEntry[] | undefined = template.classes;
            if (templateEventClassEntries && templateEventClassEntries.length > 0) {
                for (let j = 0; j < templateEventClassEntries.length; j++) {
                    const eventClassEntry: EventClassEntry = templateEventClassEntries[j];
                    
                    // Match up event classes using field: number
                    const oldEventClass: EventClass | undefined | null = eventClassEntry.eventClass;
                    let newEventClassId;
                    if (oldEventClass && currentEventClasses && currentEventClasses.length > 0) {
                        const newEventClass: EventClass | undefined = currentEventClasses.find(newEC => newEC.number === oldEventClass.number);
                        if (newEventClass) newEventClassId = newEventClass.id;
                    }

                    if (newEventClassId) {
                        const eventClassEntryInput: CreateEventClassEntryInput = {
                            createdBy: person.id,
                            eventId: event.id,
                            eventClassId: newEventClassId || "",
                            eventEntryId: entry.id,
                            riderId: eventClassEntry.riderId || "",
                            status: "submitted"
                        };
                        await createEventClassEntry(eventClassEntryInput);
                    }
                }
            }

            const templateEventClassFees: EventEntryFee[] | undefined = template.fees;
            if (templateEventClassFees && templateEventClassFees.length > 0) {
                for (let j = 0; j < templateEventClassFees.length; j++) {
                    const oldEventEntryFee: EventEntryFee = templateEventClassFees[j];
                    
                    // Match up event fees using field: name
                    let newEventFeeId;
                    if (oldEventEntryFee && currentEventFees && currentEventFees.length > 0) {
                        const newEventFee: EventFee | undefined = currentEventFees.find(newED => newED.name === oldEventEntryFee.name);
                        if (newEventFee) {
                            newEventFeeId = newEventFee.id;
                            const input: CreateEventEntryFeeInput = {
                                eventId: event.id,
                                name: newEventFee.name,
                                status: "submitted",
                                amount: newEventFee.amount,
                                quantity: oldEventEntryFee.quantity || null,
                                entryId: entry.id, 
                                eventFeeId: newEventFeeId || "",
                                feeId: newEventFee.feeId || "", 
                                taxA: newEventFee.fee?.taxA || null,
                                taxB: newEventFee.fee?.taxB || null,
                                description: newEventFee.description,
                                createdBy: person.id
                            };
                            await createEventEntryFee(input);
                        }
                    }
                }   
            }

            const templateEventStalls: StablingRequest[] | undefined = template.stabling;
            if (templateEventStalls && templateEventStalls.length > 0) {
                for (let j = 0; j < templateEventStalls.length; j++) {
                    const oldStablingRequest: StablingRequest = templateEventStalls[j];
                    
                    // Match up event stall types using field: name
                    if (oldStablingRequest && currentEventStallTypes && currentEventStallTypes.length > 0) {
                        const newEventStallType: EventStallType | undefined = currentEventStallTypes.find(newEST => newEST.name === oldStablingRequest.stallType?.name);
                        if (newEventStallType) {
                            const pricePerStall = newEventStallType.pricePerStall ? parseFloat(newEventStallType.pricePerStall) : null;
                            const quantityNeeded = oldStablingRequest.quantityNeeded || null;
                            const taxA = newEventStallType.taxPerStall ? parseFloat(newEventStallType.taxPerStall) : null;
                            const basePrice = (pricePerStall || 0) * (quantityNeeded || 0);
                            const taxRate = (taxA || 0) / 100;
                            const fullPrice = basePrice * taxRate + basePrice;
                            const input: CreateStablingRequestInput = {
                                eventId: event.id,
                                personId: person.id || "",
                                stallTypeId: newEventStallType.id || "",
                                quantityNeeded: quantityNeeded,
                                trainerId: oldStablingRequest.trainerId || "",
                                basePrice: pricePerStall,
                                taxA: taxA,
                                totalPrice: fullPrice,
                                status: "submitted",
                                requestNote: oldStablingRequest.requestNote, 
                                barnId: entry?.barnId || "",
                                entryIds: [entry.id]
                            };
                            await createStablingRequest(input);
                        }
                    }
                }   
            }

            const templateEventBedding: BeddingRequest[] | undefined = template.bedding;
            if (templateEventBedding && templateEventBedding.length > 0) {
                for (let j = 0; j < templateEventBedding.length; j++) {
                    const oldBeddingRequest: BeddingRequest = templateEventBedding[j];
                    
                    // Match up event bedding types using field: beddingType (which is an id value)
                    if (oldBeddingRequest && currentEventStallTypes && currentEventStallTypes.length > 0) {                        
                        
                        // For now, assume only one (TO DO - fix this)
                        const newEventBeddingType: EventBeddingType | undefined = currentEventStallTypes[0];
                        if (newEventBeddingType) {
                            const pricePerBedding = newEventBeddingType.pricePerBag ? parseFloat(newEventBeddingType.pricePerBag) : null;
                            const quantityNeeded = oldBeddingRequest.quantityNeeded || null;
                            const taxA = newEventBeddingType.taxPerBag ? parseFloat(newEventBeddingType.taxPerBag) : null;
                            const basePrice = (pricePerBedding || 0) * (quantityNeeded || 0);
                            const taxRate = (taxA || 0) / 100;
                            const fullPrice = basePrice * taxRate + basePrice;
                            const input: CreateBeddingRequestInput = {
                                eventId: event.id,
                                personId: person.id || "",
                                beddingType: newEventBeddingType.id || "",
                                quantityNeeded: quantityNeeded,
                                trainerId: oldBeddingRequest.trainerId || "",
                                basePrice: pricePerBedding,
                                totalPrice: fullPrice,
                                status: "submitted",
                                statusNote: "Created via an entry template",
                                barnId: entry?.barnId || "",
                                entryIds: [entry.id],
                                submittedEntry: entry.id,
                                lastUpdatedBy: person.id,
                                beddingRequestTaxAId: taxA ? taxA.toString() : ""
                            };
                            await createBeddingRequest(input);
                        }
                    }
                }   
            }
            await sendEntryStatusSubmittedEmailWithInvoice(entry, person.email, person.firstName || "");
        } else {
            console.error("Could not create the entry: ", template);
        }
    }
}