import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { chevronDown, chevronUp, close } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { CreateEventClassEntryInput, UpdateEventClassEntryInput, UpdateEventEntryInput } from "../../../API";
import { PersonContext } from "../../../context/PersonContext";
import { Event, EventEntry, EventClass, EventDivision, Rider,  EventResult } from "../../../models";
import { getEventClassTypeAbbreviation } from "../../../utilities/eventClass/EventClassTypes";
import { createEventClassEntry, deleteEventClassEntry, getEventClassEntryByEventClassIdEntryId, updateEventClassEntry } from "../../../utilities/eventClassEntry/EventClassEntry";
import { formattedOptionGroup, getFormattedEntryClassList } from "../../../utilities/eventClassEntry/FormattedEventEntryClasses";
import ErrorAlert from "../../Errors/ErrorAlert";
import { getEventClassesByEventDivisionId } from "../../../utilities/eventClass/EventClass";
import SelectEventClass from "../../EventClass/SelectEventClass";
import SelectEventDivision from "../../EventDivision/SelectEventDivision";
import Spinner from "../../Spinners/Spinner";
import { updateEventEntry } from "../../../utilities/eventEntry/EventEntry";
import SelectRiderFromEntry from "../../Rider/SelectRiderFromEntry";
import EventClassCheckList from "../../EventClass/EventClassCheckList";
import SelectEventClassEntryStatus from "../../EventClassEntry/SelectEventClassEntryStatus";
import { deleteEventResult, getEventResultsByEventClassEntryId } from "../../../utilities/eventResult/EventResult";

interface _Props {
    entry: EventEntry
    event: Event
    onCalculate?: Function
}

const EditEntryClasses: React.FC<_Props> = ({entry, event, onCalculate}) => {
    const user = useContext(PersonContext);

    const [isShowChecklistView, setIsShowChecklistView] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [selectedClass, setSelectedClass] = useState<EventClass | null | undefined>();
    const [selectedDivision, setSelectedDivision] = useState<EventDivision | null | undefined>();
    const [selectedClasses, setSelectedClasses] = useState<EventClass[] | null | undefined>();
    const [selectedDivisions, setSelectedDivisions] = useState<EventDivision[] | null | undefined>();
    const [totalEntryFees, setTotalEntryFees] = useState<number | null | undefined>();
    const [tax, setTax] = useState<number | null | undefined>();
    const [totalCost, setTotalCost] = useState<number | null | undefined>();
    const [formattedClassData, setFormattedClassData] = useState<formattedOptionGroup[] | null | undefined>();
    const [currentDivisionList, setCurrentDivisionList] = useState<(string | null)[] | null | undefined>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [isSettingRider, setIsSettingRider] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteDataModal, setShowDeleteDataModal] = useState(false);
    const [selectedFormattedOptionGroup, setSelectedFormattedOptionGroup] = useState<formattedOptionGroup | null | undefined>();
    const [currentEventResults, setCurrentEventResults] = useState<EventResult[] | undefined | null>();

    const getFinalFeeCost = (total?: (number | null), taxPercent?: (number | null)) => {
        if (!total) total = totalEntryFees;
        if (!taxPercent) taxPercent = tax;
        if (total && taxPercent) {
            const finalCost = total + total * (taxPercent || 0)/100;
            setTotalCost(finalCost);
        } else if (total) {
            setTotalCost(total);
        }
    }

    const getFormattedClassData = async (entry: EventEntry) => {
        setError("");
        setIsLoading(true);
        const dataResult = await getFormattedEntryClassList(entry, false);
        if (dataResult.isSuccess) {
            setTotalEntryFees(dataResult.result.totalEntryFees);
            if (tax) getFinalFeeCost(dataResult.result.totalEntryFees, tax);
            else if (event) getFinalFeeCost(dataResult.result.totalEntryFees, event.invoiceOptions?.classFeeTax);
            if (onCalculate) onCalculate(dataResult.result.totalEntryFees);
            
            setFormattedClassData(dataResult.result.classList);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (event && event.invoiceOptions && event.invoiceOptions.classFeeTax) {
            setTax(event.invoiceOptions.classFeeTax);
            if (totalEntryFees) getFinalFeeCost(totalEntryFees, event.invoiceOptions.classFeeTax);
            else if (entry) getFormattedClassData(entry);
        }
    }, [event]);

    useEffect(() => {
        if (entry) {
            setCurrentEntry(entry);
            setIsCollapsed(false);
            getFormattedClassData(entry);
            setCurrentDivisionList(entry.divisionList);
        }
    }, [entry]);

    const handleChecklistChange = (newFormattedGroups: any[]) => {
        let classList: EventClass[] = [];
        let divisionList: EventDivision[] = [];

        if (newFormattedGroups && newFormattedGroups.length) {
            for (let i = 0; i < newFormattedGroups.length; i++) {
                const current = newFormattedGroups[i];
                if (current.division && current.division.isChecked) {
                    const divisionObject: EventDivision = current.division.object;
                    divisionList.push(divisionObject);
                } 
                if (current.class && current.class.isChecked) {
                    const classObject: EventClass = current.class.object;
                    classList.push(classObject);
                } 
                if (current.classes) {
                    for (let j = 0; j < current.classes.length; j++) {
                        const currentClass = current.classes[j];
                        if (currentClass.isChecked) {
                            classList.push(currentClass.object);
                        }
                    }
                }
            }
        }
        setSelectedClasses(classList);
        setSelectedDivisions(divisionList);
    }

    const handleSelectedRider = async (rider: Rider, formattedOption: any, formattedOptionGroupIndex: number, optionIndex?: number) => {
        setIsSettingRider(true);
        if (formattedOption.object) {
            const eventClass = formattedOption.object;
            const queryEventClassEntryResult = await getEventClassEntryByEventClassIdEntryId(eventClass.id, entry.id);
            if (queryEventClassEntryResult.isSuccess) {
                const currentEventClassEntry = queryEventClassEntryResult.result;
                if (rider) {
                    const updateInput: UpdateEventClassEntryInput = {
                        id: currentEventClassEntry.id,
                        riderId: rider.id,
                        // eventClassEntryRiderId: rider.id
                    };
                    const updateResult = await updateEventClassEntry(updateInput);
                    if (updateResult.isSuccess && formattedClassData) {
                        // Use the indices to update the formatted class data
                        let formattedGroupObject = formattedClassData[formattedOptionGroupIndex];
                        
                        let updatedOption: any = {
                            ...formattedOption,
                            rider
                        };

                        let updatedFormattedOptionGroup: formattedOptionGroup = {};

                        if (formattedGroupObject.class) {
                            updatedFormattedOptionGroup = {
                                class: updatedOption
                            }
                        } else if (formattedGroupObject.classes) {
                            if (optionIndex === 0 || (optionIndex && optionIndex > -1)) {
                                let classArray = formattedGroupObject.classes;
                                if (classArray) {
                                    let updatedArray: any[] = [
                                        ...classArray.slice(0, optionIndex),
                                        updatedOption,
                                        ...classArray.slice(optionIndex + 1),
                                    ];
                                    updatedFormattedOptionGroup = {
                                        ...formattedGroupObject,
                                        classes: updatedArray
                                    };
                                }
                            }
                        }

                        let updatedData: formattedOptionGroup[] = [
                            ...formattedClassData.slice(0, formattedOptionGroupIndex),
                            updatedFormattedOptionGroup,
                            ...formattedClassData.slice(formattedOptionGroupIndex + 1),
                        ];
                        setFormattedClassData(updatedData);
                    } else {
                        const errorMessage = "Could not update the rider for class: " + eventClass.number + " - " + eventClass.name;
                        setError(errorMessage);
                    }
                }
            }
        }
        setIsSettingRider(false);
    }

    const handleSelectedStatus = async (status: string, formattedOption: any, formattedOptionGroupIndex: number, optionIndex?: number) => {
        setIsSettingRider(true);
        if (formattedOption) {
            const eventClass = formattedOption.object;
            const queryEventClassEntryResult = await getEventClassEntryByEventClassIdEntryId(eventClass.id, entry.id);
            if (queryEventClassEntryResult.isSuccess) {
                const currentEventClassEntry = queryEventClassEntryResult.result;
                if (status) {
                    const updateInput: UpdateEventClassEntryInput = {
                        id: currentEventClassEntry.id,
                        status: status
                    };
                    const updateResult = await updateEventClassEntry(updateInput);
                    if (updateResult.isSuccess && formattedClassData) {
                        // Use the indices to update the formatted class data
                        let formattedGroupObject = formattedClassData[formattedOptionGroupIndex];
                        
                        let updatedOption: any = {
                            ...formattedOption,
                            status
                        };

                        let updatedFormattedOptionGroup: formattedOptionGroup = {};

                        if (formattedGroupObject.class) {
                            updatedFormattedOptionGroup = {
                                class: updatedOption
                            }
                        } else if (formattedGroupObject.classes) {
                            if (optionIndex === 0 || (optionIndex && optionIndex > -1)) {
                                let classArray = formattedGroupObject.classes;
                                if (classArray) {
                                    let updatedArray: any[] = [
                                        ...classArray.slice(0, optionIndex),
                                        updatedOption,
                                        ...classArray.slice(optionIndex + 1),
                                    ];
                                    updatedFormattedOptionGroup = {
                                        ...formattedGroupObject,
                                        classes: updatedArray
                                    };
                                }
                            }
                        }

                        let updatedData: formattedOptionGroup[] = [
                            ...formattedClassData.slice(0, formattedOptionGroupIndex),
                            updatedFormattedOptionGroup,
                            ...formattedClassData.slice(formattedOptionGroupIndex + 1),
                        ];
                        setFormattedClassData(updatedData);
                    } else {
                        const errorMessage = "Could not update the status for class: " + eventClass.number + " - " + eventClass.name;
                        setError(errorMessage);
                    }
                }
            }
        }
        setIsSettingRider(false);
    }

    const handleSelectedEventDivision = (eventDivision: EventDivision) => {
        setSelectedClass(undefined);
        setSelectedDivision(eventDivision);
    }

    const handleSelectedEventClass = (eventClass: EventClass) => {
        setSelectedClass(eventClass);
        setSelectedDivision(undefined);
    }

    const handleSubmit = async () => {
        setIsLoadingModal(true);
        if (selectedDivision) {
            // Need to add the classes in this division 
            const queryResult = await getEventClassesByEventDivisionId(selectedDivision.id);
            if (queryResult.isSuccess) {
                const eventClassArray: EventClass[] = queryResult.result;
                for (let i = 0; i < eventClassArray.length; i++) {
                    const eventClass = eventClassArray[i];
                    const eventClassEntryInput: CreateEventClassEntryInput = {
                        createdBy: user.id,
                        eventId: event.id,
                        eventClassId: eventClass.id,
                        eventEntryId: entry.id,
                        riderId: entry.riderId,
                        status: "accepted"
                    };
                    await createEventClassEntry(eventClassEntryInput);
                }
            }

            // Need to add the divisionId to the entry's division list
            let newDivisionList = [];
            if (currentDivisionList) {
                newDivisionList = currentDivisionList.concat([selectedDivision.id]);
            } else {
                newDivisionList = [selectedDivision.id];
            }
            setCurrentDivisionList(newDivisionList);
            const updateInput: UpdateEventEntryInput = {
                id: entry.id,
                divisionList: newDivisionList
            };
            const updateEventEntryResult = await updateEventEntry(updateInput);
            if (updateEventEntryResult.isSuccess) {
                const updatedEventEntry: EventEntry = updateEventEntryResult.result;
                await getFormattedClassData(updatedEventEntry);
                setCurrentEntry(updatedEventEntry);
            } else {
                setError(updateEventEntryResult.message);
            }
        } else if (selectedClass) {
            // Need to add the class
            const eventClassEntryInput: CreateEventClassEntryInput = {
                createdBy: user.id,
                eventId: event.id,
                eventClassId: selectedClass.id,
                eventEntryId: entry.id,
                riderId: entry.riderId,
                status: "accepted"
            };
            const createResult = await createEventClassEntry(eventClassEntryInput);
            if (createResult.isSuccess) {
                setError("");
                await getFormattedClassData(entry);
            } else {
                const message = "Could not add " + selectedClass.number + ": " + selectedClass.name + " to the entry";
                setError(message);
            }
        } else {
            setError("Did not add because no class or division selected.");
        }
        setIsLoadingModal(false);
        setShowModal(false);
    }

    const handleShowDeleteModal = async (formattedOptionGroup: formattedOptionGroup) => {
        setCurrentEventResults(undefined);
        setSelectedFormattedOptionGroup(formattedOptionGroup);
        setShowDeleteDataModal(true);

        const division = formattedOptionGroup.division;
        const classes = formattedOptionGroup.classes;
        const c = formattedOptionGroup.class;

        let currentEventResults: EventResult[] = [];

        if (classes && classes.length > 0) {
            for (let index = 0; index < classes.length; index++) {
                const currentClass = classes[index];
                const eventClassEntryId = currentClass?.eventClassEntry?.id;
                if (eventClassEntryId) {
                    const queryResult = await getEventResultsByEventClassEntryId(eventClassEntryId);
                    if (queryResult.isSuccess) {
                        const eventResults: EventResult[] = queryResult.result;
                        if (eventResults && eventResults.length > 0) {
                            currentEventResults = currentEventResults.concat(eventResults);
                        }
                    }
                }
            }
        } else if (c) {
            const eventClassEntryId = c?.eventClassEntry?.id;
            if (eventClassEntryId) {
                const queryResult = await getEventResultsByEventClassEntryId(eventClassEntryId);
                if (queryResult.isSuccess) {
                    const eventResults: EventResult[] = queryResult.result;
                    if (eventResults && eventResults.length > 0) {
                        currentEventResults = currentEventResults.concat(eventResults);
                    }
                }
            }
        }

        setCurrentEventResults(() => currentEventResults);
    }

    const handleCloseDeleteModal = async () => {
        setCurrentEventResults(undefined);
        setSelectedFormattedOptionGroup(undefined);
        setShowDeleteDataModal(false);
    }

    const handleDelete = async (formattedOptionGroup: formattedOptionGroup) => {
        setIsLoading(true);
        setShowDeleteDataModal(false);

        // Delete results connected to this EventClassEntry
        if (currentEventResults && currentEventResults.length > 0) {
            for (let i = 0; i < currentEventResults.length; i++) {
                const currentEventResult = currentEventResults[i];
                await deleteEventResult({id: currentEventResult.id});
            }
        } 

        if (formattedOptionGroup.class) {
            // Delete this EventClassEntry
            const queryResult = await getEventClassEntryByEventClassIdEntryId(formattedOptionGroup.class.value, entry.id);
            if (queryResult.isSuccess) {
                const classEntryId = queryResult.result.id;
                const deleteResult = await deleteEventClassEntry({id: classEntryId});
                if (deleteResult.isSuccess) {
                    await getFormattedClassData(entry);
                } else {
                    setError("Could not delete the class - check id.");
                }
            } else {
                setError("Could not delete the class - no class.");
            }
        } else if (formattedOptionGroup.division) {
            // Delete the division classes
            const queryResult = await getEventClassesByEventDivisionId(formattedOptionGroup.division.object.id);
            if (queryResult.isSuccess) {
                const eventClassArray: EventClass[] = queryResult.result;
                for (let i = 0; i < eventClassArray.length; i++) {
                    const eventClass = eventClassArray[i];
                    const eventClassEntryResult = await getEventClassEntryByEventClassIdEntryId(eventClass.id, entry.id);
                    if (eventClassEntryResult.isSuccess) {
                        await deleteEventClassEntry({id: eventClassEntryResult.result.id});
                    }
                }
            }

            // Remove the division id from entry division list
            let newDivisionList: string[] = [];
            if (currentDivisionList && formattedOptionGroup.division.value) {
                currentDivisionList.forEach(eventDivisionId => {
                    if (eventDivisionId && (eventDivisionId !== formattedOptionGroup?.division?.value)) newDivisionList.push(eventDivisionId);
                });
            }
            const updateInput: UpdateEventEntryInput = {
                id: entry.id,
                divisionList: newDivisionList
            };
            const updateEventEntryResult = await updateEventEntry(updateInput);
            if (updateEventEntryResult.isSuccess) {
                setCurrentEntry(updateEventEntryResult.result);
            } else {
                setError(updateEventEntryResult.message);
            }
            await getFormattedClassData(entry);
        }
        setIsLoading(false);
    }

    const handleDeleteSingleClassFromDivision = async (formattedOption: any) => {
        setIsLoading(true);
        if (formattedOption.eventClassEntry) {
            const currentEventClassEntry = formattedOption.eventClassEntry;
            const deleteResult = await deleteEventClassEntry({id: currentEventClassEntry.id});
            if (deleteResult.isSuccess) {
                await getFormattedClassData(entry);
            } else {
                setError("Could not delete the class - check id.");
            }
        }
        setIsLoading(false);
    }

    const handleViewChange = async () => {
        setIsLoading(true);
        if (isShowChecklistView) {
            await getFormattedClassData(currentEntry || entry);
        }
        setIsShowChecklistView(() => !isShowChecklistView);
        setIsLoading(false);
    }

    return (
        <>
            <IonRow>
                <IonCol>
                    <IonButton color="light" onClick={handleViewChange}>
                        Display {isShowChecklistView ? "Table View" : "Checklist View"}
                    </IonButton>
                </IonCol>
                {!isShowChecklistView && (
                    <IonCol className="text-right">
                        <IonButton color="primary" onClick={() => setShowModal(true)}>
                            Add New
                        </IonButton>
                    </IonCol>
                )}
            </IonRow>
            {isShowChecklistView ?
                <>
                    <IonCard className="bg-white padding">
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="text-center">
                                <p>Select or deselect the classes the entry should be in.</p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="text-center">
                                <p>If you have started entering results, DO NOT USE. Instead, use the Table View.</p>
                            </IonCol>
                        </IonRow>
                        {error && <ErrorAlert width="12" error={error} />}
                        {isLoading ?
                            <Spinner />
                            :
                            <>
                                <IonRow>
                                    <IonCol>
                                        <EventClassCheckList entry={currentEntry || entry} eventId={event.id} isQuickAdd={false} onUpdateEntry={setCurrentEntry} onSelect={handleChecklistChange} />
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                    </IonCard>
                </>
                :
                <>
                    <IonCard color="white">
                        <IonCardTitle>
                            <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                                <IonCol>
                                    {entry ? <IonRow><h3>Class Fees: {totalCost ? "$" + totalCost.toFixed(2) : ""}</h3></IonRow> : <h2>Loading event fees...</h2> }
                                </IonCol>
                                <IonCol className="ion-text-right">
                                    <p>{isCollapsed ? <IonIcon icon={chevronDown} />  : <IonIcon icon={chevronUp} />}</p>
                                </IonCol>
                            </IonRow>
                        </IonCardTitle>
                        {!isCollapsed && (
                            <IonCardContent>
                                {error && <ErrorAlert width="12" error={error} />}
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <>
                                        {formattedClassData && (
                                            <>
                                                {formattedClassData.map((formattedOption, index) => (
                                                    <IonItem key={index}>
                                                        <IonLabel>
                                                            <IonRow>
                                                                <IonCol sizeXs="12" sizeMd="6">
                                                                    <IonRow>
                                                                        <IonCol>
                                                                            {formattedOption.division ? 
                                                                                <h2 className="ion-text-wrap">Division: {formattedOption.division.label + " - $" + formattedOption.division.entryFee}</h2>
                                                                                : 
                                                                                <h2 className="ion-text-wrap">Class: {(formattedOption.class?.number ? + formattedOption.class?.number + " - " : "") + formattedOption.class?.label + " - $" + formattedOption.class?.entryFee}</h2>
                                                                            }
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </IonCol>
                                                                <IonCol sizeXs="12" sizeMd="3" offsetMd="3" className="pt-3">
                                                                    <IonButton color="danger" onClick={() => handleShowDeleteModal(formattedOption)}><IonIcon icon={close}/>Delete {formattedOption.division ? "Div" : "Class"}</IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                            {(formattedOption.classes && formattedOption.classes.length > 0) && (
                                                                <>
                                                                    {formattedOption.classes.map((formattedClass, i) => (
                                                                        <IonRow key={i} className="ion-text-dark">
                                                                            <IonCol sizeXs="2" sizeMd="1">
                                                                                <IonButton color="danger" size="small" onClick={() => handleDeleteSingleClassFromDivision(formattedClass)}>X</IonButton>
                                                                            </IonCol>
                                                                            <IonCol sizeXs="10" sizeMd="3">
                                                                                <h2 className="ion-text-wrap">Class: {(formattedClass?.number ? + formattedClass?.number + " - " : "") + formattedClass?.label}</h2>
                                                                            </IonCol>
                                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                                {isSettingRider ?
                                                                                    <Spinner />
                                                                                    :
                                                                                    <SelectRiderFromEntry entry={entry} selectedValue={formattedClass.rider?.id} onSelect={(rider: Rider) => handleSelectedRider(rider, formattedClass, index, i)} />
                                                                                }
                                                                            </IonCol>
                                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                                {isSettingRider ?
                                                                                    <Spinner />
                                                                                    :
                                                                                    <SelectEventClassEntryStatus selectedValue={formattedClass.status} onSelect={(status: string) => handleSelectedStatus(status, formattedClass, index, i)}/>
                                                                                }
                                                                            </IonCol>
                                                                        </IonRow>
                                                                    ))}
                                                                </>
                                                            )}
                                                            {(formattedOption.class && (
                                                                <IonRow className="ion-text-dark">
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        <h2 className="ion-text-wrap">Class: {(formattedOption.class?.number ? + formattedOption.class?.number + " - " : "") + formattedOption.class?.label}</h2>
                                                                    </IonCol>
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        {isSettingRider ?
                                                                            <Spinner />
                                                                            :
                                                                            <SelectRiderFromEntry entry={entry} selectedValue={formattedOption.class?.rider?.id} onSelect={(rider: Rider) => handleSelectedRider(rider, formattedOption.class, index)} />
                                                                        }
                                                                    </IonCol>
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        {isSettingRider ?
                                                                            <Spinner />
                                                                            :
                                                                            <SelectEventClassEntryStatus selectedValue={formattedOption.class.status} onSelect={(status: string) => handleSelectedStatus(status, formattedOption.class, index)}/>
                                                                        }
                                                                    </IonCol>
                                                                </IonRow>
                                                            ))}
                                                        </IonLabel>
                                                    </IonItem>
                                                ))}
                                            </>
                                        )}
                                    </>
                                }
                                <hr/>
                                <IonList className="bg-white">
                                    <IonItem key="total">
                                        <IonLabel color="dark">
                                            <h3 className="font-weight-bold">
                                                Tax: {tax ? tax + "%" : "none"}
                                            </h3>
                                        </IonLabel>
                                    </IonItem>
                                </IonList>
                                <IonList className="bg-white">
                                    <IonItem key="total">
                                        <IonLabel color="dark">
                                            <h3 className="font-weight-bold">
                                                Total: {totalCost ? "$" + totalCost.toFixed(2) : ""}
                                            </h3>
                                        </IonLabel>
                                    </IonItem>
                                </IonList>
                            </IonCardContent>
                        )}
                    </IonCard>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="termsAndConditionsModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Class / Division
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                {isLoadingModal ?
                    <Spinner />
                    :
                    <IonContent className="ion-padding">
                        {event && (
                            <>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="10">
                                        <IonLabel>Select Division</IonLabel>
                                        <SelectEventDivision eventId={event.id} onSelect={handleSelectedEventDivision} />
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="10" className="ion-text-center">
                                        <IonLabel>OR</IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="10">
                                        <IonLabel>Select Class</IonLabel>
                                        <SelectEventClass eventId={event.id} onSelect={handleSelectedEventClass} />
                                    </IonCol>
                                </IonRow>
                                <hr />
                                <IonRow className="ion-justify-content-center ion-padding-top">
                                    <IonCol size="10" className="ion-text-center">
                                        <p>{selectedClass ? "Class: " + selectedClass.number + " - " + selectedClass.name + " " + getEventClassTypeAbbreviation(selectedClass.type || "") : ""}</p>
                                        <p>{selectedDivision ? "Division: " + selectedDivision.name : ""}</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center ion-padding-top">
                                    <IonCol size="10" className="ion-text-center">
                                        <IonButton expand="block" onClick={handleSubmit}>
                                            Save
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </IonContent>
                }   
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showDeleteDataModal} id="deleteDataModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Remove {(selectedFormattedOptionGroup && selectedFormattedOptionGroup.division) ? "Division" : "Class"}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowDeleteDataModal(false)}
                        >
                            <p id="closeDeleteDataModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow>
                        <IonCol>
                            <h4 className="ion-text-wrap">Are you sure you want to remove {selectedFormattedOptionGroup?.class ? selectedFormattedOptionGroup?.class.number + ": " : ""}{selectedFormattedOptionGroup?.class ? selectedFormattedOptionGroup?.class.label : selectedFormattedOptionGroup?.division?.label}?</h4>
                            <p className="ion-text-wrap description">Once you remove this, all record of it will be gone including any results that are connected to this {(selectedFormattedOptionGroup && selectedFormattedOptionGroup?.division) ? "division" : "class"} for this entry.</p>
                            <p className="ion-text-wrap description">Note, you can change the status of any class to 'scratched' which will keep the record.</p>
                        </IonCol>
                    </IonRow>
                    {(currentEventResults && currentEventResults.length > 0) && (
                        <IonRow className="mt-3">
                            <IonCol>
                                <p>This record is currently attached to the following results:</p>
                                {currentEventResults.map((currentEventResult, index) => (
                                    <div key={index}>
                                        {currentEventResult.place &&  <p>Place: {currentEventResult.place} in {currentEventResult.eventClass?.number}: {currentEventResult.eventClass?.name}</p>}
                                    </div>
                                ))}
                            </IonCol>
                        </IonRow>
                    )}
                </IonContent>
                <IonRow>
                    <IonCol size="6">
                        <IonButton expand="block" color="light" onClick={() => handleCloseDeleteModal()}>
                            Cancel
                        </IonButton>
                    </IonCol>
                    <IonCol size="6">
                        <IonButton expand="block" color="danger" onClick={() => selectedFormattedOptionGroup ? handleDelete(selectedFormattedOptionGroup) : console.log("Nothing to delete.")}>
                            Delete {(selectedFormattedOptionGroup && selectedFormattedOptionGroup.division) ? "Division" : "Class"}
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonModal>
        </>
    );
};

export default EditEntryClasses;