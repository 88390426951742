import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {Event} from "../../../../../models";
import EventsList from "../../../../../components/Event/EventList";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import SearchIcon from "../../../../../components/Search/SearchIcon";
import { PersonContext } from "../../../../../context/PersonContext";
import { getEventsByOrganizationId } from "../../../../../utilities/events/Event";
import CreateEventButton from "../../../../../components/Event/CreateEventButton";
import SearchAndAddHeader from "../../../../../components/Headers/SearchAndAddHeader";
import { Organization } from "../../../../../models";
import { getOrganizationsByPersonId } from "../../../../../utilities/organization/Organization";
import { isWindows } from "../../../../../utilities/platform/Platform";
import Spinner from "../../../../../components/Spinners/Spinner";
import { useSubscriptionByItself } from "../../../../../utilities/subscription/Subscription";
import { onCreateEvent, onDeleteEvent } from "../../../../../graphql/subscriptions";
import { sortEventsByDate } from "../../../../../utilities/events/SortEvents";

const AllEventsPage: React.FC = () => {
    const user = useContext(PersonContext);

    const createEventSubscription = useSubscriptionByItself({
        config: {
            query: onCreateEvent,
            key: "onCreateEvent"
        }
    });

    const deleteEventSubscription = useSubscriptionByItself({
        config: {
            query: onDeleteEvent,
            key: "onDeleteEvent"
        }
    });

    const [currentEventSubscriptionItem, setCurrentEventSubscriptionItem] = useState<any>();
    const [organization, setOrganization] = useState<Organization>();
    const [events, setEvents] = useState<Event[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (createEventSubscription && createEventSubscription[0] !== undefined && createEventSubscription[0] !== currentEventSubscriptionItem) {
            setCurrentEventSubscriptionItem(createEventSubscription[0]);
            getOrganizations();
        }
    }, [createEventSubscription]);

    useEffect(() => {
        if (deleteEventSubscription && deleteEventSubscription[0] !== undefined && deleteEventSubscription[0] !== currentEventSubscriptionItem) {
            setCurrentEventSubscriptionItem(deleteEventSubscription[0]);
            getOrganizations();
        }
    }, [deleteEventSubscription]);

    useEffect(() => {
        getOrganizations();
    }, [user, user.id]);

    async function getOrganizations() {
        setIsLoading(true);
        const queryResult = await getOrganizationsByPersonId(user.id);
        if (queryResult.isSuccess) {
            const organizationList = queryResult.result;
            if (organizationList && organizationList.length) {
                let eventList: Event[] = [];
                setEvents([]);
                for (var i = 0; i < organizationList.length; i++) {
                    const newEvents = await getEventsByOrganization(organizationList[i].id);
                    if (newEvents) {
                        eventList = eventList.concat(newEvents);
                    }
                }
                const sortedByDate = sortEventsByDate(eventList) as Event[];
                setEvents(sortedByDate);
            }
            setOrganization(queryResult.result[0]); //To Do: make alphabetical or chronological?
        }
        setIsLoading(false);
    }

    async function getEventsByOrganization(id: string): Promise<Event[] | null> {
        const queryResult = await getEventsByOrganizationId(id);
        const newEvents = queryResult.result;
        if (queryResult.isSuccess) {
            return newEvents;
        } else {
            return null;
        }
    }

    const handleSearchInput = async () => {
        
    }

    return (
        <IonPage className="bg-light">
            <Header />
            {((!isWindows()) && (isPlatform("mobile"))) && <SearchAndAddHeader title="Events" organization={organization} />}
            <IonContent>
                {(isWindows() || (isPlatform("desktop"))) && (
                    <>
                        <PageTitle title="Events" />
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="10">
                                <SearchIcon onSearch={handleSearchInput} />
                                <CreateEventButton organization={organization} />
                            </IonCol>
                        </IonRow>
                    </>
                )}
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        {isLoading ?
                            <Spinner />
                            :
                            <EventsList events={events} />
                        }
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default AllEventsPage;