/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      name
      type
      isDefault
      streetAddress1
      streetAddress2
      city
      provState
      zip
      country
      notes
      createdAt
      updatedAt
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $id: ID
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAddresses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlert = /* GraphQL */ `
  query GetAlert($id: ID!) {
    getAlert(id: $id) {
      id
      type
      title
      body
      imageKey
      category
      organizationId
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      messageSegments
      subscriberCount
      totalCost
      blockIdsUsed
      fromUnlimitedEvent
      isIncoming
      sentFromPhoneNumber
      recipientPhoneNumbers
      successSIDList
      errorSIDList
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      alertEventId
    }
  }
`;
export const listAlerts = /* GraphQL */ `
  query ListAlerts(
    $id: ID
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAlerts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      nextToken
    }
  }
`;
export const getAuditor = /* GraphQL */ `
  query GetAuditor($id: ID!) {
    getAuditor(id: $id) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      name
      phoneNumber
      email
      status
      statusNote
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listAuditors = /* GraphQL */ `
  query ListAuditors(
    $id: ID
    $filter: ModelAuditorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAuditors(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getBacklogEventResult = /* GraphQL */ `
  query GetBacklogEventResult($id: ID!) {
    getBacklogEventResult(id: $id) {
      id
      organizationId
      organizationMemberId
      organizationResultId
      eventId
      eventName
      eventStartDate
      eventEndDate
      eventDivisionId
      eventDivisionName
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClassId
      eventClassName
      eventClassNumber
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      riderName
      horseName
      place
      pointsEarned
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      backlogEventResultPrizeMoneyTableId
    }
  }
`;
export const listBacklogEventResults = /* GraphQL */ `
  query ListBacklogEventResults(
    $id: ID
    $filter: ModelBacklogEventResultFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBacklogEventResults(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organizationMemberId
        organizationResultId
        eventId
        eventName
        eventStartDate
        eventEndDate
        eventDivisionId
        eventDivisionName
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClassId
        eventClassName
        eventClassNumber
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        riderName
        horseName
        place
        pointsEarned
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        backlogEventResultPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const getBarn = /* GraphQL */ `
  query GetBarn($id: ID!) {
    getBarn(id: $id) {
      id
      name
      nickname
      description
      type
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      ownerName
      ownerPersonId
      secondaryOwnerName
      secondaryOwnerPersonId
      members {
        items {
          id
          name
          barnId
          personId
          permissionLevel
          status
          roles
          createdBy
          createdOn
          updatedOn
        }
        nextToken
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      barnContactId
      barnLocationId
    }
  }
`;
export const listBarns = /* GraphQL */ `
  query ListBarns(
    $id: ID
    $filter: ModelBarnFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBarns(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      nextToken
    }
  }
`;
export const getBarnMember = /* GraphQL */ `
  query GetBarnMember($id: ID!) {
    getBarnMember(id: $id) {
      id
      name
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      permissionLevel
      status
      roles
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const listBarnMembers = /* GraphQL */ `
  query ListBarnMembers(
    $id: ID
    $filter: ModelBarnMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBarnMembers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        permissionLevel
        status
        roles
        createdBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getBlock = /* GraphQL */ `
  query GetBlock($id: ID!) {
    getBlock(id: $id) {
      id
      name
      type
      personid
      organizationId
      purchasedOn
      expiresOn
      totalCredits
      usedCredits
      status
      price
      amountPaid
      isPromotion
      isFree
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listBlocks = /* GraphQL */ `
  query ListBlocks(
    $id: ID
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBlocks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        personid
        organizationId
        purchasedOn
        expiresOn
        totalCredits
        usedCredits
        status
        price
        amountPaid
        isPromotion
        isFree
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getClass = /* GraphQL */ `
  query GetClass($id: ID!) {
    getClass(id: $id) {
      id
      name
      nickname
      divisionId
      type
      description
      discipline
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoiceClassesId
    }
  }
`;
export const listClasses = /* GraphQL */ `
  query ListClasses(
    $id: ID
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClasses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceClassesId
      }
      nextToken
    }
  }
`;
export const getClassOrder = /* GraphQL */ `
  query GetClassOrder($id: ID!) {
    getClassOrder(id: $id) {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      entryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      order
      isRandom
      createdOn
      updatedOn
    }
  }
`;
export const listClassOrders = /* GraphQL */ `
  query ListClassOrders(
    $id: ID
    $filter: ModelClassOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClassOrders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        entryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        order
        isRandom
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getClinician = /* GraphQL */ `
  query GetClinician($id: ID!) {
    getClinician(id: $id) {
      id
      name
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      judgeId
      contactId
      rating {
        type
        license
      }
      usefNumber
      ecNumber
      price
      status
      paymentChoice {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      paymentVerified
      stripeAccountId
      totalEarned
      location
      disciplines
      experience
      personal
      preferences
      hasIHSAExperience
      hasIEAExperience
      createdOn
      updatedOn
      clinicianPaymentChoiceId
    }
  }
`;
export const listClinicians = /* GraphQL */ `
  query ListClinicians(
    $id: ID
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClinicians(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      name
      cell
      home
      work
      fax
      personalEmail
      personalEmailIsVerified
      workEmail
      workEmailIsVerified
      mailingAddress
      website
      facebookPage
      instagramHandle
      twitterHandle
      tiktokHandle
      emergencyContactName
      emergencyContactPhone
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $id: ID
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContacts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDivision = /* GraphQL */ `
  query GetDivision($id: ID!) {
    getDivision(id: $id) {
      id
      name
      nickname
      description
      discipline
      isSearchable
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoiceDivisionsId
    }
  }
`;
export const listDivisions = /* GraphQL */ `
  query ListDivisions(
    $id: ID
    $filter: ModelDivisionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDivisions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceDivisionsId
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      nickname
      createdBy
      manager
      startDate
      endDate
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contactId
      contactIds
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      type
      disciplines
      eventOptions {
        autoAcceptNewEntries
        usesTextAlerts
        acceptsIncomingTextAlerts
        hasUnlimitedCredits
        usesEventManagement
        usesStablingManagement
        usesVendorManagement
        usesMediaTools
        usesMarketingTools
        usesFinanceTools
        usesJudgeApp
      }
      paymentOptions {
        acceptsChecks
        acceptsCash
        acceptsACH
        acceptsCC
      }
      invoiceOptions {
        classFeeTax
        sendEmails
      }
      entryOptions {
        displayHorse2Input
        displayRider2Input
        displayRider3Input
        requiresCogginsDocument
        requiresCogginsData
        maximumNumberClassesOnEntry
      }
      officials {
        items {
          id
          name
          position
          eventId
          personId
          contactId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      entriesStatus
      showStatus
      usefID
      usefSoftwareKey
      ecID
      ecPassword
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      eventAddressId
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $id: ID
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      nextToken
    }
  }
`;
export const getEventAdd = /* GraphQL */ `
  query GetEventAdd($id: ID!) {
    getEventAdd(id: $id) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listEventAdds = /* GraphQL */ `
  query ListEventAdds(
    $id: ID
    $filter: ModelEventAddFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventAdds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventAlertSetting = /* GraphQL */ `
  query GetEventAlertSetting($id: ID!) {
    getEventAlertSetting(id: $id) {
      id
      eventId
      categories
      useDescription
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listEventAlertSettings = /* GraphQL */ `
  query ListEventAlertSettings(
    $id: ID
    $filter: ModelEventAlertSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventAlertSettings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        categories
        useDescription
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventAuditor = /* GraphQL */ `
  query GetEventAuditor($id: ID!) {
    getEventAuditor(id: $id) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventauditorid
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorEventId
    }
  }
`;
export const listEventAuditors = /* GraphQL */ `
  query ListEventAuditors(
    $id: ID
    $filter: ModelEventAuditorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventAuditors(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      nextToken
    }
  }
`;
export const getOrganizationAuditor = /* GraphQL */ `
  query GetOrganizationAuditor($id: ID!) {
    getOrganizationAuditor(id: $id) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      howSignedUp
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventauditorid
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      organizationAuditorPersonId
      organizationAuditorOrganizationId
    }
  }
`;
export const listOrganizationAuditors = /* GraphQL */ `
  query ListOrganizationAuditors(
    $id: ID
    $filter: ModelOrganizationAuditorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizationAuditors(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      nextToken
    }
  }
`;
export const getEventAuditorFilter = /* GraphQL */ `
  query GetEventAuditorFilter($id: ID!) {
    getEventAuditorFilter(id: $id) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      eventauditorid
      eventAuditorId
      eventAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationAuditorId
      organizationAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      filterId
      filter {
        id
        eventId
        organizationID
        name
        options
        isRequired
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      options
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorFilterOrganizationId
    }
  }
`;
export const listEventAuditorFilters = /* GraphQL */ `
  query ListEventAuditorFilters(
    $id: ID
    $filter: ModelEventAuditorFilterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventAuditorFilters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        eventauditorid
        eventAuditorId
        eventAuditor {
          id
          personId
          eventId
          auditorId
          name
          phoneNumber
          status
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorEventId
        }
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationAuditorId
        organizationAuditor {
          id
          personId
          howSignedUp
          organizationId
          auditorId
          name
          phoneNumber
          status
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          organizationAuditorPersonId
          organizationAuditorOrganizationId
        }
        filterId
        filter {
          id
          eventId
          organizationID
          name
          options
          isRequired
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        options
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorFilterOrganizationId
      }
      nextToken
    }
  }
`;
export const getEventBeddingType = /* GraphQL */ `
  query GetEventBeddingType($id: ID!) {
    getEventBeddingType(id: $id) {
      id
      eventId
      organizationId
      organizationBeddingTypeId
      name
      description
      type
      pricePerBag
      taxPerBag
      createdOn
      updatedOn
    }
  }
`;
export const listEventBeddingTypes = /* GraphQL */ `
  query ListEventBeddingTypes(
    $id: ID
    $filter: ModelEventBeddingTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventBeddingTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        organizationId
        organizationBeddingTypeId
        name
        description
        type
        pricePerBag
        taxPerBag
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventBreak = /* GraphQL */ `
  query GetEventBreak($id: ID!) {
    getEventBreak(id: $id) {
      id
      eventId
      name
      time
      isDisplayedOnSchedule
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listEventBreaks = /* GraphQL */ `
  query ListEventBreaks(
    $id: ID
    $filter: ModelEventBreakFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventBreaks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        name
        time
        isDisplayedOnSchedule
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventClass = /* GraphQL */ `
  query GetEventClass($id: ID!) {
    getEventClass(id: $id) {
      id
      name
      eventId
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceClassesId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryOption
      entryFee
      fees {
        items {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        nextToken
      }
      prizeMoney
      entries {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      completedVerifyEntries
      currentNumberEntries
      numberEntriesThatCompeted
      minNumberEntries
      maxNumberEntries
      estimatedTimePerRound
      isCaliforniaSplit
      isCombined
      combinedEventClassId
      combinedEventDivisionId
      jumpHeight
      isScheduled
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventClassPointTableId
      eventClassPrizeMoneyTableId
    }
  }
`;
export const listEventClasses = /* GraphQL */ `
  query ListEventClasses(
    $id: ID
    $filter: ModelEventClassFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventClasses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const getEventClassEntry = /* GraphQL */ `
  query GetEventClassEntry($id: ID!) {
    getEventClassEntry(id: $id) {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      status
      selectedEntryOption
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listEventClassEntries = /* GraphQL */ `
  query ListEventClassEntries(
    $id: ID
    $filter: ModelEventClassEntryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventClassEntries(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventDay = /* GraphQL */ `
  query GetEventDay($id: ID!) {
    getEventDay(id: $id) {
      id
      eventId
      awsdate
      dayofweek
      date
      month
      year
      createdOn
      updatedOn
    }
  }
`;
export const listEventDays = /* GraphQL */ `
  query ListEventDays(
    $id: ID
    $filter: ModelEventDayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventDays(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        awsdate
        dayofweek
        date
        month
        year
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventDivision = /* GraphQL */ `
  query GetEventDivision($id: ID!) {
    getEventDivision(id: $id) {
      id
      eventId
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceDivisionsId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClasses {
        items {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        nextToken
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      entryFee
      prizeMoney
      jumpHeight
      minNumberEntriesPerClass
      maxNumberEntriesPerClass
      isCaliforniaSplit
      isCombined
      combinedEventDivisionId
      combinedEventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventDivisionOrganizationDivisionId
      eventDivisionPointTableId
      eventDivisionPrizeMoneyTableId
    }
  }
`;
export const listEventDivisions = /* GraphQL */ `
  query ListEventDivisions(
    $id: ID
    $filter: ModelEventDivisionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventDivisions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const getEventEntry = /* GraphQL */ `
  query GetEventEntry($id: ID!) {
    getEventEntry(id: $id) {
      id
      eventId
      status
      statusNote
      personId
      number
      isCheckedIn
      isCheckedOut
      contactId
      primarycontact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      riderName
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      secondRiderName
      secondRiderId
      secondRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      thirdRiderName
      thirdRiderId
      thirdRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseName
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerName
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      secondOwnerName
      secondOwnerId
      secondOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      thirdOwnerName
      thirdOwnerId
      thirdOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerName
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      secondTrainerName
      secondTrainerId
      secondTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      thirdTrainerName
      thirdTrainerId
      thirdTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      stablingRequestId
      stablingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        departureDateTime
        stallTypeId
        stallType {
          id
          eventId
          organizationId
          organizationStallTypeId
          name
          description
          type
          pricePerStall
          taxPerStall
          createdOn
          updatedOn
        }
        quantityNeeded
        basePrice
        taxA
        taxB
        totalPrice
        status
        statusNote
        requestNote
        stallSetId
        stallSet {
          id
          eventId
          organizationId
          organizationStallSetId
          name
          location
          available
          totalAvailable
          used
          totalUsed
          createdOn
          updatedOn
        }
        stallLocation
        stallNumbers
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
      }
      beddingRequestId
      beddingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        dateTimeNeeded
        deliveryInfo
        beddingType
        quantityNeeded
        basePrice
        taxA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        totalPrice
        status
        statusNote
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
        beddingRequestTaxAId
        beddingRequestTaxBId
      }
      markedDoesNotNeedStalls
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      eventRulesAgreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      note
      divisionList
      classList {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      fees {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventEntryPrizeMoneyRecipientContactId
    }
  }
`;
export const listEventEntries = /* GraphQL */ `
  query ListEventEntries(
    $id: ID
    $filter: ModelEventEntryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventEntries(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      nextToken
    }
  }
`;
export const getEventEntryFee = /* GraphQL */ `
  query GetEventEntryFee($id: ID!) {
    getEventEntryFee(id: $id) {
      id
      name
      description
      status
      amount
      quantity
      taxA
      taxB
      eventId
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        eventInvoiceFeesId
        feeTaxRateAId
        feeTaxRateBId
      }
      eventFeeId
      eventFee {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const listEventEntryFees = /* GraphQL */ `
  query ListEventEntryFees(
    $id: ID
    $filter: ModelEventEntryFeeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventEntryFees(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        status
        amount
        quantity
        taxA
        taxB
        eventId
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        eventFeeId
        eventFee {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventEntryNumbers = /* GraphQL */ `
  query GetEventEntryNumbers($id: ID!) {
    getEventEntryNumbers(id: $id) {
      id
      eventId
      name
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const listEventEntryNumbers = /* GraphQL */ `
  query ListEventEntryNumbers(
    $id: ID
    $filter: ModelEventEntryNumbersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventEntryNumbers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        name
        available
        totalAvailable
        used
        totalUsed
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventFee = /* GraphQL */ `
  query GetEventFee($id: ID!) {
    getEventFee(id: $id) {
      id
      name
      amount
      description
      eventId
      createdBy
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        eventInvoiceFeesId
        feeTaxRateAId
        feeTaxRateBId
      }
      splitAcrossEntries
      entries {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdOn
      updatedOn
    }
  }
`;
export const listEventFees = /* GraphQL */ `
  query ListEventFees(
    $id: ID
    $filter: ModelEventFeeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventFees(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventFilter = /* GraphQL */ `
  query GetEventFilter($id: ID!) {
    getEventFilter(id: $id) {
      id
      eventId
      organizationID
      name
      options
      isRequired
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listEventFilters = /* GraphQL */ `
  query ListEventFilters(
    $id: ID
    $filter: ModelEventFilterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventFilters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        organizationID
        name
        options
        isRequired
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventInvoice = /* GraphQL */ `
  query GetEventInvoice($id: ID!) {
    getEventInvoice(id: $id) {
      id
      eventId
      status
      statusNote
      number
      stripeInvoiceNumber
      entryNumber
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      payerType
      payerStripeId
      payerContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipientStripeId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      divisions {
        items {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        nextToken
      }
      classes {
        items {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        nextToken
      }
      fees {
        items {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        nextToken
      }
      entryFeeTotalAmount
      otherFeeTotalAmount
      stablingAmount
      beddingAmount
      feedAmount
      results {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      prizeMoneyAmount
      amountToDeductFromInvoice
      amountToPayRecipientViaCheck
      totalDue
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoicePayerContactId
      eventInvoicePrizeMoneyRecipientId
      eventInvoicePrizeMoneyRecipientContactId
    }
  }
`;
export const listEventInvoices = /* GraphQL */ `
  query ListEventInvoices(
    $id: ID
    $filter: ModelEventInvoiceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventInvoices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        status
        statusNote
        number
        stripeInvoiceNumber
        entryNumber
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        payerType
        payerStripeId
        payerContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipientStripeId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        divisions {
          nextToken
        }
        classes {
          nextToken
        }
        fees {
          nextToken
        }
        entryFeeTotalAmount
        otherFeeTotalAmount
        stablingAmount
        beddingAmount
        feedAmount
        results {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        prizeMoneyAmount
        amountToDeductFromInvoice
        amountToPayRecipientViaCheck
        totalDue
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoicePayerContactId
        eventInvoicePrizeMoneyRecipientId
        eventInvoicePrizeMoneyRecipientContactId
      }
      nextToken
    }
  }
`;
export const getEventJudge = /* GraphQL */ `
  query GetEventJudge($id: ID!) {
    getEventJudge(id: $id) {
      id
      name
      eventId
      personId
      judgeId
      contactId
      rating {
        type
        license
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEventJudges = /* GraphQL */ `
  query ListEventJudges(
    $id: ID
    $filter: ModelEventJudgeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventJudges(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        eventId
        personId
        judgeId
        contactId
        rating {
          type
          license
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventOfficial = /* GraphQL */ `
  query GetEventOfficial($id: ID!) {
    getEventOfficial(id: $id) {
      id
      name
      position
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      personId
      contactId
      createdAt
      updatedAt
    }
  }
`;
export const listEventOfficials = /* GraphQL */ `
  query ListEventOfficials(
    $id: ID
    $filter: ModelEventOfficialFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventOfficials(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        position
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        personId
        contactId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventResult = /* GraphQL */ `
  query GetEventResult($id: ID!) {
    getEventResult(id: $id) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventClassEntryId
      eventClassEntry {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      resultSet
      place
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      pointTables {
        items {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        nextToken
      }
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultEntryId
      eventResultEventClassEntryId
      eventResultPrizeMoneyTableId
    }
  }
`;
export const listEventResults = /* GraphQL */ `
  query ListEventResults(
    $id: ID
    $filter: ModelEventResultFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventResults(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const getEventRing = /* GraphQL */ `
  query GetEventRing($id: ID!) {
    getEventRing(id: $id) {
      id
      name
      eventId
      ringId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      ring {
        id
        name
        displayOrder
        organizationId
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const listEventRings = /* GraphQL */ `
  query ListEventRings(
    $id: ID
    $filter: ModelEventRingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventRings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        eventId
        ringId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        ring {
          id
          name
          displayOrder
          organizationId
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventRules = /* GraphQL */ `
  query GetEventRules($id: ID!) {
    getEventRules(id: $id) {
      id
      eventId
      title
      text
      requiresAgreementPerEntry
      agreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listEventRules = /* GraphQL */ `
  query ListEventRules(
    $id: ID
    $filter: ModelEventRulesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventRules(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        title
        text
        requiresAgreementPerEntry
        agreements {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventRulesAgreement = /* GraphQL */ `
  query GetEventRulesAgreement($id: ID!) {
    getEventRulesAgreement(id: $id) {
      id
      eventId
      eventRuleId
      eventRule {
        id
        eventId
        title
        text
        requiresAgreementPerEntry
        agreements {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      name
      personId
      personType
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listEventRulesAgreements = /* GraphQL */ `
  query ListEventRulesAgreements(
    $id: ID
    $filter: ModelEventRulesAgreementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventRulesAgreements(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        eventRuleId
        eventRule {
          id
          eventId
          title
          text
          requiresAgreementPerEntry
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        name
        personId
        personType
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventScratch = /* GraphQL */ `
  query GetEventScratch($id: ID!) {
    getEventScratch(id: $id) {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listEventScratches = /* GraphQL */ `
  query ListEventScratches(
    $id: ID
    $filter: ModelEventScratchFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventScratches(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventStallSet = /* GraphQL */ `
  query GetEventStallSet($id: ID!) {
    getEventStallSet(id: $id) {
      id
      eventId
      organizationId
      organizationStallSetId
      name
      location
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const listEventStallSets = /* GraphQL */ `
  query ListEventStallSets(
    $id: ID
    $filter: ModelEventStallSetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventStallSets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        organizationId
        organizationStallSetId
        name
        location
        available
        totalAvailable
        used
        totalUsed
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getEventStallType = /* GraphQL */ `
  query GetEventStallType($id: ID!) {
    getEventStallType(id: $id) {
      id
      eventId
      organizationId
      organizationStallTypeId
      name
      description
      type
      pricePerStall
      taxPerStall
      createdOn
      updatedOn
    }
  }
`;
export const listEventStallTypes = /* GraphQL */ `
  query ListEventStallTypes(
    $id: ID
    $filter: ModelEventStallTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventStallTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        organizationId
        organizationStallTypeId
        name
        description
        type
        pricePerStall
        taxPerStall
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getFee = /* GraphQL */ `
  query GetFee($id: ID!) {
    getFee(id: $id) {
      id
      name
      description
      createdBy
      organizationId
      stripeId
      altId
      isFixed
      isPercentage
      totalPrice
      quantity
      isPerEntry
      isPerHorse
      isPerRider
      isPerClass
      isPerTrainer
      isRefundable
      isChargedAtTimeOfEntry
      isStartedOnEntryDate
      isHiddenFromEntryForm
      startDate
      startTime
      taxA
      taxB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      eventInvoiceFeesId
      feeTaxRateAId
      feeTaxRateBId
    }
  }
`;
export const listFees = /* GraphQL */ `
  query ListFees(
    $id: ID
    $filter: ModelFeeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFees(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        eventInvoiceFeesId
        feeTaxRateAId
        feeTaxRateBId
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      clinicianId
      personId
      technical
      style
      turnout
      general
      score
      status
      submissionFeedback {
        id
        type
        submission {
          id
          title
          description
          videoLength
          riderId
          personId
          totalPrice
          status
          createdOn
          updatedOn
        }
        submissionId
        feedback {
          id
          clinicianId
          personId
          technical
          style
          turnout
          general
          score
          status
          createdOn
          updatedOn
          feedbackSubmissionFeedbackId
        }
        feedbackId
        personId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        clinician {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        clinicianId
        submissionStatus
        feedbackStatus
        clinicianAmount
        declineReason
        permissionToPost
        dateSubmitted
        dateCompleted
        createdOn
        updatedOn
        submissionFeedbackClinicianId
      }
      createdOn
      updatedOn
      feedbackSubmissionFeedbackId
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $id: ID
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeedbacks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        clinicianId
        personId
        technical
        style
        turnout
        general
        score
        status
        submissionFeedback {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        createdOn
        updatedOn
        feedbackSubmissionFeedbackId
      }
      nextToken
    }
  }
`;
export const getGameInput = /* GraphQL */ `
  query GetGameInput($id: ID!) {
    getGameInput(id: $id) {
      id
      eventId
      eventClassId
      backNumber
      round
      score
      isMasterRecord
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const listGameInputs = /* GraphQL */ `
  query ListGameInputs(
    $id: ID
    $filter: ModelGameInputFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGameInputs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        eventClassId
        backNumber
        round
        score
        isMasterRecord
        createdBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getHorse = /* GraphQL */ `
  query GetHorse($id: ID!) {
    getHorse(id: $id) {
      id
      name
      nickname
      previousNames
      personId
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      ownerName
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      microchipNumber
      passportNumber
      dateOfBirth
      breed
      sex
      color
      markings
      height
      foalState
      cogginsNumber
      cogginsDate
      cogginsState
      coggins {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      highestLevelCompeted
      greenEligibility
      createdOn
      updatedOn
    }
  }
`;
export const listHorses = /* GraphQL */ `
  query ListHorses(
    $id: ID
    $filter: ModelHorseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHorses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getPedigree = /* GraphQL */ `
  query GetPedigree($id: ID!) {
    getPedigree(id: $id) {
      id
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      sireId
      sireName
      sire {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      damId
      damName
      dam {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      birthplace {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      breederName
      breederContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      breederId
      breedRegistryInfo
      createdOn
      updatedOn
      pedigreeBirthplaceId
      pedigreeBreederContactId
    }
  }
`;
export const listPedigrees = /* GraphQL */ `
  query ListPedigrees(
    $id: ID
    $filter: ModelPedigreeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPedigrees(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        sireId
        sireName
        sire {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        damId
        damName
        dam {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        birthplace {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        breederName
        breederContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        breederId
        breedRegistryInfo
        createdOn
        updatedOn
        pedigreeBirthplaceId
        pedigreeBreederContactId
      }
      nextToken
    }
  }
`;
export const getMeasurementRecord = /* GraphQL */ `
  query GetMeasurementRecord($id: ID!) {
    getMeasurementRecord(id: $id) {
      id
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      date
      status
      height
      heightOfHeel
      shoeingStatus
      steward
      official
      createdOn
      updatedOn
    }
  }
`;
export const listMeasurementRecords = /* GraphQL */ `
  query ListMeasurementRecords(
    $id: ID
    $filter: ModelMeasurementRecordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeasurementRecords(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        date
        status
        height
        heightOfHeel
        shoeingStatus
        steward
        official
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getHorseShow = /* GraphQL */ `
  query GetHorseShow($id: ID!) {
    getHorseShow(id: $id) {
      id
      name
      nickname
      eventId
      ratings {
        id
        eventId
        governingBody
        rating
        createdAt
        updatedAt
      }
      judges {
        id
        name
        eventId
        personId
        judgeId
        contactId
        rating {
          type
          license
        }
        createdAt
        updatedAt
      }
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listHorseShows = /* GraphQL */ `
  query ListHorseShows(
    $id: ID
    $filter: ModelHorseShowFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHorseShows(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        nickname
        eventId
        ratings {
          id
          eventId
          governingBody
          rating
          createdAt
          updatedAt
        }
        judges {
          id
          name
          eventId
          personId
          judgeId
          contactId
          createdAt
          updatedAt
        }
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getHorseShowRating = /* GraphQL */ `
  query GetHorseShowRating($id: ID!) {
    getHorseShowRating(id: $id) {
      id
      eventId
      governingBody
      rating
      createdAt
      updatedAt
    }
  }
`;
export const listHorseShowRatings = /* GraphQL */ `
  query ListHorseShowRatings(
    $id: ID
    $filter: ModelHorseShowRatingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHorseShowRatings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        governingBody
        rating
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      createdBy
      creatorEmail
      invitee
      inviteeEmail
      status
      organizationId
      barnId
      permissionLevel
      roles
      createdOn
      updatedOn
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $id: ID
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvitations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdBy
        creatorEmail
        invitee
        inviteeEmail
        status
        organizationId
        barnId
        permissionLevel
        roles
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getMembership = /* GraphQL */ `
  query GetMembership($id: ID!) {
    getMembership(id: $id) {
      id
      name
      description
      membershipId
      backNumber
      membershipStatus
      amountPaid
      organizationMembershipTypeId
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      type
      personName
      personId
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      teamId
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationName
      isJunior
      isProfessional
      dateMembershipEnds
      volunteerHours
      meetingsAttended
      showsAttended
      personEmail
      gradeLevel
      aelDivision
      aelTeamRole
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      organizationAgreements {
        organizationRuleId
        organizationRuleTitle
        firstName
        lastName
        personId
        createdOn
        updatedOn
      }
      contactPersonId
      groupContactId
      groupContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      groupLeaderContactId
      groupLeaderContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      createdBy
      createdOn
      updatedOn
      personMembershipsId
      membershipOrganizationId
    }
  }
`;
export const listMemberships = /* GraphQL */ `
  query ListMemberships(
    $id: ID
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMemberships(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        membershipId
        backNumber
        membershipStatus
        amountPaid
        organizationMembershipTypeId
        organizationMembershipType {
          id
          name
          description
          order
          organizationId
          membershipIdValues
          nextAvailableMembershipId
          price
          category
          schedule
          isStartAtTimeOfPurchase
          setStartDate
          acceptOnlineApplications
          createdOn
          updatedOn
          organizationMembershipTypeOrganizationId
        }
        type
        personName
        personId
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        teamId
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationName
        isJunior
        isProfessional
        dateMembershipEnds
        volunteerHours
        meetingsAttended
        showsAttended
        personEmail
        gradeLevel
        aelDivision
        aelTeamRole
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        organizationAgreements {
          organizationRuleId
          organizationRuleTitle
          firstName
          lastName
          personId
          createdOn
          updatedOn
        }
        contactPersonId
        groupContactId
        groupContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        groupLeaderContactId
        groupLeaderContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        createdBy
        createdOn
        updatedOn
        personMembershipsId
        membershipOrganizationId
      }
      nextToken
    }
  }
`;
export const getOrganizationMembershipType = /* GraphQL */ `
  query GetOrganizationMembershipType($id: ID!) {
    getOrganizationMembershipType(id: $id) {
      id
      name
      description
      order
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      membershipIdValues
      nextAvailableMembershipId
      price
      category
      schedule
      isStartAtTimeOfPurchase
      setStartDate
      acceptOnlineApplications
      applicationFields {
        firstName
        lastName
        dateOfBirth
        contact
        gradeLevel
        barnName
        teamName
        backNumber
        groupName
        groupLeader
        groupLeaderContactInfo
        collectMemberDetailsTogether
        maxNumberOfGroupMembers
        isTeam
        horseName
        horseHeight
        horseOwnerName
        cogginsInfo
        cogginsUpload
      }
      applicationOptions {
        requireApproval
        autoAssignIds
        acceptRSPPayment
        acceptOtherPayment
      }
      organizationDocuments {
        id
        name
      }
      organizationRules {
        id
        title
        body
        isRequired
      }
      seasons {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdOn
      updatedOn
      organizationMembershipTypeOrganizationId
    }
  }
`;
export const listOrganizationMembershipTypes = /* GraphQL */ `
  query ListOrganizationMembershipTypes(
    $id: ID
    $filter: ModelOrganizationMembershipTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizationMembershipTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      nickname
      description
      type
      industry
      addresses
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      stripeId
      stripeCustomerId
      paymentMethodId
      website
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      lastUpdatedBy
      organizationOptions {
        usesTextAlerts
        acceptsIncomingTextAlerts
      }
      urlBackHalf
      colors
      logos {
        logoURL
        key
        logo {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
      }
      createdOn
      updatedOn
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $id: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getOrganizationBeddingType = /* GraphQL */ `
  query GetOrganizationBeddingType($id: ID!) {
    getOrganizationBeddingType(id: $id) {
      id
      organizationId
      name
      description
      type
      pricePerBag
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const listOrganizationBeddingTypes = /* GraphQL */ `
  query ListOrganizationBeddingTypes(
    $id: ID
    $filter: ModelOrganizationBeddingTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizationBeddingTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        name
        description
        type
        pricePerBag
        taxA
        taxB
        taxRateIdA
        taxRateIdB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getOrganizationClass = /* GraphQL */ `
  query GetOrganizationClass($id: ID!) {
    getOrganizationClass(id: $id) {
      id
      name
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceClassesId
      }
      organizationId
      organizationDivisionId
      hasChampionship
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryFee
      prizeMoney
      minNumberEntries
      maxNumberEntries
      jumpHeight
      estimatedTimePerRound
      createdBy
      displayOrder
      lastUpdatedBy
      createdOn
      updatedOn
      organizationClassPointTableId
      organizationClassPrizeMoneyTableId
    }
  }
`;
export const listOrganizationClasses = /* GraphQL */ `
  query ListOrganizationClasses(
    $id: ID
    $filter: ModelOrganizationClassFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizationClasses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const getOrganizationDivision = /* GraphQL */ `
  query GetOrganizationDivision($id: ID!) {
    getOrganizationDivision(id: $id) {
      id
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceDivisionsId
      }
      organizationId
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      jumpHeight
      minNumberEntries
      maxNumberEntries
      entryFee
      prizeMoney
      entryFeePerClass
      prizeMoneyPerClass
      createdBy
      lastUpdatedBy
      displayOrder
      createdOn
      updatedOn
      organizationDivisionPointTableId
      organizationDivisionPrizeMoneyTableId
    }
  }
`;
export const listOrganizationDivisions = /* GraphQL */ `
  query ListOrganizationDivisions(
    $id: ID
    $filter: ModelOrganizationDivisionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizationDivisions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const getOrganizationStallSet = /* GraphQL */ `
  query GetOrganizationStallSet($id: ID!) {
    getOrganizationStallSet(id: $id) {
      id
      organizationId
      name
      location
      available
      createdOn
      updatedOn
    }
  }
`;
export const listOrganizationStallSets = /* GraphQL */ `
  query ListOrganizationStallSets(
    $id: ID
    $filter: ModelOrganizationStallSetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizationStallSets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        name
        location
        available
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getOrganizationStallType = /* GraphQL */ `
  query GetOrganizationStallType($id: ID!) {
    getOrganizationStallType(id: $id) {
      id
      organizationId
      name
      description
      type
      pricePerStall
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const listOrganizationStallTypes = /* GraphQL */ `
  query ListOrganizationStallTypes(
    $id: ID
    $filter: ModelOrganizationStallTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizationStallTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        name
        description
        type
        pricePerStall
        taxA
        taxB
        taxRateIdA
        taxRateIdB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getOwner = /* GraphQL */ `
  query GetOwner($id: ID!) {
    getOwner(id: $id) {
      id
      name
      type
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnId
      createdBy
      createdOn
      updatedOn
      ownerContactId
    }
  }
`;
export const listOwners = /* GraphQL */ `
  query ListOwners(
    $id: ID
    $filter: ModelOwnerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOwners(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      nextToken
    }
  }
`;
export const getPayer = /* GraphQL */ `
  query GetPayer($id: ID!) {
    getPayer(id: $id) {
      id
      name
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      paymentMethod {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      payerContactId
      payerPaymentMethodId
    }
  }
`;
export const listPayers = /* GraphQL */ `
  query ListPayers(
    $id: ID
    $filter: ModelPayerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPayers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      nextToken
    }
  }
`;
export const getPaymentMethod = /* GraphQL */ `
  query GetPaymentMethod($id: ID!) {
    getPaymentMethod(id: $id) {
      id
      personId
      organizationId
      type
      stripeId
      altId
      value
      createdOn
      updatedOn
    }
  }
`;
export const listPaymentMethods = /* GraphQL */ `
  query ListPaymentMethods(
    $id: ID
    $filter: ModelPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPaymentMethods(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      email
      firstName
      lastName
      isVerified
      roles
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      personalInformationId
      personalInformation {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        dateOfBirth
        gender
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      pictureURL
      picture {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      disciplines
      stripeId
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const listPeople = /* GraphQL */ `
  query ListPeople(
    $id: ID
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPeople(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getPersonName = /* GraphQL */ `
  query GetPersonName($id: ID!) {
    getPersonName(id: $id) {
      id
      personId
      title
      firstName
      middleName
      maidenName
      lastName
      suffix
      legalName
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const listPersonNames = /* GraphQL */ `
  query ListPersonNames(
    $id: ID
    $filter: ModelPersonNameFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPersonNames(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        personId
        title
        firstName
        middleName
        maidenName
        lastName
        suffix
        legalName
        displayName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPersonalInformation = /* GraphQL */ `
  query GetPersonalInformation($id: ID!) {
    getPersonalInformation(id: $id) {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      dateOfBirth
      gender
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPersonalInformations = /* GraphQL */ `
  query ListPersonalInformations(
    $id: ID
    $filter: ModelPersonalInformationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPersonalInformations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        dateOfBirth
        gender
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPointTable = /* GraphQL */ `
  query GetPointTable($id: ID!) {
    getPointTable(id: $id) {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isAddValueToRegularFromEntryCount
      isAddValueToChampionshipFromEntryCount
      isUseChampionRule
      isUseReserveRule
      tiers {
        lowEntryCount
        highEntryCount
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      numberEntriesToReceiveSpecialClassPoints
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultPointTablesId
    }
  }
`;
export const listPointTables = /* GraphQL */ `
  query ListPointTables(
    $id: ID
    $filter: ModelPointTableFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPointTables(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      nextToken
    }
  }
`;
export const getPoll = /* GraphQL */ `
  query GetPoll($id: ID!) {
    getPoll(id: $id) {
      id
      title
      description
      responses {
        items {
          id
          pollId
          personId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPolls = /* GraphQL */ `
  query ListPolls(
    $id: ID
    $filter: ModelPollFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPolls(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        responses {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      status
      rating
      content
      organizationId
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $id: ID
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPosts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        status
        rating
        content
        organizationId
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrizeMoneyTable = /* GraphQL */ `
  query GetPrizeMoneyTable($id: ID!) {
    getPrizeMoneyTable(id: $id) {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isPercentage
      totalPot
      tiers {
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listPrizeMoneyTables = /* GraphQL */ `
  query ListPrizeMoneyTables(
    $id: ID
    $filter: ModelPrizeMoneyTableFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrizeMoneyTables(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getPromoCode = /* GraphQL */ `
  query GetPromoCode($id: ID!) {
    getPromoCode(id: $id) {
      id
      name
      stripeId
      isvalid
      isfundraiser
      ispercentdiscount
      isfixeddiscount
      percent
      fixed
      minamount
      startdate
      enddate
      createdOn
      updatedOn
    }
  }
`;
export const listPromoCodes = /* GraphQL */ `
  query ListPromoCodes(
    $id: ID
    $filter: ModelPromoCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPromoCodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        stripeId
        isvalid
        isfundraiser
        ispercentdiscount
        isfixeddiscount
        percent
        fixed
        minamount
        startdate
        enddate
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getResponse = /* GraphQL */ `
  query GetResponse($id: ID!) {
    getResponse(id: $id) {
      id
      pollId
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      value
      createdAt
      updatedAt
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $id: ID
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResponses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pollId
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRider = /* GraphQL */ `
  query GetRider($id: ID!) {
    getRider(id: $id) {
      id
      name
      location
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      personId
      age
      birthdate
      isProfessional
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
      riderContactId
    }
  }
`;
export const listRiders = /* GraphQL */ `
  query ListRiders(
    $id: ID
    $filter: ModelRiderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRiders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      nextToken
    }
  }
`;
export const getRing = /* GraphQL */ `
  query GetRing($id: ID!) {
    getRing(id: $id) {
      id
      name
      displayOrder
      organizationId
      createdOn
      updatedOn
    }
  }
`;
export const listRings = /* GraphQL */ `
  query ListRings(
    $id: ID
    $filter: ModelRingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        displayOrder
        organizationId
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getScheduleItem = /* GraphQL */ `
  query GetScheduleItem($id: ID!) {
    getScheduleItem(id: $id) {
      id
      eventId
      eventRingId
      eventDayId
      eventRing {
        id
        name
        eventId
        ringId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        ring {
          id
          name
          displayOrder
          organizationId
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
      }
      eventDay {
        id
        eventId
        awsdate
        dayofweek
        date
        month
        year
        createdOn
        updatedOn
      }
      displayOrder
      classId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      breakId
      eventBreak {
        id
        eventId
        name
        time
        isDisplayedOnSchedule
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      startTime
      createdOn
      updatedOn
    }
  }
`;
export const listScheduleItems = /* GraphQL */ `
  query ListScheduleItems(
    $id: ID
    $filter: ModelScheduleItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listScheduleItems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        eventRingId
        eventDayId
        eventRing {
          id
          name
          eventId
          ringId
          createdOn
          updatedOn
        }
        eventDay {
          id
          eventId
          awsdate
          dayofweek
          date
          month
          year
          createdOn
          updatedOn
        }
        displayOrder
        classId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        breakId
        eventBreak {
          id
          eventId
          name
          time
          isDisplayedOnSchedule
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        startTime
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getSeason = /* GraphQL */ `
  query GetSeason($id: ID!) {
    getSeason(id: $id) {
      id
      name
      description
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationMembershipTypes {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      startDate
      endDate
      disciplines
      createdOn
      updatedOn
      createdAt
      updatedAt
      seasonOrganizationId
    }
  }
`;
export const listSeasons = /* GraphQL */ `
  query ListSeasons(
    $id: ID
    $filter: ModelSeasonFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSeasons(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationMembershipTypes {
          nextToken
        }
        isActive
        startDate
        endDate
        disciplines
        createdOn
        updatedOn
        createdAt
        updatedAt
        seasonOrganizationId
      }
      nextToken
    }
  }
`;
export const getStablingRequest = /* GraphQL */ `
  query GetStablingRequest($id: ID!) {
    getStablingRequest(id: $id) {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      departureDateTime
      stallTypeId
      stallType {
        id
        eventId
        organizationId
        organizationStallTypeId
        name
        description
        type
        pricePerStall
        taxPerStall
        createdOn
        updatedOn
      }
      quantityNeeded
      basePrice
      taxA
      taxB
      totalPrice
      status
      statusNote
      requestNote
      stallSetId
      stallSet {
        id
        eventId
        organizationId
        organizationStallSetId
        name
        location
        available
        totalAvailable
        used
        totalUsed
        createdOn
        updatedOn
      }
      stallLocation
      stallNumbers
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const listStablingRequests = /* GraphQL */ `
  query ListStablingRequests(
    $id: ID
    $filter: ModelStablingRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStablingRequests(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        departureDateTime
        stallTypeId
        stallType {
          id
          eventId
          organizationId
          organizationStallTypeId
          name
          description
          type
          pricePerStall
          taxPerStall
          createdOn
          updatedOn
        }
        quantityNeeded
        basePrice
        taxA
        taxB
        totalPrice
        status
        statusNote
        requestNote
        stallSetId
        stallSet {
          id
          eventId
          organizationId
          organizationStallSetId
          name
          location
          available
          totalAvailable
          used
          totalUsed
          createdOn
          updatedOn
        }
        stallLocation
        stallNumbers
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getBeddingRequest = /* GraphQL */ `
  query GetBeddingRequest($id: ID!) {
    getBeddingRequest(id: $id) {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      dateTimeNeeded
      deliveryInfo
      beddingType
      quantityNeeded
      basePrice
      taxA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      totalPrice
      status
      statusNote
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
      beddingRequestTaxAId
      beddingRequestTaxBId
    }
  }
`;
export const listBeddingRequests = /* GraphQL */ `
  query ListBeddingRequests(
    $filter: ModelBeddingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBeddingRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        dateTimeNeeded
        deliveryInfo
        beddingType
        quantityNeeded
        basePrice
        taxA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        totalPrice
        status
        statusNote
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
        beddingRequestTaxAId
        beddingRequestTaxBId
      }
      nextToken
    }
  }
`;
export const getSubmission = /* GraphQL */ `
  query GetSubmission($id: ID!) {
    getSubmission(id: $id) {
      id
      title
      description
      video {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      videoLength
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      personId
      clinicians {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      totalPrice
      status
      submissionFeedback {
        items {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        nextToken
      }
      createdOn
      updatedOn
    }
  }
`;
export const listSubmissions = /* GraphQL */ `
  query ListSubmissions(
    $id: ID
    $filter: ModelSubmissionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubmissions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        video {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        videoLength
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        personId
        clinicians {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        totalPrice
        status
        submissionFeedback {
          nextToken
        }
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getSubmissionFeedback = /* GraphQL */ `
  query GetSubmissionFeedback($id: ID!) {
    getSubmissionFeedback(id: $id) {
      id
      type
      submission {
        id
        title
        description
        video {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        videoLength
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        personId
        clinicians {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        totalPrice
        status
        submissionFeedback {
          nextToken
        }
        createdOn
        updatedOn
      }
      submissionId
      feedback {
        id
        clinicianId
        personId
        technical
        style
        turnout
        general
        score
        status
        submissionFeedback {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        createdOn
        updatedOn
        feedbackSubmissionFeedbackId
      }
      feedbackId
      personId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      clinician {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      clinicianId
      submissionStatus
      feedbackStatus
      clinicianAmount
      declineReason
      permissionToPost
      dateSubmitted
      dateCompleted
      createdOn
      updatedOn
      submissionFeedbackClinicianId
    }
  }
`;
export const listSubmissionFeedbacks = /* GraphQL */ `
  query ListSubmissionFeedbacks(
    $id: ID
    $filter: ModelSubmissionFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubmissionFeedbacks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        submission {
          id
          title
          description
          videoLength
          riderId
          personId
          totalPrice
          status
          createdOn
          updatedOn
        }
        submissionId
        feedback {
          id
          clinicianId
          personId
          technical
          style
          turnout
          general
          score
          status
          createdOn
          updatedOn
          feedbackSubmissionFeedbackId
        }
        feedbackId
        personId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        clinician {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        clinicianId
        submissionStatus
        feedbackStatus
        clinicianAmount
        declineReason
        permissionToPost
        dateSubmitted
        dateCompleted
        createdOn
        updatedOn
        submissionFeedbackClinicianId
      }
      nextToken
    }
  }
`;
export const getTaxRate = /* GraphQL */ `
  query GetTaxRate($id: ID!) {
    getTaxRate(id: $id) {
      id
      name
      createdBy
      organizationId
      stripeTaxRateId
      percentage
      createdOn
      updatedOn
    }
  }
`;
export const listTaxRates = /* GraphQL */ `
  query ListTaxRates(
    $id: ID
    $filter: ModelTaxRateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTaxRates(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      nickname
      description
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      members {
        items {
          id
          teamId
          personId
          status
          role
          permissionLevel
          creatorId
          createdOn
          updatedOn
          teamMemberCreatorId
        }
        nextToken
      }
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamContactId
      teamLocationId
      teamCreatorId
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $id: ID
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTeams(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        nickname
        description
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamContactId
        teamLocationId
        teamCreatorId
      }
      nextToken
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      id
      teamId
      team {
        id
        name
        nickname
        description
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamContactId
        teamLocationId
        teamCreatorId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      status
      role
      permissionLevel
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamMemberCreatorId
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $id: ID
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTeamMembers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        teamId
        team {
          id
          name
          nickname
          description
          creatorId
          createdOn
          updatedOn
          teamContactId
          teamLocationId
          teamCreatorId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        status
        role
        permissionLevel
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamMemberCreatorId
      }
      nextToken
    }
  }
`;
export const getText = /* GraphQL */ `
  query GetText($id: ID!) {
    getText(id: $id) {
      id
      eventId
      blockId
      block {
        id
        name
        type
        personid
        organizationId
        purchasedOn
        expiresOn
        totalCredits
        usedCredits
        status
        price
        amountPaid
        isPromotion
        isFree
        lastUpdatedBy
        createdOn
        updatedOn
      }
      alertId
      alert {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      status
      statusNote
      errorCode
      twilioSID
      twilioMessagingServiceSID
      twilioNote
      createdBy
      lastUpdatedBy
      timeSent
      timeDelivered
      createdOn
      updatedOn
    }
  }
`;
export const listTexts = /* GraphQL */ `
  query ListTexts(
    $id: ID
    $filter: ModelTextFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTexts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        blockId
        block {
          id
          name
          type
          personid
          organizationId
          purchasedOn
          expiresOn
          totalCredits
          usedCredits
          status
          price
          amountPaid
          isPromotion
          isFree
          lastUpdatedBy
          createdOn
          updatedOn
        }
        alertId
        alert {
          id
          type
          title
          body
          imageKey
          category
          organizationId
          eventId
          messageSegments
          subscriberCount
          totalCost
          blockIdsUsed
          fromUnlimitedEvent
          isIncoming
          sentFromPhoneNumber
          recipientPhoneNumbers
          successSIDList
          errorSIDList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          alertEventId
        }
        auditorId
        auditor {
          id
          personId
          eventId
          auditorId
          name
          phoneNumber
          status
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorEventId
        }
        status
        statusNote
        errorCode
        twilioSID
        twilioMessagingServiceSID
        twilioNote
        createdBy
        lastUpdatedBy
        timeSent
        timeDelivered
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getTrainer = /* GraphQL */ `
  query GetTrainer($id: ID!) {
    getTrainer(id: $id) {
      id
      name
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      trainerContactId
    }
  }
`;
export const listTrainers = /* GraphQL */ `
  query ListTrainers(
    $id: ID
    $filter: ModelTrainerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrainers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      nextToken
    }
  }
`;
export const getOrganizationMembershipTypeSeasons = /* GraphQL */ `
  query GetOrganizationMembershipTypeSeasons($id: ID!) {
    getOrganizationMembershipTypeSeasons(id: $id) {
      id
      organizationMembershipTypeID
      seasonID
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      season {
        id
        name
        description
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationMembershipTypes {
          nextToken
        }
        isActive
        startDate
        endDate
        disciplines
        createdOn
        updatedOn
        createdAt
        updatedAt
        seasonOrganizationId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizationMembershipTypeSeasons = /* GraphQL */ `
  query ListOrganizationMembershipTypeSeasons(
    $filter: ModelOrganizationMembershipTypeSeasonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationMembershipTypeSeasons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationMembershipTypeID
        seasonID
        organizationMembershipType {
          id
          name
          description
          order
          organizationId
          membershipIdValues
          nextAvailableMembershipId
          price
          category
          schedule
          isStartAtTimeOfPurchase
          setStartDate
          acceptOnlineApplications
          createdOn
          updatedOn
          organizationMembershipTypeOrganizationId
        }
        season {
          id
          name
          description
          organizationId
          isActive
          startDate
          endDate
          disciplines
          createdOn
          updatedOn
          createdAt
          updatedAt
          seasonOrganizationId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const alertsByOrganizationId = /* GraphQL */ `
  query AlertsByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      nextToken
    }
  }
`;
export const alertsByEventId = /* GraphQL */ `
  query AlertsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      nextToken
    }
  }
`;
export const alertsByEventIdByIsIncoming = /* GraphQL */ `
  query AlertsByEventIdByIsIncoming(
    $eventId: ID!
    $isIncoming: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsByEventIdByIsIncoming(
      eventId: $eventId
      isIncoming: $isIncoming
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      nextToken
    }
  }
`;
export const backlogEventResultsByEventId = /* GraphQL */ `
  query BacklogEventResultsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBacklogEventResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    backlogEventResultsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        organizationMemberId
        organizationResultId
        eventId
        eventName
        eventStartDate
        eventEndDate
        eventDivisionId
        eventDivisionName
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClassId
        eventClassName
        eventClassNumber
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        riderName
        horseName
        place
        pointsEarned
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        backlogEventResultPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const getBarnMembersByBarnByPersonId = /* GraphQL */ `
  query GetBarnMembersByBarnByPersonId(
    $barnId: ID!
    $personId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBarnMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBarnMembersByBarnByPersonId(
      barnId: $barnId
      personId: $personId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        permissionLevel
        status
        roles
        createdBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getBarnMembersByBarnId = /* GraphQL */ `
  query GetBarnMembersByBarnId(
    $barnId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBarnMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBarnMembersByBarnId(
      barnId: $barnId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        permissionLevel
        status
        roles
        createdBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getBarnMembersByPersonId = /* GraphQL */ `
  query GetBarnMembersByPersonId(
    $personId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBarnMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBarnMembersByPersonId(
      personId: $personId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        permissionLevel
        status
        roles
        createdBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const cliniciansByStatus = /* GraphQL */ `
  query CliniciansByStatus(
    $status: ClinicianStatus!
    $sortDirection: ModelSortDirection
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cliniciansByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      nextToken
    }
  }
`;
export const eventsByOrganizationIdByStartDate = /* GraphQL */ `
  query EventsByOrganizationIdByStartDate(
    $organizationId: ID!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByOrganizationIdByStartDate(
      organizationId: $organizationId
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      nextToken
    }
  }
`;
export const eventAuditorsByEvent = /* GraphQL */ `
  query EventAuditorsByEvent(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventAuditorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventAuditorsByEvent(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      nextToken
    }
  }
`;
export const eventAuditorByPhoneNumberByEvent = /* GraphQL */ `
  query EventAuditorByPhoneNumberByEvent(
    $phoneNumber: String!
    $eventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventAuditorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventAuditorByPhoneNumberByEvent(
      phoneNumber: $phoneNumber
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      nextToken
    }
  }
`;
export const organizationAuditorByPersonId = /* GraphQL */ `
  query OrganizationAuditorByPersonId(
    $personId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationAuditorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationAuditorByPersonId(
      personId: $personId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      nextToken
    }
  }
`;
export const organizationAuditorByOrganizationId = /* GraphQL */ `
  query OrganizationAuditorByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationAuditorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationAuditorByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      nextToken
    }
  }
`;
export const organizationAuditorByOrganizationIdByStatus = /* GraphQL */ `
  query OrganizationAuditorByOrganizationIdByStatus(
    $organizationId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationAuditorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationAuditorByOrganizationIdByStatus(
      organizationId: $organizationId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      nextToken
    }
  }
`;
export const organizationAuditorByPhoneNumber = /* GraphQL */ `
  query OrganizationAuditorByPhoneNumber(
    $phoneNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationAuditorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationAuditorByPhoneNumber(
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      nextToken
    }
  }
`;
export const eventAuditorFilterByOrganizationId = /* GraphQL */ `
  query EventAuditorFilterByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventAuditorFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventAuditorFilterByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        eventauditorid
        eventAuditorId
        eventAuditor {
          id
          personId
          eventId
          auditorId
          name
          phoneNumber
          status
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorEventId
        }
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationAuditorId
        organizationAuditor {
          id
          personId
          howSignedUp
          organizationId
          auditorId
          name
          phoneNumber
          status
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          organizationAuditorPersonId
          organizationAuditorOrganizationId
        }
        filterId
        filter {
          id
          eventId
          organizationID
          name
          options
          isRequired
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        options
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorFilterOrganizationId
      }
      nextToken
    }
  }
`;
export const eventAuditorFilterByOrganizationAuditorId = /* GraphQL */ `
  query EventAuditorFilterByOrganizationAuditorId(
    $organizationAuditorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventAuditorFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventAuditorFilterByOrganizationAuditorId(
      organizationAuditorId: $organizationAuditorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        eventauditorid
        eventAuditorId
        eventAuditor {
          id
          personId
          eventId
          auditorId
          name
          phoneNumber
          status
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorEventId
        }
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationAuditorId
        organizationAuditor {
          id
          personId
          howSignedUp
          organizationId
          auditorId
          name
          phoneNumber
          status
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          organizationAuditorPersonId
          organizationAuditorOrganizationId
        }
        filterId
        filter {
          id
          eventId
          organizationID
          name
          options
          isRequired
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        options
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorFilterOrganizationId
      }
      nextToken
    }
  }
`;
export const eventBeddingTypeByEventId = /* GraphQL */ `
  query EventBeddingTypeByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventBeddingTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBeddingTypeByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        organizationId
        organizationBeddingTypeId
        name
        description
        type
        pricePerBag
        taxPerBag
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const eventClassesByEventId = /* GraphQL */ `
  query EventClassesByEventId(
    $eventId: ID!
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassesByEventId(
      eventId: $eventId
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const eventClassEntriesByEventClassId = /* GraphQL */ `
  query EventClassEntriesByEventClassId(
    $eventClassId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventClassEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassEntriesByEventClassId(
      eventClassId: $eventClassId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const eventClassEntriesByEventClassIdByStatus = /* GraphQL */ `
  query EventClassEntriesByEventClassIdByStatus(
    $eventClassId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventClassEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassEntriesByEventClassIdByStatus(
      eventClassId: $eventClassId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const eventClassEntriesByEventEntryId = /* GraphQL */ `
  query EventClassEntriesByEventEntryId(
    $eventEntryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventClassEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassEntriesByEventEntryId(
      eventEntryId: $eventEntryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const eventClassEntriesByEventEntryIdByStatus = /* GraphQL */ `
  query EventClassEntriesByEventEntryIdByStatus(
    $eventEntryId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventClassEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassEntriesByEventEntryIdByStatus(
      eventEntryId: $eventEntryId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const eventDivisionsByEventId = /* GraphQL */ `
  query EventDivisionsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventDivisionsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const eventDivisionsByOrganizationDivisionId = /* GraphQL */ `
  query EventDivisionsByOrganizationDivisionId(
    $organizationDivisionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventDivisionsByOrganizationDivisionId(
      organizationDivisionId: $organizationDivisionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const eventEntriesByEventId = /* GraphQL */ `
  query EventEntriesByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventEntriesByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      nextToken
    }
  }
`;
export const eventEntriesByEventIdByStatus = /* GraphQL */ `
  query EventEntriesByEventIdByStatus(
    $eventId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventEntriesByEventIdByStatus(
      eventId: $eventId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      nextToken
    }
  }
`;
export const eventEntriesByEventIdByPersonId = /* GraphQL */ `
  query EventEntriesByEventIdByPersonId(
    $eventId: ID!
    $personId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventEntriesByEventIdByPersonId(
      eventId: $eventId
      personId: $personId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      nextToken
    }
  }
`;
export const eventEntriesByEventIdByTrainerName = /* GraphQL */ `
  query EventEntriesByEventIdByTrainerName(
    $eventId: ID!
    $trainerName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventEntriesByEventIdByTrainerName(
      eventId: $eventId
      trainerName: $trainerName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      nextToken
    }
  }
`;
export const eventEntriesByEventIdByNumber = /* GraphQL */ `
  query EventEntriesByEventIdByNumber(
    $eventId: ID!
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventEntriesByEventIdByNumber(
      eventId: $eventId
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      nextToken
    }
  }
`;
export const eventEntriesByPersonId = /* GraphQL */ `
  query EventEntriesByPersonId(
    $personId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEventEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventEntriesByPersonId(
      personId: $personId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      nextToken
    }
  }
`;
export const eventEntriesByPersonIdByStatus = /* GraphQL */ `
  query EventEntriesByPersonIdByStatus(
    $personId: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventEntriesByPersonIdByStatus(
      personId: $personId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      nextToken
    }
  }
`;
export const eventEntryFeesByEventEntryId = /* GraphQL */ `
  query EventEntryFeesByEventEntryId(
    $entryId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventEntryFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventEntryFeesByEventEntryId(
      entryId: $entryId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        amount
        quantity
        taxA
        taxB
        eventId
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        eventFeeId
        eventFee {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const eventFeesByEventClassId = /* GraphQL */ `
  query EventFeesByEventClassId(
    $eventClassId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventFeesByEventClassId(
      eventClassId: $eventClassId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const eventInvoicesByEventEntryId = /* GraphQL */ `
  query EventInvoicesByEventEntryId(
    $entryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventInvoicesByEventEntryId(
      entryId: $entryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        status
        statusNote
        number
        stripeInvoiceNumber
        entryNumber
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        payerType
        payerStripeId
        payerContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipientStripeId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        divisions {
          nextToken
        }
        classes {
          nextToken
        }
        fees {
          nextToken
        }
        entryFeeTotalAmount
        otherFeeTotalAmount
        stablingAmount
        beddingAmount
        feedAmount
        results {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        prizeMoneyAmount
        amountToDeductFromInvoice
        amountToPayRecipientViaCheck
        totalDue
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoicePayerContactId
        eventInvoicePrizeMoneyRecipientId
        eventInvoicePrizeMoneyRecipientContactId
      }
      nextToken
    }
  }
`;
export const eventResultsByEventId = /* GraphQL */ `
  query EventResultsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventResultsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const eventResultsByEventIdByEventClass = /* GraphQL */ `
  query EventResultsByEventIdByEventClass(
    $eventId: ID!
    $eventClassId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventResultsByEventIdByEventClass(
      eventId: $eventId
      eventClassId: $eventClassId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const eventClassResultsByEntryId = /* GraphQL */ `
  query EventClassResultsByEntryId(
    $entryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassResultsByEntryId(
      entryId: $entryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const eventClassResultsByEventClassEntryId = /* GraphQL */ `
  query EventClassResultsByEventClassEntryId(
    $eventClassEntryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassResultsByEventClassEntryId(
      eventClassEntryId: $eventClassEntryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const eventDivisionResultsByEventDivisionId = /* GraphQL */ `
  query EventDivisionResultsByEventDivisionId(
    $eventDivisionId: ID!
    $place: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventDivisionResultsByEventDivisionId(
      eventDivisionId: $eventDivisionId
      place: $place
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const eventClassResultsByEventClassId = /* GraphQL */ `
  query EventClassResultsByEventClassId(
    $eventClassId: ID!
    $place: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassResultsByEventClassId(
      eventClassId: $eventClassId
      place: $place
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const eventRulesbyEventId = /* GraphQL */ `
  query EventRulesbyEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventRulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventRulesbyEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        title
        text
        requiresAgreementPerEntry
        agreements {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const eventStallTypeByEventId = /* GraphQL */ `
  query EventStallTypeByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventStallTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventStallTypeByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        organizationId
        organizationStallTypeId
        name
        description
        type
        pricePerStall
        taxPerStall
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const gameInputsByEventId = /* GraphQL */ `
  query GameInputsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGameInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameInputsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventClassId
        backNumber
        round
        score
        isMasterRecord
        createdBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const byBarnByInvitor = /* GraphQL */ `
  query ByBarnByInvitor(
    $barnId: ID!
    $createdBy: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByBarnByInvitor(
      barnId: $barnId
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdBy
        creatorEmail
        invitee
        inviteeEmail
        status
        organizationId
        barnId
        permissionLevel
        roles
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const byBarnByInvitee = /* GraphQL */ `
  query ByBarnByInvitee(
    $barnId: ID!
    $invitee: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByBarnByInvitee(
      barnId: $barnId
      invitee: $invitee
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdBy
        creatorEmail
        invitee
        inviteeEmail
        status
        organizationId
        barnId
        permissionLevel
        roles
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const membershipsByRiderId = /* GraphQL */ `
  query MembershipsByRiderId(
    $riderId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membershipsByRiderId(
      riderId: $riderId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        membershipId
        backNumber
        membershipStatus
        amountPaid
        organizationMembershipTypeId
        organizationMembershipType {
          id
          name
          description
          order
          organizationId
          membershipIdValues
          nextAvailableMembershipId
          price
          category
          schedule
          isStartAtTimeOfPurchase
          setStartDate
          acceptOnlineApplications
          createdOn
          updatedOn
          organizationMembershipTypeOrganizationId
        }
        type
        personName
        personId
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        teamId
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationName
        isJunior
        isProfessional
        dateMembershipEnds
        volunteerHours
        meetingsAttended
        showsAttended
        personEmail
        gradeLevel
        aelDivision
        aelTeamRole
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        organizationAgreements {
          organizationRuleId
          organizationRuleTitle
          firstName
          lastName
          personId
          createdOn
          updatedOn
        }
        contactPersonId
        groupContactId
        groupContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        groupLeaderContactId
        groupLeaderContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        createdBy
        createdOn
        updatedOn
        personMembershipsId
        membershipOrganizationId
      }
      nextToken
    }
  }
`;
export const membershipsByHorseId = /* GraphQL */ `
  query MembershipsByHorseId(
    $horseId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membershipsByHorseId(
      horseId: $horseId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        membershipId
        backNumber
        membershipStatus
        amountPaid
        organizationMembershipTypeId
        organizationMembershipType {
          id
          name
          description
          order
          organizationId
          membershipIdValues
          nextAvailableMembershipId
          price
          category
          schedule
          isStartAtTimeOfPurchase
          setStartDate
          acceptOnlineApplications
          createdOn
          updatedOn
          organizationMembershipTypeOrganizationId
        }
        type
        personName
        personId
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        teamId
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationName
        isJunior
        isProfessional
        dateMembershipEnds
        volunteerHours
        meetingsAttended
        showsAttended
        personEmail
        gradeLevel
        aelDivision
        aelTeamRole
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        organizationAgreements {
          organizationRuleId
          organizationRuleTitle
          firstName
          lastName
          personId
          createdOn
          updatedOn
        }
        contactPersonId
        groupContactId
        groupContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        groupLeaderContactId
        groupLeaderContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        createdBy
        createdOn
        updatedOn
        personMembershipsId
        membershipOrganizationId
      }
      nextToken
    }
  }
`;
export const membershipsByOwnerId = /* GraphQL */ `
  query MembershipsByOwnerId(
    $ownerId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membershipsByOwnerId(
      ownerId: $ownerId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        membershipId
        backNumber
        membershipStatus
        amountPaid
        organizationMembershipTypeId
        organizationMembershipType {
          id
          name
          description
          order
          organizationId
          membershipIdValues
          nextAvailableMembershipId
          price
          category
          schedule
          isStartAtTimeOfPurchase
          setStartDate
          acceptOnlineApplications
          createdOn
          updatedOn
          organizationMembershipTypeOrganizationId
        }
        type
        personName
        personId
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        teamId
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationName
        isJunior
        isProfessional
        dateMembershipEnds
        volunteerHours
        meetingsAttended
        showsAttended
        personEmail
        gradeLevel
        aelDivision
        aelTeamRole
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        organizationAgreements {
          organizationRuleId
          organizationRuleTitle
          firstName
          lastName
          personId
          createdOn
          updatedOn
        }
        contactPersonId
        groupContactId
        groupContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        groupLeaderContactId
        groupLeaderContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        createdBy
        createdOn
        updatedOn
        personMembershipsId
        membershipOrganizationId
      }
      nextToken
    }
  }
`;
export const membershipsByTrainerId = /* GraphQL */ `
  query MembershipsByTrainerId(
    $trainerId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membershipsByTrainerId(
      trainerId: $trainerId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        membershipId
        backNumber
        membershipStatus
        amountPaid
        organizationMembershipTypeId
        organizationMembershipType {
          id
          name
          description
          order
          organizationId
          membershipIdValues
          nextAvailableMembershipId
          price
          category
          schedule
          isStartAtTimeOfPurchase
          setStartDate
          acceptOnlineApplications
          createdOn
          updatedOn
          organizationMembershipTypeOrganizationId
        }
        type
        personName
        personId
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        teamId
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationName
        isJunior
        isProfessional
        dateMembershipEnds
        volunteerHours
        meetingsAttended
        showsAttended
        personEmail
        gradeLevel
        aelDivision
        aelTeamRole
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        organizationAgreements {
          organizationRuleId
          organizationRuleTitle
          firstName
          lastName
          personId
          createdOn
          updatedOn
        }
        contactPersonId
        groupContactId
        groupContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        groupLeaderContactId
        groupLeaderContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        createdBy
        createdOn
        updatedOn
        personMembershipsId
        membershipOrganizationId
      }
      nextToken
    }
  }
`;
export const membershipsByOrganizationId = /* GraphQL */ `
  query MembershipsByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membershipsByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        membershipId
        backNumber
        membershipStatus
        amountPaid
        organizationMembershipTypeId
        organizationMembershipType {
          id
          name
          description
          order
          organizationId
          membershipIdValues
          nextAvailableMembershipId
          price
          category
          schedule
          isStartAtTimeOfPurchase
          setStartDate
          acceptOnlineApplications
          createdOn
          updatedOn
          organizationMembershipTypeOrganizationId
        }
        type
        personName
        personId
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        teamId
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationName
        isJunior
        isProfessional
        dateMembershipEnds
        volunteerHours
        meetingsAttended
        showsAttended
        personEmail
        gradeLevel
        aelDivision
        aelTeamRole
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        organizationAgreements {
          organizationRuleId
          organizationRuleTitle
          firstName
          lastName
          personId
          createdOn
          updatedOn
        }
        contactPersonId
        groupContactId
        groupContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        groupLeaderContactId
        groupLeaderContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        createdBy
        createdOn
        updatedOn
        personMembershipsId
        membershipOrganizationId
      }
      nextToken
    }
  }
`;
export const getMembershipTypesByOrganizationIDByCreatedOn = /* GraphQL */ `
  query GetMembershipTypesByOrganizationIDByCreatedOn(
    $organizationId: ID!
    $createdOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationMembershipTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMembershipTypesByOrganizationIDByCreatedOn(
      organizationId: $organizationId
      createdOn: $createdOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      nextToken
    }
  }
`;
export const getMembershipTypesByOrganizationIDByAcceptOnlineApplications = /* GraphQL */ `
  query GetMembershipTypesByOrganizationIDByAcceptOnlineApplications(
    $organizationId: ID!
    $acceptOnlineApplications: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationMembershipTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMembershipTypesByOrganizationIDByAcceptOnlineApplications(
      organizationId: $organizationId
      acceptOnlineApplications: $acceptOnlineApplications
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      nextToken
    }
  }
`;
export const getOrganizationsByURLBackHalf = /* GraphQL */ `
  query GetOrganizationsByURLBackHalf(
    $urlBackHalf: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrganizationsByURLBackHalf(
      urlBackHalf: $urlBackHalf
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const organizationClassesByOrganizationId = /* GraphQL */ `
  query OrganizationClassesByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationClassesByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const organizationDivisionsByOrganizationId = /* GraphQL */ `
  query OrganizationDivisionsByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationDivisionsByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      nextToken
    }
  }
`;
export const getPostsByOrganizationIDByCreatedOn = /* GraphQL */ `
  query GetPostsByOrganizationIDByCreatedOn(
    $organizationId: ID!
    $createdOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByOrganizationIDByCreatedOn(
      organizationId: $organizationId
      createdOn: $createdOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        rating
        content
        organizationId
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const scheduleItemsByEventId = /* GraphQL */ `
  query ScheduleItemsByEventId(
    $eventId: ID!
    $displayOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scheduleItemsByEventId(
      eventId: $eventId
      displayOrder: $displayOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventRingId
        eventDayId
        eventRing {
          id
          name
          eventId
          ringId
          createdOn
          updatedOn
        }
        eventDay {
          id
          eventId
          awsdate
          dayofweek
          date
          month
          year
          createdOn
          updatedOn
        }
        displayOrder
        classId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        breakId
        eventBreak {
          id
          eventId
          name
          time
          isDisplayedOnSchedule
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        startTime
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getSeasonsByOrganizationId = /* GraphQL */ `
  query GetSeasonsByOrganizationId(
    $organizationId: ID!
    $createdOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSeasonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSeasonsByOrganizationId(
      organizationId: $organizationId
      createdOn: $createdOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationMembershipTypes {
          nextToken
        }
        isActive
        startDate
        endDate
        disciplines
        createdOn
        updatedOn
        createdAt
        updatedAt
        seasonOrganizationId
      }
      nextToken
    }
  }
`;
export const stablingRequestsByEventId = /* GraphQL */ `
  query StablingRequestsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStablingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stablingRequestsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        departureDateTime
        stallTypeId
        stallType {
          id
          eventId
          organizationId
          organizationStallTypeId
          name
          description
          type
          pricePerStall
          taxPerStall
          createdOn
          updatedOn
        }
        quantityNeeded
        basePrice
        taxA
        taxB
        totalPrice
        status
        statusNote
        requestNote
        stallSetId
        stallSet {
          id
          eventId
          organizationId
          organizationStallSetId
          name
          location
          available
          totalAvailable
          used
          totalUsed
          createdOn
          updatedOn
        }
        stallLocation
        stallNumbers
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const stablingRequestsByEntryId = /* GraphQL */ `
  query StablingRequestsByEntryId(
    $submittedEntry: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStablingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stablingRequestsByEntryId(
      submittedEntry: $submittedEntry
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        departureDateTime
        stallTypeId
        stallType {
          id
          eventId
          organizationId
          organizationStallTypeId
          name
          description
          type
          pricePerStall
          taxPerStall
          createdOn
          updatedOn
        }
        quantityNeeded
        basePrice
        taxA
        taxB
        totalPrice
        status
        statusNote
        requestNote
        stallSetId
        stallSet {
          id
          eventId
          organizationId
          organizationStallSetId
          name
          location
          available
          totalAvailable
          used
          totalUsed
          createdOn
          updatedOn
        }
        stallLocation
        stallNumbers
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const beddingRequestsByEventId = /* GraphQL */ `
  query BeddingRequestsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBeddingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    beddingRequestsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        dateTimeNeeded
        deliveryInfo
        beddingType
        quantityNeeded
        basePrice
        taxA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        totalPrice
        status
        statusNote
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
        beddingRequestTaxAId
        beddingRequestTaxBId
      }
      nextToken
    }
  }
`;
export const beddingRequestsByEntryId = /* GraphQL */ `
  query BeddingRequestsByEntryId(
    $submittedEntry: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBeddingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    beddingRequestsByEntryId(
      submittedEntry: $submittedEntry
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        dateTimeNeeded
        deliveryInfo
        beddingType
        quantityNeeded
        basePrice
        taxA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        totalPrice
        status
        statusNote
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
        beddingRequestTaxAId
        beddingRequestTaxBId
      }
      nextToken
    }
  }
`;
export const submissionFeedbackByClinicianByStatus = /* GraphQL */ `
  query SubmissionFeedbackByClinicianByStatus(
    $clinicianId: ID!
    $feedbackStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubmissionFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    submissionFeedbackByClinicianByStatus(
      clinicianId: $clinicianId
      feedbackStatus: $feedbackStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        submission {
          id
          title
          description
          videoLength
          riderId
          personId
          totalPrice
          status
          createdOn
          updatedOn
        }
        submissionId
        feedback {
          id
          clinicianId
          personId
          technical
          style
          turnout
          general
          score
          status
          createdOn
          updatedOn
          feedbackSubmissionFeedbackId
        }
        feedbackId
        personId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        clinician {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        clinicianId
        submissionStatus
        feedbackStatus
        clinicianAmount
        declineReason
        permissionToPost
        dateSubmitted
        dateCompleted
        createdOn
        updatedOn
        submissionFeedbackClinicianId
      }
      nextToken
    }
  }
`;
export const paymentsByClinicianByDate = /* GraphQL */ `
  query PaymentsByClinicianByDate(
    $feedbackStatus: FeedbackStatus!
    $clinicianIdDateCompleted: ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubmissionFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentsByClinicianByDate(
      feedbackStatus: $feedbackStatus
      clinicianIdDateCompleted: $clinicianIdDateCompleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        submission {
          id
          title
          description
          videoLength
          riderId
          personId
          totalPrice
          status
          createdOn
          updatedOn
        }
        submissionId
        feedback {
          id
          clinicianId
          personId
          technical
          style
          turnout
          general
          score
          status
          createdOn
          updatedOn
          feedbackSubmissionFeedbackId
        }
        feedbackId
        personId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        clinician {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        clinicianId
        submissionStatus
        feedbackStatus
        clinicianAmount
        declineReason
        permissionToPost
        dateSubmitted
        dateCompleted
        createdOn
        updatedOn
        submissionFeedbackClinicianId
      }
      nextToken
    }
  }
`;
export const getTeamMembersByTeamId = /* GraphQL */ `
  query GetTeamMembersByTeamId(
    $teamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamMembersByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamId
        team {
          id
          name
          nickname
          description
          creatorId
          createdOn
          updatedOn
          teamContactId
          teamLocationId
          teamCreatorId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        status
        role
        permissionLevel
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamMemberCreatorId
      }
      nextToken
    }
  }
`;
export const getTeamMembersByPersonId = /* GraphQL */ `
  query GetTeamMembersByPersonId(
    $personId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamMembersByPersonId(
      personId: $personId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamId
        team {
          id
          name
          nickname
          description
          creatorId
          createdOn
          updatedOn
          teamContactId
          teamLocationId
          teamCreatorId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        status
        role
        permissionLevel
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamMemberCreatorId
      }
      nextToken
    }
  }
`;
export const textsByEventId = /* GraphQL */ `
  query TextsByEventId(
    $eventId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTextFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        blockId
        block {
          id
          name
          type
          personid
          organizationId
          purchasedOn
          expiresOn
          totalCredits
          usedCredits
          status
          price
          amountPaid
          isPromotion
          isFree
          lastUpdatedBy
          createdOn
          updatedOn
        }
        alertId
        alert {
          id
          type
          title
          body
          imageKey
          category
          organizationId
          eventId
          messageSegments
          subscriberCount
          totalCost
          blockIdsUsed
          fromUnlimitedEvent
          isIncoming
          sentFromPhoneNumber
          recipientPhoneNumbers
          successSIDList
          errorSIDList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          alertEventId
        }
        auditorId
        auditor {
          id
          personId
          eventId
          auditorId
          name
          phoneNumber
          status
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorEventId
        }
        status
        statusNote
        errorCode
        twilioSID
        twilioMessagingServiceSID
        twilioNote
        createdBy
        lastUpdatedBy
        timeSent
        timeDelivered
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
