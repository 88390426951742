import { 
    IonButtons, 
    IonCol, 
    IonHeader, 
    IonRow, 
    IonTitle, 
    IonToolbar 
} from "@ionic/react";
import CreateEventButton from "../Event/CreateEventButton";
import React from "react";
import SearchIcon from "../Search/SearchIcon";
import { Organization } from "../../models";

interface _Props {
    title?: string
    organization?: Organization
}

const SearchAndAddHeader: React.FC<_Props> = ({title, organization}) => {
    const handleSearchInput = async () => {

    }

    return (
        <React.Fragment>
            <IonHeader id="mobile-auth-search-and-add-header">
                <IonToolbar id="mobile-auth-search-and-add-toolbar" color="light">
                    {title && <IonTitle color="primary" >{title}</IonTitle>}
                    <IonButtons slot="end" color="light">
                        <IonRow>
                            <IonCol size="6">
                                <SearchIcon onSearch={handleSearchInput} />
                            </IonCol>
                            <IonCol size="6">
                                <CreateEventButton organization={organization} />
                            </IonCol>
                        </IonRow>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
        </React.Fragment>
    );
};

export default SearchAndAddHeader;
