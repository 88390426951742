import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonLabel,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useState} from "react";

const PrizeMoneyTableExplanationModal: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <IonLabel>
            <IonButton 
                id="prizeMoneyTableExplanationBtn"
                onClick={() => setShowModal(true)}
                fill="clear"
                className="ion-no-padding"
            >
                <IonText className="font-weight-normal text-dark text-capitalize initialism ion-text-wrap ion-text-start">
                    Learn More
                </IonText>
            </IonButton>
            <IonModal backdropDismiss={false} isOpen={showModal} id="prizeMoneyTableExplanationModal">
                <IonHeader>
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Prize Money Tables
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closePrizeMoneyTableExplanationModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    X
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonText>To save you time, we allow you to reuse any Prize Money Tables that you create. When you add a division or class, you can select the prize money table that should be used for it.</IonText>
                    <hr />
                    <IonText>Prize money tables can use fixed amounts or percentages.</IonText>
                    <hr />
                    <IonText>Make sure you give each table a descriptive name so that you can quicly find it in the future.</IonText>
                </IonContent>
                <IonButton id="ClosePrizeMoneyTableExplanationModalBtn" onClick={() => handleClose()}>
                    Close
                </IonButton>
            </IonModal>
        </IonLabel>
    );
};

export default PrizeMoneyTableExplanationModal;
