import {
    IonButton,
    IonCol,
    IonLabel,
    IonRow, 
    IonSegment, 
    IonSegmentButton,
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import { EventClass, EventClassEntry, EventResult, PointTable, PrizeMoneyTable } from "../../models";
import { isWindows } from "../../utilities/platform/Platform";
import { isPlatform } from "@ionic/core";
import { getEventClassEntriesByEventClassId } from "../../utilities/eventClassEntry/EventClassEntry";
import { Input, Table } from "reactstrap";
import { EventResultRow } from "../../interfaces/EventResult";
import { createEventResult, deleteEventResult, getEventResultsByEventClassId } from "../../utilities/eventResult/EventResult";
import { getPrizeMoneyAmountFromTable } from "../../utilities/prizeMoneyTable/PrizeMoney";
import BasicTooltip from "../Tooltip/BasicTooltip";
import { CreateEventResultInput } from "../../API";
import moment from "moment";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { getPointTableById } from "../../utilities/pointTable/PointTable";
import EditEventClassResultsModal from "../Modals/EditEventClassResultsModal";
import SuccessBanner from "../Banners/SuccessBanner";
import ErrorBanner from "../Banners/ErrorBanner";

interface _Props {
    eventClass: EventClass
    pointTable?: (PointTable | null)
    prizeMoneyTable?: (PrizeMoneyTable | null)
    prizeMoney?: (number | null)
    isEditable?: Boolean
}

const EventResultTable: React.FC<_Props> = ({eventClass, pointTable, prizeMoneyTable, prizeMoney, isEditable}) => {
    const user = useContext(PersonContext);

    const [eventClassEntries, setEventClassEntries] = useState<EventClassEntry[] | undefined>();
    const [eventClassResults, setEventClassResults] = useState<EventResult[] | undefined>();
    const [resultsSet1, setResultsSet1] = useState<EventResultRow[] | undefined>();
    const [resultsSet2, setResultsSet2] = useState<EventResultRow[] | undefined>();
    const [resultsSet3, setResultsSet3] = useState<EventResultRow[] | undefined>();
    const [resultsSet4, setResultsSet4] = useState<EventResultRow[] | undefined>();
    const [currentResultSet, setCurrentResultSet] = useState("1");

    const [formattedRows, setFormattedRows] = useState<EventResultRow[] | undefined>();

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showResultsModal, setShowResultsModal] = useState(false);

    async function getEventClassEntries(eventClassId: string) {
        const queryResult = await getEventClassEntriesByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            const eventClassEntries = queryResult.result;
            setEventClassEntries(eventClassEntries);
            return eventClassEntries;
        } else {
            setError("Sorry, a problem occurred. No event class entries found. Please go back and try again.");
        }
    }

    async function getEventClassResults(eventClassId: string) {
        const queryResult = await getEventResultsByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            const eventResults: EventResult[] = queryResult.result;
            setEventClassResults(eventResults);
            return eventResults;
        } else {
            setError("Sorry, a problem occurred. No class results found. Please go back and try again.");
        }
    }

    const getData = async (eventClassId: string) => {
        setIsLoading(true);
        const eventClassEntries: EventClassEntry[] = await getEventClassEntries(eventClassId);
        const eventResults: EventResult[] | undefined = await getEventClassResults(eventClassId);
        await formatRows(eventClassEntries, eventResults);
        setIsLoading(false);
    }

    useEffect(() => {
        if (eventClass) {
            getData(eventClass.id);
        }
    }, [eventClass]);

    const handleSetClick = async (value: string) => {
        setError("");
        setSuccess("");
        setCurrentResultSet(value);
        if (value === "1") await setFormattedRows(() => resultsSet1);
        else if (value === "2") await setFormattedRows(() => resultsSet2);
        else if (value === "3") await setFormattedRows(() => resultsSet3);
        else if (value === "4") await setFormattedRows(() => resultsSet4);
    }

    const calculatePoints = (placeValue: number, selectedPointTable?: PointTable) => {
        const currentPointTable = selectedPointTable || eventClass.pointTable || pointTable;
        if (currentPointTable) {
            let pointValue = "0";
            if (currentPointTable && currentPointTable.tiers && currentPointTable.tiers[0]) {
                const tier = currentPointTable.tiers[0];
                if (placeValue === 1) pointValue = tier["first"] || "0";
                if (placeValue === 2) pointValue = tier["second"] || "0";
                if (placeValue === 3) pointValue = tier["third"] || "0";
                if (placeValue === 4) pointValue = tier["fourth"] || "0";
                if (placeValue === 5) pointValue = tier["fifth"] || "0";
                if (placeValue === 6) pointValue = tier["sixth"] || "0";
                if (placeValue === 7) pointValue = tier["seventh"] || "0";
                if (placeValue === 8) pointValue = tier["eighth"] || "0";
                if (placeValue === 9) pointValue = tier["ninth"] || "0";
                if (placeValue === 10) pointValue = tier["tenth"] || "0";
                if (placeValue === 11) pointValue = tier["eleventh"] || "0";
                if (placeValue === 12) pointValue = tier["twelfth"] || "0";
            }
            return parseFloat(pointValue);
        }
    }

    const calculatePrizeMoney = async (placeValue: number) => {
        if (prizeMoneyTable) {
            const prizeMoneyAmount = await getPrizeMoneyAmountFromTable(prizeMoneyTable, placeValue);
            return prizeMoneyAmount;
        }
    }

    const formatRows = async (eventEntries: EventClassEntry[], eventResults?: EventResult[]) => {
        let formattedRowsSet1 = [];
        let formattedRowsSet2 = [];
        let formattedRowsSet3 = [];
        let formattedRowsSet4 = [];
        for (let i = 0; i < eventEntries.length; i++) {
            const currentEventClassEntry: EventClassEntry = eventEntries[i];
            
            const currentResults = eventResults?.filter(element => (element.eventClassEntryId || "") === currentEventClassEntry.id);

            for (let j = 1; j <= 4; j++) {
                let currentResult = currentResults?.find(result => result.resultSet === j.toString());
                // Check if there is a current results but there was never a set associated with the result
                if (!currentResult && j === 1 && currentResults && currentResults[0]) {
                    currentResult = currentResults[0];
                }
                
                // Use the point table assigned to the specific class
                let eventClassPointTable = eventClass.pointTable;
                // Check if there are tiers on the point table from the query. If not, get the full point table with tiers.
                if (eventClassPointTable && (!eventClassPointTable.tiers || eventClassPointTable.tiers.length < 1)) {
                    const queryPointTableResult = await getPointTableById(eventClassPointTable?.id);
                    if (queryPointTableResult.isSuccess) {
                        eventClassPointTable = queryPointTableResult.result;
                    }
                }
                
                let prizeMoneyWon = currentResult?.place ? await calculatePrizeMoney(currentResult.place) : "";
                
                let formatted: EventResultRow = {
                    eventClassId: eventClass.id,
                    place: currentResult?.place,
                    entryId: currentEventClassEntry.eventEntry?.id || "",
                    eventClassEntryId: currentEventClassEntry.id,
                    entryNumber: currentEventClassEntry.eventEntry?.number || 0,
                    riderName: currentEventClassEntry.rider?.name || "",
                    horseName: currentEventClassEntry.eventEntry?.horseName || "",
                    time: currentResult?.time,
                    score: currentResult?.score,
                    points: currentResult?.place ? calculatePoints(currentResult.place, eventClassPointTable || undefined) : 0,
                    prizeMoney: prizeMoneyWon,
                    didNotCompete: currentResult?.didNotCompete ? true : false
                };

                if (j === 4) formattedRowsSet4.push(formatted);
                else if (j === 3) formattedRowsSet3.push(formatted);
                else if (j == 2) formattedRowsSet2.push(formatted);
                else formattedRowsSet1.push(formatted);
            }
        }

        // First sort by entry number to sort the entries that did not place
        const sortedByEntryNumber1 = formattedRowsSet1.sort((a, b) => a.entryNumber - b.entryNumber);
        const sortedByEntryNumber2 = formattedRowsSet2.sort((a, b) => a.entryNumber - b.entryNumber);
        const sortedByEntryNumber3 = formattedRowsSet3.sort((a, b) => a.entryNumber - b.entryNumber);
        const sortedByEntryNumber4 = formattedRowsSet4.sort((a, b) => a.entryNumber - b.entryNumber);

        // The sort by place to go from 1 to lower placings
        const sortedByPlace1 = sortedByEntryNumber1.sort((a, b) => (a.place || 9999) - (b.place || 9999));
        const sortedByPlace2 = sortedByEntryNumber2.sort((a, b) => (a.place || 9999) - (b.place || 9999));
        const sortedByPlace3 = sortedByEntryNumber3.sort((a, b) => (a.place || 9999) - (b.place || 9999));
        const sortedByPlace4 = sortedByEntryNumber4.sort((a, b) => (a.place || 9999) - (b.place || 9999));
        
        setResultsSet1(sortedByPlace1);
        setResultsSet2(sortedByPlace2);
        setResultsSet3(sortedByPlace3);
        setResultsSet4(sortedByPlace4);

        if (currentResultSet === "1") setFormattedRows(sortedByPlace1);
        else if (currentResultSet === "2") setFormattedRows(sortedByPlace2);
        else if (currentResultSet === "3") setFormattedRows(sortedByPlace3);
        else if (currentResultSet === "4") setFormattedRows(sortedByPlace4);
    }

    const handleRowChange = (index: number, place?: number, time?: string, score?: number, dnc?: boolean) => {
        setError("");
        if (formattedRows) {
            const currentRow = formattedRows[index];
            const updatedRow: EventResultRow = {
                ...currentRow,
                place: (place === 0 ? 0 : place === undefined ? currentRow.place : place),
                time: time || currentRow.time,
                score: score || currentRow.score,
                didNotCompete: dnc || false
            };
            const updatedRows = [
                ...formattedRows.slice(0, index),
                updatedRow,
                ...formattedRows.slice(index + 1)
            ];
            setFormattedRows(updatedRows);
            if (currentResultSet === "1" && resultsSet1) {
                const updatedRows = [
                    ...resultsSet1.slice(0, index),
                    updatedRow,
                    ...resultsSet1.slice(index + 1)
                ];
                setResultsSet1(updatedRows);
            } else if (currentResultSet === "2" && resultsSet2) {
                const updatedRows = [
                    ...resultsSet2.slice(0, index),
                    updatedRow,
                    ...resultsSet2.slice(index + 1)
                ];
                setResultsSet2(updatedRows);
            } else if (currentResultSet === "3" && resultsSet3) {
                const updatedRows = [
                    ...resultsSet3.slice(0, index),
                    updatedRow,
                    ...resultsSet3.slice(index + 1)
                ];
                setResultsSet3(updatedRows);
            } else if (currentResultSet === "4" && resultsSet4) {
                const updatedRows = [
                    ...resultsSet4.slice(0, index),
                    updatedRow,
                    ...resultsSet4.slice(index + 1)
                ];
                setResultsSet4(updatedRows);
            }
        } 
    }

    const handleSaveResultsSet = async (resultSet: EventResultRow[], resultSetNumber: string) => {
        let newEventResults: EventResult[] = [];
        for (let i = 0; i < resultSet.length; i++) {
            const currentRow = resultSet[i];
            let input: CreateEventResultInput = {
                eventId: eventClass.eventId,
                entryId: currentRow.entryId,
                eventResultEntryId: currentRow.entryId,
                eventClassEntryId: currentRow.eventClassEntryId,
                eventResultEventClassEntryId: currentRow.eventClassEntryId,
                eventClassId: eventClass.id,
                eventClassName: eventClass.name,
                resultSet: resultSetNumber,
                place: currentRow.place || 0,
                time: currentRow.time || undefined,
                score: currentRow.score || undefined,
                prizeMoney: currentRow.prizeMoney ? parseFloat(currentRow.prizeMoney?.replace("$", "")) : 0,
                eventResultPrizeMoneyTableId: prizeMoneyTable?.id || "",
                didNotCompete: currentRow.didNotCompete || false,
                createdBy: user.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const createResult = await createEventResult(input);
            if (createResult.isSuccess) {
                const eventResult = createResult.result;
                newEventResults.push(eventResult);
            } else {
                setError("An error occurred while saving the results. Please try refreshing the screen and entering the results again.");
            }
        }
        return newEventResults;
    }

    const handleSave = async () => {
        setError("");
        if (eventClassEntries) {
            setIsLoading(true);

            // First, remove the current results
            if (eventClassResults) {
                for (let i = 0; i < eventClassResults.length; i++) {
                    const currentResult = eventClassResults[i];
                    const deleteResult = await deleteEventResult({id: currentResult.id});
                    if (!deleteResult.isSuccess) {
                        setError("The outdated results could not be updated. Please try again or contact hello@ringsidepro.com.");
                        return;
                    }
                }
            }

            // Create new results
            let newEventResults: EventResult[] = [];
            if (resultsSet1) {
                const newResults1 = await handleSaveResultsSet(resultsSet1, "1");
                newEventResults = newEventResults.concat(newResults1);
            }
            if (resultsSet2) {
                const newResults2 = await handleSaveResultsSet(resultsSet2, "2");
                newEventResults = newEventResults.concat(newResults2);
            }
            if (resultsSet3) {
                const newResults3 = await handleSaveResultsSet(resultsSet3, "3");
                newEventResults = newEventResults.concat(newResults3);
            }
            if (resultsSet4) {
                const newResults4 = await handleSaveResultsSet(resultsSet4, "4");
                newEventResults = newEventResults.concat(newResults4);
            }
            formatRows(eventClassEntries, newEventResults);
            if (!error || error === "") setSuccess("The results have been saved.");
            setIsLoading(false);
        } else {
            setError("No entries found.")
        }
    }

    return (
       <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {success && <SuccessBanner success={success} width="12" />}
                    {error && <ErrorBanner error={error} />}
                    <EditEventClassResultsModal eventClass={eventClass} openModal={showResultsModal} closeModal={() => setShowResultsModal(false)}/>
                    {(!isWindows() && isPlatform("mobile")) ?
                        <>
                            {/* <IonList>
                                {formattedRows && formattedRows.length}
                            </IonList> */}
                        </>
                        :
                        <>
                            <IonRow className="ion-justify-content-center">
                                <IonCol>
                                    <IonSegment color="primary" mode="ios" value={currentResultSet} scrollable={true} onIonChange={e => {handleSetClick(e.detail.value || "1");}}>
                                        <IonSegmentButton value="1">
                                            <IonLabel>Results Set 1</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="2">
                                            <IonLabel>Results Set 2</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="3">
                                            <IonLabel>Results Set 3</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="4">
                                            <IonLabel>Results Set 4</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                </IonCol>
                            </IonRow>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Place</th>
                                        <th>Entry #</th>
                                        <th>Rider Name</th>
                                        <th>Horse Name</th>
                                        <th>Time</th>
                                        <th>Score</th>
                                        <th>Points</th>
                                        <th>Prize $</th>
                                        <th>DNC <BasicTooltip label="" tip="Did Not Compete" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { (
                                        <>
                                            {(formattedRows && formattedRows.length > 0) && formattedRows.map((row, index) => (
                                                <tr key={index}>
                                                    <td className="ion-text-wrap">
                                                        {isEditable ?
                                                            <>
                                                                <Input 
                                                                    className="input-width"
                                                                    type="number"
                                                                    min={0}
                                                                    value={row.place || 0}
                                                                    onChange={e => {
                                                                        handleRowChange(index, parseInt(e.target.value));
                                                                    }}
                                                                />
                                                            </>
                                                            :
                                                            <>
                                                                {row.place}
                                                            </>
                                                        }
                                                    </td>
                                                    <td className="ion-text-wrap">
                                                        {row.entryNumber}
                                                    </td>
                                                    <td className="ion-text-wrap">
                                                        {row.riderName}
                                                    </td>
                                                    <td className="ion-text-wrap">
                                                        {row.horseName}
                                                    </td>
                                                    <td className="ion-text-wrap">
                                                        {isEditable ?
                                                            <>
                                                                <Input 
                                                                    className="input-width"
                                                                    type="text"
                                                                    value={row.time || ""}
                                                                    onChange={e => {
                                                                        handleRowChange(index, undefined, e.target.value);
                                                                    }}
                                                                />
                                                            </>
                                                            :
                                                            <>
                                                                {row.time}
                                                            </>
                                                        }
                                                    </td>
                                                    <td className="ion-text-wrap">
                                                        {isEditable ?
                                                            <>
                                                                <Input 
                                                                    className="input-width"
                                                                    type="number"
                                                                    value={(row.score) ? row.score : ""}
                                                                    onChange={e => {
                                                                        handleRowChange(index, undefined, undefined, parseInt(e.target.value));
                                                                    }}
                                                                />
                                                            </>
                                                            :
                                                            <>
                                                                {row.score}
                                                            </>
                                                        }
                                                    </td>
                                                    <td className="ion-text-wrap">
                                                        {row.points}
                                                    </td>
                                                    <td className="ion-text-wrap">
                                                        {row.prizeMoney}
                                                    </td>
                                                    <td className="ion-text-wrap ion-text-center">
                                                        <Input 
                                                            type="checkbox" 
                                                            aria-label="Checkbox for did not compete." 
                                                            value="checkbox"
                                                            checked={row.didNotCompete}
                                                            disabled={!isEditable}
                                                            onChange={e => {
                                                                handleRowChange(index, undefined, undefined, undefined, e.target.checked);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </>
                    }
                    {(isEditable && formattedRows && formattedRows.length > 0) && (
                        <IonRow className="ion-align-items-center">
                            <IonCol sizeXs="12" sizeMd="6" offsetMd="3" className="ion-text-center">
                                <IonButton color="success" expand="full" onClick={handleSave}>Save</IonButton>
                            </IonCol>
                        </IonRow>
                    )}
                </>
            }
       </>
    );
};

export default EventResultTable;
