import {
    IonCol, 
    IonContent, 
    IonHeader,  
    IonPage, 
    IonRow,
} from "@ionic/react";
import React from "react";
import { Container } from "reactstrap";
import WebNavbar from "../../components/Navbars/WebNavbar";
import Footer from "../../components/Footers/Footer";
import PrivacyPolicy from "../../data/legal/PrivacyPolicy";
import "../../data/legal/PrivacyPolicyStyles.css";

const PrivacyPolicyPage: React.FC = () => {

    return (
        <IonPage id="webAboutPage">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                <section className="py-5 bg-white overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol size="10" className="text-left text-dark">
                                {PrivacyPolicy}
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default PrivacyPolicyPage;
