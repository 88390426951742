import {
    IonCol, 
    IonContent, 
    IonHeader,  
    IonPage, 
    IonRow,
    isPlatform
} from "@ionic/react";
import React from "react";
import { Container } from "reactstrap";
import WebNavbar from "../../components/Navbars/WebNavbar";
import logo from "./../../assets/img/brand/WhiteLogoAndBlock.png";
import banner from "./../../assets/img/general/Sarah2.jpg";
import Footer from "../../components/Footers/Footer";
import { isWindows } from "../../utilities/platform/Platform";
import ContactUsForm from "../../components/ContactUsForm/ContactUsForm";

const ContactUsPage: React.FC = () => {

    return (
        <IonPage id="webAboutPage">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                {(!isWindows() && isPlatform("mobile")) ?
                    <section className="bg-white overflow-hidden">
                        <div
                            className="header pb-6 d-flex align-items-center"
                            style={{
                                minHeight: "300px",
                                backgroundImage:
                                "url(\"" + banner + "\")",
                                backgroundSize: "cover",
                                backgroundPosition: "left top"
                            }}
                        >
                            <span className="mask bg-gradient-info opacity-7" />

                            <Container>
                                <IonRow className="justify-content-center">
                                    <IonCol sizeMd="6" sizeXs="8" className="pt-5 px-5">
                                        <img alt="RingSide Pro logo" src={logo}/>
                                    </IonCol>
                                </IonRow>
                            </Container>
                        </div>
                    </section>
                    :
                    <section className="bg-white overflow-hidden">
                        <div
                            className="header pb-6 d-flex align-items-center"
                            style={{
                                minHeight: "500px",
                                backgroundImage:
                                "url(\"" + banner + "\")",
                                backgroundSize: "cover",
                                backgroundPosition: "center top"
                            }}
                        >
                            <span className="mask bg-gradient-info opacity-7" />

                            <Container>
                                <IonRow className="justify-content-center">
                                    <IonCol sizeMd="6" sizeXs="10" className="pt-5 px-5">
                                        <img alt="RingSide Pro logo" src={logo}/>
                                    </IonCol>
                                </IonRow>
                            </Container>
                        </div>
                    </section>
                }
                <section className="py-5 bg-white overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <h2 className="text-dark">
                                    Contact Us
                                </h2>
                                <p className="text-dark">
                                    Email Address: hello@ringsidepro.com
                                </p>
                                <p className="text-dark">
                                    Phone Number: +1(757)812-0385
                                </p>
                                <p className="text-dark">
                                    Physical Address: 203 Riverside Dr Newport News, VA 23606
                                </p>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                               <ContactUsForm />
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default ContactUsPage;
