import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useContext, useState, useEffect } from "react";
import BasicEventOrganizationInfo from "../../../../../components/EventOrganization/BasicEventOrganizationInfo";
import { Organization } from "../../../../../models";
import PageList from "../../../../../components/Lists/PageList";
import Header from "../../../../../components/Headers/Header";
import NewEventOrganizationPage from "./NewEventOrganizationPage";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import SelectOrganization from "../../../../../components/Organization/SelectOrganization";
import Spinner from "../../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../../context/PersonContext";
import { OrganizationContext } from "../../../../../context/OrganizationContext";
import { getOrganizationsByPersonId } from "../../../../../utilities/organization/Organization";
import { organizationListOptions } from "../../../../../interfaces/Page";
import { useHistory } from "react-router";

const EventOrganizationPage: React.FC = () => {
    const history = useHistory();
    const user = useContext(PersonContext);
    const organizationCtx = useContext(OrganizationContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [userOrganizations, setUserOrganizations] = useState<Organization[] | null>(null);

    async function getOrganizations() {
        const queryResult = await getOrganizationsByPersonId(user.id);
        if (queryResult.isSuccess) {
            setUserOrganizations(queryResult.result);
            const found = queryResult.result.find((org: Organization) => {
                return org.id === organizationCtx.id;
            });
            if (found) {
                setOrganization(found);
            } else {
                setOrganization(queryResult.result[0]); //To Do: make alphabetical or chronological?
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getOrganizations();
    }, [user, user.id]);

    useEffect(() => {
        if (!organization) getOrganizations();
    }, []);

    useEffect(() => {
        getOrganizations();
    }, [history.location]);

    const handleOrganizationSelection = (selectedOrganization: Organization) => {
        setOrganization(selectedOrganization);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
            <PageTitle title="Organizations" />
                {organization && userOrganizations ?
                    <>
                        {isPlatform("desktop") && userOrganizations?.length > 1 && (
                        <IonRow>
                            <IonCol size="4">
                                <SelectOrganization currentOrganization={organization} organizations={userOrganizations} canClear={false} onInputChange={handleOrganizationSelection} />
                            </IonCol>
                        </IonRow>
                        )}
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <BasicEventOrganizationInfo organization={organization} />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <PageList id={organization.id} includeId={true} listOptions={organizationListOptions} />
                            </IonCol>
                        </IonRow>
                    </>
                    :
                    <>
                        {isLoading ?
                            <Spinner />
                            :
                            <NewEventOrganizationPage />
                        }
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationPage;