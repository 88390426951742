import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventEntry } from "../../../models";
import { formattedOptionGroup, getFormattedEntryClassList } from "../../../utilities/eventClassEntry/FormattedEventEntryClasses";

interface _Props {
    event?: (Event | null)
    entry: EventEntry
    onCalculate?: Function
}

const ViewEntryPageClassList: React.FC<_Props> = ({event, entry, onCalculate}) => {

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [totalEntryFees, setTotalEntryFees] = useState<number | null | undefined>();
    const [tax, setTax] = useState<number | null | undefined>();
    const [totalCost, setTotalCost] = useState<number | null | undefined>();
    const [formattedClassData, setFormattedClassData] = useState<formattedOptionGroup[] | null | undefined>();

    const getFinalFeeCost = (total?: (number | null), taxPercent?: (number | null)) => {
        if (!total) total = totalEntryFees;
        if (!taxPercent) taxPercent = tax;
        if (total && taxPercent) {
            const finalCost = total + total * (taxPercent || 0)/100;
            setTotalCost(finalCost);
        }
    }

    const getFormattedClassData = async (entry: EventEntry) => {
        const dataResult = await getFormattedEntryClassList(entry, false);
        if (dataResult.isSuccess) {
            setTotalEntryFees(dataResult.result.totalEntryFees);
            if (tax) getFinalFeeCost(dataResult.result.totalEntryFees, tax);
            else if (event) getFinalFeeCost(dataResult.result.totalEntryFees, event.invoiceOptions?.classFeeTax);
            if (onCalculate) onCalculate(dataResult.result.totalEntryFees);
            
            setFormattedClassData(dataResult.result.classList);
        }
    }

    useEffect(() => {
        if (event && event.invoiceOptions && event.invoiceOptions.classFeeTax) {
            setTax(event.invoiceOptions.classFeeTax);
            if (totalEntryFees) getFinalFeeCost(totalEntryFees, event.invoiceOptions.classFeeTax);
            else if (entry) getFormattedClassData(entry);
        }
    }, [event]);

    useEffect(() => {
        if (entry) {
            setIsCollapsed(false);
            getFormattedClassData(entry);
        }
    }, [entry]);

    return (
        <>
            <IonCard className="ion-padding" color="white">
                <IonCardTitle>
                    {/* <IonRow onClick={() => setIsCollapsed(!isCollapsed)}> */}
                    <IonRow>
                        <IonCol>
                            {entry ? <IonRow><h3>Entered: ${totalCost?.toFixed(2) || totalEntryFees?.toFixed(2)}</h3></IonRow> : <h2>Loading classes and divisions...</h2> }
                        </IonCol>
                        {/* <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol> */}
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {formattedClassData && (
                            <IonList className="bg-white">
                                {formattedClassData.map((formattedOption, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <p>
                                                {formattedOption.division ? 
                                                    "Division: " + formattedOption.division.label + " - $" + formattedOption.division.entryFee
                                                    : 
                                                    "Class: " + (formattedOption.class?.number ? + formattedOption.class?.number + " - " : "") + formattedOption.class?.label + " - $" + formattedOption.class?.entryFee
                                                }
                                            </p>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                                {tax && (
                                    <IonItem>
                                        <IonLabel>
                                            <p className="text-dark">
                                                Tax: ${tax}% 
                                            </p>
                                        </IonLabel>
                                </IonItem>
                                )}
                                <IonItem>
                                        <IonLabel>
                                            <p className="text-dark font-weight-bold">
                                                Total entry fees: ${totalCost?.toFixed(2) || totalEntryFees?.toFixed(2)}
                                            </p>
                                        </IonLabel>
                                </IonItem>
                            </IonList>
                        )}
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default ViewEntryPageClassList;