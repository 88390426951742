import { Result } from "../../interfaces/Result";

var colors = {
    gray: {
        100: "#f6f9fc",
        200: "#e9ecef",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#adb5bd",
        600: "#8898aa",
        700: "#525f7f",
        800: "#32325d",
        900: "#212529"
    },
    theme: {
        default: "#172b4d",
        primary: "#425974",
        secondary: "#f4f5f7",
        info: "#73a4d3",
        success: "#2dce89",
        danger: "#f5365c",
        warning: "#fb6340",
        blue: "#5e72e4",
        lightblue: "#11cdef"
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent"
};

export function formatDiscoverMethodData(data?: any, isMobile?: Boolean): Result {
    try {
        let result: Result = {isSuccess: false, type: "Chart", result: null, message: "Could not format the discover method data."};

        if (!data) return result;

        let formattedData: any = {
            options: {
                responsive: false,
                legend: {
                    position: isMobile ? "bottom" : "right"
                },
                animation: {
                    animateScale: true,
                    animateRotate: true
                }
            }
        };

        let datasets = [
            {
                data: [
                    countDiscoverMethodType(data, "Facebook"),
                    countDiscoverMethodType(data, "Instagram"),
                    countDiscoverMethodType(data, "Word of Mouth"),
                    countDiscoverMethodType(data, "Horse Show"),
                    countDiscoverMethodType(data, "Email"),
                    countDiscoverMethodType(data, "Other"),
                ],
                backgroundColor: [
                    colors.theme["danger"],
                    colors.theme["warning"],
                    colors.theme["success"],
                    colors.theme["info"],
                    colors.theme["lightblue"],
                    colors.theme["blue"]
                ],
                label: "Discover Method"
            }
        ];
        
        formattedData["data"] = {
            labels: ["Facebook", "Instagram", "Word of Mouth", "Horse Show", "Email", "Other"],
            datasets: datasets
        };

        result = {isSuccess: true, type: "Chart", result: formattedData, message: "Successfully formatted the discover method data."};
        return result;
    } catch (error: any) {
        const result = {isSuccess: false, type: "Chart", result: error, message: "Error occurred while trying to format the discover method data."};
        return result;
    }
}

export function countDiscoverMethodType(data: any, type: any) {
    let count = 0;

    for (var i = 0; i < data.length; i++) {
        if (data[i].value === type) count = count + 1;
    }
    
    return count;
}