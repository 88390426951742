import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonLabel,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useState} from "react";

const OrganizationClassExplanationModal: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <IonLabel>
            <IonButton 
                id="organizationClassExplanationBtn"
                onClick={() => setShowModal(true)}
                fill="clear"
                className="ion-no-padding"
            >
                <IonText className="font-weight-normal text-dark text-capitalize initialism ion-text-wrap ion-text-start">
                    Learn More
                </IonText>
            </IonButton>
            <IonModal backdropDismiss={false} isOpen={showModal} id="organizationClassExplanationModal">
                <IonHeader>
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Organization Classes
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeOrganizationClassExplanationModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    X
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonText>To save you time, we allow you to reuse any Classes that you create. When you add an "Organization Class" any member of your organization can quickly add this Class to an event.</IonText>
                    <hr />
                    <IonText>We consider a "Class" to be a singular section a participant can enter. It will NOT have a Championship (i.e. a Medal class, a Derby, etc).</IonText>
                    <hr />
                    <IonText>We have a separate tab for "Organization Divisions" that will allow you to add any groups of classes that do result in a Championship (i.e. an Adult Hunter Division)</IonText>
                    <hr />
                    <IonText>You will be able to use these classes in any templates you create.</IonText>
                    <hr />
                    <IonText>Lastly, this is where you can edit any "Organization Class". You can update aspects such as the entry fee, amount of prize money, etc.</IonText>
                </IonContent>
                <IonButton id="CloseOrganizationClassExplanationModalBtn" onClick={() => handleClose()}>
                    Close
                </IonButton>
            </IonModal>
        </IonLabel>
    );
};

export default OrganizationClassExplanationModal;
