import "./../../theme/main.css";

import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonLabel,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useState} from "react";
import TermsAndConditions from "../../data/legal/TermsAndConditions";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

const TermsAndConditionsModal: React.FC<{handleAgreement: Function}> = ({children, handleAgreement}) => {
    const [showModal, setShowModal] = useState(false);

    const handleAgreeAndClose = () => {
        handleAgreement(true);
        setShowModal(false);
    };

    return (
        <IonLabel>
            <IonButton 
                id="termsAndConditionsBtn"
                onClick={() => setShowModal(true)}
                fill="clear"
                className="ion-no-padding"
            >
                <IonText className="font-weight-normal text-dark text-capitalize initialism ion-text-wrap ion-text-start">
                    I agree to the <span className="link">Terms and Conditions.</span><RequiredInputIndicator/>
                </IonText>
            </IonButton>
            <IonModal backdropDismiss={false} isOpen={showModal} id="termsAndConditionsModal">
                <IonHeader>
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Terms and Conditions
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeTermsAndConditionsModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    X
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    {TermsAndConditions}
                </IonContent>
                <IonButton id="agreeAndCloseTermsAndConditionsModalBtn" onClick={() => handleAgreeAndClose()}>
                    Agree and Close
                </IonButton>
            </IonModal>
        </IonLabel>
    );
};

export default TermsAndConditionsModal;
