import {
    IonCard,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import { Event } from "../../models";
import React from "react";
import { ribbon } from "ionicons/icons";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";

interface _Props {
    events: Event[]
}

const EventsList: React.FC<_Props> = ({events}) => {
    return (
        <IonCard className="bg-white ion-padding" mode="md">
            {events && events.length ?
                <IonList className="bg-white">
                    {events.map((event: Event, index: number) => (
                        <IonItem 
                            key={index} 
                            detail={true}
                            routerLink={"/index/staff/event/" + event.id} 
                            routerDirection="none"
                        >
                            <IonIcon icon={ribbon} slot="start" />
                            <IonLabel>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="4">
                                        <h2 className="ion-text-wrap">{event.name} {event.nickname ? (" (" + event.nickname + ")") : ""}</h2>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="4">
                                        <h2 className="ion-text-wrap">{event.status}</h2>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="4">
                                        <h2 className="ion-text-wrap">{event.startDate ? formatStartEndDatesStrings(event.startDate, event.endDate) : ""}</h2>
                                    </IonCol>
                                </IonRow>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
                :
                <p>No events yet.</p>
            }
        </IonCard>
    );
};

export default EventsList;