import React from "react";
import {
    IonChip
} from "@ionic/react";
import "../../theme/cards.css";

interface EntryCardChipProps {
    chipTitle: ChipTitleOption;
}

export type ChipTitleOption = "Information" | "Not Started" | "Incomplete" | "Completed";

const EntryCardChip: React.FC<EntryCardChipProps> = ({
    chipTitle,
}) => {
    const chipColor = ((chipTitle === "Information") ? "info" : ((chipTitle === "Completed") ? "success" : "danger"));
    return (
        <IonChip className={"card-chip-rd bg-" + chipColor}>{chipTitle}</IonChip>
    );
};

export default EntryCardChip;