import React, { useContext, useEffect, useState } from "react";
import Select from 'react-select';
// import { BarnMember } from "../../API";
import { PersonContext } from "../../context/PersonContext";
import { onCreateBarn, onUpdateBarn } from "../../graphql/subscriptions";
import { Barn, BarnMember } from "../../models";
import { getBarnMembersByPersonId } from "../../utilities/barnMember/BarnMember";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import ErrorAlert from "../Errors/ErrorAlert";
import { getAllBarns } from "../../utilities/barn/Barn";

interface _Props {
    onSelect: Function
    selectedValue?: string
    barn?: Barn
    barnMemberOptions?: BarnMember[]
    useAllBarns?: Boolean
}

interface formattedOption {
    label: string
    value: string
    barn: Barn
}

const SelectBarn: React.FC<_Props> = ({barn, barnMemberOptions, useAllBarns, onSelect, selectedValue}) => {
    const user = useContext(PersonContext);

    const barnSubscription = useSubscriptionByItself({
        config: {
            query: onCreateBarn,
            key: "onCreateBarn"
        }
    });

    const barnUpdateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateBarn,
            key: "onUpdateBarn"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [barnMembers, setBarnMembers] = useState<BarnMember[] | null | undefined>();
    const [formattedBarns, setFormattedBarns] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [error, setError] = useState("");

    const getBarnMembers = async () => {
        if (useAllBarns) {
            const queryResult = await getAllBarns();
            if (queryResult.isSuccess) {
                const allBarns: Barn[] = queryResult.result;
                formatBarnsFromBarns(allBarns);
            }
        } else {
            const queryResult = await getBarnMembersByPersonId(user.id);
            if (queryResult.isSuccess) {
                const memberList = queryResult.result;
                setBarnMembers(memberList);  
                formatBarnsFromBarnMemberships(memberList);          
            }
        }
    };

    const formatBarnsFromBarns = (barns: Barn[]) => {
        let formattedBarns = [];
        for (var i = 0; i < barns.length; i++) {
            const barn = barns[i];
            if (barn) {
                let object: formattedOption = {
                    value: barn.id,
                    label: barn.name,
                    barn: barn as Barn
                };
                formattedBarns.push(object);
            }
        }
        setFormattedBarns(formattedBarns);
        if (selectedValue) findValueInList(selectedValue, formattedBarns);
    }

    const formatBarnsFromBarnMemberships = (barnMembers: BarnMember[]) => {
        let formattedBarns = [];
        for (var i = 0; i < barnMembers.length; i++) {
            const barn = barnMembers[i].barn;
            if (barn) {
                let object: formattedOption = {
                    value: barn.id,
                    label: barn.name,
                    barn: barn as Barn
                };
                formattedBarns.push(object);
            }
        }
        setFormattedBarns(formattedBarns);
        if (selectedValue) findValueInList(selectedValue, formattedBarns);
    }

    const findValueInList = (value: string, barnList?: formattedOption[]) => {
        let optionArray = barnList || formattedBarns;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        if (barnMemberOptions) {
            formatBarnsFromBarnMemberships(barnMemberOptions);  
            setBarnMembers(barnMemberOptions);
        } 
    }, [barnMemberOptions]);

    useEffect(() => {
        if (!barnMembers) getBarnMembers();
    }, [barn]);

    useEffect(() => {
        if (!barnMembers) getBarnMembers();
    }, []);

    useEffect(() => {
        if (barnSubscription && barnSubscription[0] !== undefined && barnSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(barnSubscription[0]);
            getBarnMembers();
        }
    }, [barnSubscription]);

    useEffect(() => {
        if (barnUpdateSubscription && barnUpdateSubscription[0] !== undefined && barnUpdateSubscription[0] !== currentUpdateSubscriptionItem) {
            setCurrentSubscriptionItem(barnUpdateSubscription[0]);
            getBarnMembers();
        }
    }, [barnUpdateSubscription]);

    useEffect(() => {
        async function setValue() {
            if (selectedValue) {
                if (!formattedBarns) {
                    await getBarnMembers();
                } else {
                    findValueInList(selectedValue);
                }
                
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = async (event: any) => {
        if (event && event.barn) {
            setCurrentValue(event);
            onSelect(event.barn);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            <Select
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                // defaultValue={currentValue}
                value={currentValue}
                menuPortalTarget={document.body}
                isClearable
                options={formattedBarns!}
                onChange={handleOnChange}
            />
        </>
    );
};

export default SelectBarn;
