import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonPage,
    IonRow,
    IonSearchbar,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import { Barn, BarnMember, Person } from "../../../../models";
import { getBarnById } from "../../../../utilities/barn/Barn";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import CreateBarnPersonForm from "../../../../components/Barn/CreateBarnPersonForm";
import { getPeopleByName } from "../../../../utilities/person/Person";
import Spinner from "../../../../components/Spinners/Spinner";
import { Table } from "reactstrap";
import moment from "moment";
import { formatRolesStringToStringArray } from "../../../../utilities/roles/FormatRoles";
import { createBarnMember, getBarnMembersByPersonId } from "../../../../utilities/barnMember/BarnMember";
import { close } from "ionicons/icons";
import BasicTooltip from "../../../../components/Tooltip/BasicTooltip";
import { CreateBarnMemberInput, CreateOwnerInput, CreateRiderInput, CreateTrainerInput } from "../../../../API";
import { createRider } from "../../../../utilities/rider/Rider";
import { createOwner } from "../../../../utilities/owner/Owner";
import { createTrainer } from "../../../../utilities/trainer/Trainer";

interface BarnPageProps extends RouteComponentProps<{
    id: string;
}> {}

interface FormattedPerson {
    id: string
    name: string
    email: string
    location: string
    barnNames: string
    isAlreadyBarnMember: boolean
    roles: string
    dateJoined: string,
    person: Person
}

const NewBarnPersonPage: React.FC<BarnPageProps> = ({match}) => {
    const history = useHistory();

    const [barn, setBarn] = useState<Barn>();
    const [isCreate, setIsCreate] = useState(false);

    const [searchByNameText, setSearchByNameText] = useState("");
    const [resultText, setResultText] = useState("");
    const [resultArray, setResultArray] = useState<Person[] | undefined | null>();
    const [formattedPeopleArray, setFormattedPeopleArray] = useState<FormattedPerson[] | undefined | null>();

    const [showModal, setShowModal] = useState(false);
    const [selectedFormattedPerson, setSelectedFormattedPerson] = useState<FormattedPerson | undefined | null>();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getBarn() {
            const queryResult = await getBarnById(match.params.id);
            if (queryResult.isSuccess) {
                setBarn(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getBarn();
    }, [match, match.params.id]);

    const handleSearchInput = async (value: string) => {
        setSearchByNameText(value);
    }

    const handleKeyPress = (target: any) => {
        if(target.charCode===13){
            handleSearch();
        } 
    }

    const handleSearch = async () => {
        setIsLoading(true);
        let message = "Total people found: ";
        const firstName = searchByNameText.split(" ")[0];
        const lastName = searchByNameText.split(" ")[1];
        const queryResult = await getPeopleByName(firstName, lastName);
        if (queryResult.isSuccess) {
            const peopleResults: Person[] = queryResult.result;
            message = message + peopleResults.length;
            setResultArray(peopleResults);
            await formatPeople(peopleResults);
        } else {
            message = message + "0";
            setResultArray([]);
            setFormattedPeopleArray([]);
        }

        setResultText(message);
        setIsLoading(false);
    }

    const formatPeople = async (peopleArray: Person[]) => {
        setIsLoading(true);
        let formattedPeople: FormattedPerson[] = [];
        if (peopleArray && peopleArray.length > 0) {
            for (let i = 0; i < peopleArray.length; i++) {
                const currentPerson = peopleArray[i];
                const name = currentPerson.firstName + " " + currentPerson.lastName;

                const rolesArray: string[] = currentPerson.roles ? formatRolesStringToStringArray(currentPerson.roles) : [];
                const roles = rolesArray.join(", ");

                let barnNames = "";
                let isAlreadyBarnMember = false;
                const barnMembersResult = await getBarnMembersByPersonId(currentPerson.id);
                if (barnMembersResult) {
                    const barnMemberArray: BarnMember[] = barnMembersResult.result;
                    if (barnMemberArray && barnMemberArray.length > 0) {
                        barnMemberArray.forEach((barnMember) => {
                            if (barnMember.barn.id === barn?.id) isAlreadyBarnMember = true;
                            barnNames = barnNames + " " + barnMember.barn.name;
                        });
                    }
                }
                
                const formattedPerson: FormattedPerson = {
                    id: currentPerson.id,
                    email: currentPerson.email,
                    name: name,
                    roles: roles,
                    location: "",
                    barnNames: barnNames,
                    isAlreadyBarnMember: isAlreadyBarnMember,
                    dateJoined: moment(currentPerson.createdOn).format("MMM DD, YYYY"),
                    person: currentPerson
                };

                formattedPeople.push(formattedPerson);
            }
        }
        setFormattedPeopleArray(formattedPeople);
        setIsLoading(false);
    }

    const handlePersonSelect = (formattedPerson: FormattedPerson) => {
        setSelectedFormattedPerson(formattedPerson);
        setShowModal(true);
    }

    const handleModalCancel = () => {
        setShowModal(false);
    }

    const handleNewPerson = async () => {
        setIsLoading(true);
        const rolesArray: string[] = selectedFormattedPerson?.person.roles ? formatRolesStringToStringArray(selectedFormattedPerson.person.roles) : [];
        if (selectedFormattedPerson) {
            // Create the new barn member
            if (barn) {
                const barnMemberInput: CreateBarnMemberInput = {
                    barnId: barn.id,
                    personId: selectedFormattedPerson.id,
                    roles: selectedFormattedPerson.person.roles || "",
                    name: selectedFormattedPerson.name,
                    permissionLevel: "member"
                };
                const createBarnMemberResult = await createBarnMember(barnMemberInput);
                if (createBarnMemberResult.isSuccess) {
                    const rolesArray: string[] = selectedFormattedPerson.person.roles ? formatRolesStringToStringArray(selectedFormattedPerson.person.roles) : [];
                    if (rolesArray && rolesArray.length > 0) {
                        for (let i = 0; i < rolesArray.length; i++) {
                            const role = rolesArray[i];
                            if (role.includes("Rider")) {
                                const createRiderInput: CreateRiderInput = {
                                    name: selectedFormattedPerson.name,
                                    personId: selectedFormattedPerson.id,
                                    barnId: barn.id,
                                    barnName: barn.name,
                                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                };
                                await createRider(createRiderInput);
                            }
                            if (role.includes("Owner")) {
                                const createOwnerInput: CreateOwnerInput = {
                                    name: selectedFormattedPerson.name,
                                    personId: selectedFormattedPerson.id,
                                    barnId: barn.id,
                                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                };
                                await createOwner(createOwnerInput);
                            }
                            if (role.includes("Trainer")) {
                                const createTrainerInput: CreateTrainerInput = {
                                    name: selectedFormattedPerson.name,
                                    personId: selectedFormattedPerson.id,
                                    barnId: barn.id,
                                    barnName: barn.name,
                                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                };
                                await createTrainer(createTrainerInput);
                            }
                        }
                    }
                    setIsLoading(false);
                    setShowModal(false);
                    // const path = "/index/staff/barn/people/" + barn?.id;
                    // history.replace(path);
                } else {
                    const message = "Could not create the new barn member. " + createBarnMemberResult.message;
                    setError(message);
                    setIsLoading(false);
                }
            } else {
                setError("Could not find the barn selected.");
                setIsLoading(false);
            }
        } else {
            // Used the Create New Person option
            setIsLoading(false);
            const path = "/index/staff/barn/people/" + barn?.id;
            history.replace(path);
        }
        setShowModal(false);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={barn ? barn.name : "Barn"} />
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow>
                    <IonCol size="12">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                Add Person
                            </IonCardTitle>
                            <IonCardContent>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol sizeXs="6" sizeMd="3" sizeLg="2">
                                        <IonButton color={isCreate ? "light" : "primary"} onClick={() => setIsCreate(false)}>
                                            Invite
                                        </IonButton>
                                    </IonCol>
                                    <IonCol sizeXs="6" sizeMd="3" sizeLg="2">
                                        <IonButton color={isCreate ? "primary" : "light"} onClick={() => setIsCreate(true)}>
                                            Create
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <hr />
                                {isCreate ?
                                    <IonRow>
                                        <IonCol>
                                            <p className="ion-text-center ion-text-wrap">If the person you need to add is not already in the RingSide Pro system, you can create a new person.</p>
                                            <CreateBarnPersonForm barn={barn} onSubmit={handleNewPerson}/>
                                        </IonCol>
                                    </IonRow>
                                    :
                                    <IonRow>
                                        <IonCol>
                                            <p className="ion-text-center ion-text-wrap ion-text-dark">First, try to find the person you want to add to your barn.</p>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeXs="10" sizeMd="8">
                                                    <IonSearchbar 
                                                        color="white"
                                                        placeholder="Search by Name" 
                                                        value={searchByNameText} 
                                                        onKeyPress={e => handleKeyPress(e)}
                                                        onIonChange={e => handleSearchInput(e.detail.value!)} 
                                                    ></IonSearchbar>
                                                </IonCol>
                                                <IonCol sizeXs="2" sizeMd="2">
                                                    <IonButton
                                                        color="success"
                                                        onClick={handleSearch}
                                                    >
                                                        Search
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                            {isLoading ? 
                                                <Spinner />
                                                :   
                                                <>
                                                    {resultText && (
                                                        <IonRow>
                                                            <IonCol className="ion-text-center ion-text-wrap">
                                                                <p className="font-weight-bold">{resultText}</p>
                                                            </IonCol>
                                                        </IonRow>
                                                    )}
                                                    {(formattedPeopleArray && formattedPeopleArray.length > 0) && (
                                                        <>
                                                            <IonRow>
                                                                <IonCol className="">
                                                                    <p>Select the person you want to add to your barn.</p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <Table responsive hover>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Select</th>
                                                                                <th>Name</th>
                                                                                <th>Email</th>
                                                                                <th>Current Barn</th>
                                                                                <th>Roles</th>
                                                                                <th>Date Joined</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {formattedPeopleArray.map((person, index) => (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        {person.isAlreadyBarnMember ?
                                                                                            <BasicTooltip label="n/a" tip="Already a member of this barn." />
                                                                                            :
                                                                                            <IonButton 
                                                                                                size="small"
                                                                                                color="success"
                                                                                                onClick={() => handlePersonSelect(person)}
                                                                                            >
                                                                                                Add
                                                                                            </IonButton>
                                                                                        }
                                                                                    </td>
                                                                                    <td>{person.name}</td>
                                                                                    <td>{person.email}</td>
                                                                                    <td>{person.barnNames}</td>
                                                                                    <td>{person.roles}</td>
                                                                                    <td>{person.dateJoined}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </IonCol>
                                                            </IonRow>
                                                        </>
                                                    )}
                                                </>
                                            }
                                        </IonCol>
                                    </IonRow>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonModal backdropDismiss={false} isOpen={showModal} id="addPersonConfirmationModal">
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Confirm
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    <IonIcon icon={close} />
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent className="ion-padding">
                        <IonRow>
                            <IonCol className="ion-text-center ion-text-wrap">
                                <h5>Are you sure you want to add: </h5>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-wrap">
                                <p>Name: {selectedFormattedPerson?.name}</p>
                                <p>Email: {selectedFormattedPerson?.email}</p>
                                <p>Barn(s): {selectedFormattedPerson?.barnNames}</p>
                                <p>Role(s): {selectedFormattedPerson?.roles}</p>
                                <p>Date Joined: {selectedFormattedPerson?.dateJoined}</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-center ion-text-wrap">
                                <h5>to the barn: {barn?.name}?</h5>
                            </IonCol>
                        </IonRow>
                    </IonContent>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton color="light" onClick={() => handleModalCancel()}>
                                Cancel
                            </IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton color="success" onClick={() => handleNewPerson()}>
                                Yes, Add Person
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default NewBarnPersonPage;