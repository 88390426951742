import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateRiderInput, CreateRiderMutation, DeleteRiderInput, DeleteRiderMutation, GetRiderQuery, ListRidersQuery, UpdateRiderInput, UpdateRiderMutation } from "../../API";
import moment from "moment";
import { Rider } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: rider. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created rider.
*/
export async function createRider(input: CreateRiderInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Rider", "No input", "Create Rider received no input.");
   try {
        const fullInput: CreateRiderInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createRider, { input: fullInput }))) as GraphQLResult<CreateRiderMutation>;
       else result = (await API.graphql({
           query: mutations.createRider,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateRiderMutation>;
       const rider = result.data?.createRider;
       return formatResult(true, "Rider", rider, "Successfully created the rider.");
   } catch (error: any) {
       return formatResult(false, "Rider", error, "Error creating record in the database for type: rider");
   }
}

/**
* Handle updating a new record in the database for type: rider. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated rider.
*/
export async function updateRider(input: UpdateRiderInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Rider", "No input", "Update Rider received no input.");
   try {
        const fullInput: UpdateRiderInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateRider, { input: fullInput }))) as GraphQLResult<UpdateRiderMutation>;
       else result = (await API.graphql({
           query: mutations.updateRider,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateRiderMutation>;
       const rider = result.data?.updateRider;
       return formatResult(true, "Rider", rider, "Successfully updated the rider.");
   } catch (error: any) {
       return formatResult(false, "Rider", error, "Error updating record in the database for type: rider");
   }
}

/**
* Handle deleting a new record in the database for type: rider. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted rider.
*/
export async function deleteRider(input: DeleteRiderInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Rider", "No input", "Delete Rider received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteRider, { input: input }))) as GraphQLResult<DeleteRiderMutation>;
       else result = (await API.graphql({
           query: mutations.deleteRider,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteRiderMutation>;
       const rider = result.data?.deleteRider;
       return formatResult(true, "Rider", rider, "Successfully deleted the rider.");
   } catch (error: any) {
       return formatResult(false, "Rider", error, "Error deleting record in the database for type: rider");
   }
}

/**
* Get all records in the database for type: rider. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the rider object
*/
export async function getAllRiders(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listRiders,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListRidersQuery>;
        
        let items = result.data?.listRiders?.items as Rider[];
        let nextToken = result.data?.listRiders?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listRiders,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListRidersQuery>;

            const nextItems = nextResult.data?.listRiders?.items as Rider[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listRiders?.nextToken;
        }

        return formatResult(true, "Rider", items, "Successfully got the Riders.");
    } catch (error: any) {
        return formatResult(false, "Rider", error, "Error reading record in the database for type: riders");
    }
}

/**
* Read a specific record in the database for type: rider. 
* 
* @param {string}  id                  The rider id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the rider object
*/
export async function getRiderById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getRider,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetRiderQuery>;
       const rider = result.data?.getRider;
       return formatResult(true, "Rider", rider, "Successfully got the rider.");
   } catch (error: any) {
       return formatResult(false, "Rider", error, "Error reading record in the database for type: rider");
   }
}

/**
* Get all records in the database that match the given criteria for type: rider. 
* 
* @param {string}  personId            The person id of the rider.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the rider object
*/
export async function getRiderByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listRiders,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListRidersQuery>;

        let items = result.data?.listRiders?.items as Rider[];
        let nextToken = result.data?.listRiders?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listRiders,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListRidersQuery>;

            const nextItems = nextResult.data?.listRiders?.items as Rider[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listRiders?.nextToken;
        }

        const riders = items;
        if (riders && riders[0]) return formatResult(true, "Rider", riders[0], "Successfully got the rider.");
        else return formatResult(false, "Rider", null, "Could not find the rider.");
    } catch (error: any) {
        return formatResult(false, "Rider", error, "Error reading record in the database for type: rider");
    }
}

/**
* Get all records in the database that match the given criteria for type: rider. 
* 
* @param {string}  personId            The person id of the rider.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the rider object
*/
export async function getRidersByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listRiders,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListRidersQuery>;

        let items = result.data?.listRiders?.items as Rider[];
        let nextToken = result.data?.listRiders?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listRiders,
                variables: {
                    limit: 1000,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListRidersQuery>;

            const nextItems = nextResult.data?.listRiders?.items as Rider[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listRiders?.nextToken;
        }

        return formatResult(true, "Rider", items, "Successfully got the rider.");
    } catch (error: any) {
        return formatResult(false, "Rider", error, "Error reading record in the database for type: rider");
    }
}

/**
* Get all records in the database that match the given criteria for type: rider. 
* 
* @param {string}  name                The name of the rider.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the rider object
*/
export async function getRidersByPersonName(name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            name: {contains: name}
        };
        const result = (await API.graphql({
            query: queries.listRiders,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListRidersQuery>;

        let items = result.data?.listRiders?.items as Rider[];
        let nextToken = result.data?.listRiders?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listRiders,
                variables: {
                    limit: 1000,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListRidersQuery>;

            const nextItems = nextResult.data?.listRiders?.items as Rider[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listRiders?.nextToken;
        }

        return formatResult(true, "Rider", items, "Successfully got the rider.");
    } catch (error: any) {
        return formatResult(false, "Rider", error, "Error reading record in the database for type: rider");
    }
}

/**
* Get all records in the database that match the given criteria for type: rider. 
* 
* @param {string}  personId            The person id of the rider.
* @param {string}  name                The name of the rider.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the rider object
*/
export async function getRiderByPersonIdAndName(personId: string, name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            personId: {eq: personId},
            name: {eq: name}
        };
        const result = (await API.graphql({
            query: queries.listRiders,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListRidersQuery>;

        let items = result.data?.listRiders?.items as Rider[];
        let nextToken = result.data?.listRiders?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listRiders,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListRidersQuery>;

            const nextItems = nextResult.data?.listRiders?.items as Rider[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listRiders?.nextToken;
        }

        const riders = items;
        if (riders && riders[0]) return formatResult(true, "Rider", riders[0], "Successfully got the rider.");
        else return formatResult(false, "Rider", null, "Could not find the rider.");
    } catch (error: any) {
        return formatResult(false, "Rider", error, "Error reading record in the database for type: rider");
    }
}

/**
* Get all records in the database that match the given criteria for type: rider. 
* 
* @param {string}  personId            The person id of the creator
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the rider object
*/
export async function getRidersByCreatedBy(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listRiders,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListRidersQuery>;
        
        let items = result.data?.listRiders?.items as Rider[];
        let nextToken = result.data?.listRiders?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listRiders,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListRidersQuery>;

            const nextItems = nextResult.data?.listRiders?.items as Rider[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listRiders?.nextToken;
        }

        return formatResult(true, "Rider", items, "Successfully got the Riders.");
    } catch (error: any) {
        return formatResult(false, "Rider", error, "Error reading record in the database for type: riders");
    }
}

/**
* Get all records in the database that match the given criteria for type: rider. 
* 
* @param {string}  barnId              The barn id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the rider object
*/
export async function getRidersByBarnId(barnId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {barnId: {eq: barnId}};
        const result = (await API.graphql({
            query: queries.listRiders,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListRidersQuery>;
        
        let items = result.data?.listRiders?.items as Rider[];
        let nextToken = result.data?.listRiders?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listRiders,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListRidersQuery>;

            const nextItems = nextResult.data?.listRiders?.items as Rider[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listRiders?.nextToken;
        }

        return formatResult(true, "Rider", items, "Successfully got the Riders.");
    } catch (error: any) {
        return formatResult(false, "Rider", error, "Error reading record in the database for type: riders");
    }
}