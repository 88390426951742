import { Clinician, ClinicianStatus } from "../../models";
import { CreateClinicianInput, UpdateClinicianInput } from "../../API";
import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonTextarea,
    IonToggle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";

import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import SelectState from "../Address/SelectState";

import { createClinician, updateClinician, getClinicianByPersonId} from "../../utilities/clinician/Clinician";

import moment from "moment";
import SelectDisciplines from "../Discipline/SelectDisciplines";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

import {getFormattedDisciplineString} from "../../utilities/discipline/FormatDisciplines";
import { getEventDisciplinesAsArray } from "../../utilities/events/EventDiscipline";

interface _Props {
    selectedClinician?: (Clinician | null)
    onChange?: Function
}

const ClinicianForm: React.FC<_Props> = ({selectedClinician, onChange}) => {
    const user = useContext(PersonContext);
    
    const [isEditing, setIsEditing] = useState(!(!!selectedClinician));
    const [clinician, setClinician] = useState<Clinician | null | undefined>();
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [usefNumber, setUSEFNumber] = useState("");
    const [price, setPrice] = useState("");
    const [disciplines, setDisciplines] = useState("");
    const [experience, setExperience] = useState("");
    const [personal, setPersonal] = useState("");
    const [preferences, setPreferences] = useState("");
    const [hasIHSAExperience, setHasIHSAExperience] = useState(false);
    const [hasIEAExperience, setHasIEAExperience] = useState(false);
    const [error, setError] = useState("");

    const getClinicianInfo = (clinician: Clinician) => {
        setName(clinician.name || "");
        setCity(clinician.location?.split(",")[0] || "");
        setState(clinician.location?.split(",")[1] || "");
        setUSEFNumber(clinician.usefNumber || "");
        setDisciplines(clinician.disciplines || "");
        setExperience(clinician.experience || "");
        setPersonal(clinician.personal || "");
        setPreferences(clinician.preferences || "");
        setHasIHSAExperience(!!clinician.hasIHSAExperience);
        setHasIEAExperience(!!clinician.hasIEAExperience);
        setPrice(clinician.price?.toString() || "");
    }

    useEffect(() => {
        const getClinician = async (personId: string) => {
            const queryResult = await getClinicianByPersonId(personId);
            if (queryResult.isSuccess) {
                const clinician: Clinician = queryResult.result;
                setClinician(clinician);
                getClinicianInfo(clinician);
            }
        }
        
        if (user) {
            const name = (user.firstName || "") + (user.lastName || "");
            setName(name);
            getClinician(user.id);
        }
    }, []);

    useEffect(() => {
        setClinician(selectedClinician);
        if (selectedClinician) getClinicianInfo(selectedClinician);
    }, [selectedClinician]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include a name.");
            return false;
        }
        if (!city) {
            setError("Please include a city.");
            return false;
        }
        if (!state) {
            setError("Please include a state.");
            return false;
        }
        if (!price) {
            setError("Please include a price.");
            return false;
        }
        return true;
    }

    const handleProvStateInputChange = (value: string) => {
        if (value && value !== "-") setState(value);
    }

    const handleDisciplinesSelect = (disciplines: string[]) => {
        let disciplineString = "";
        for (let i = 0; i < disciplines.length; i++) {
            const discipline = disciplines[i];
            disciplineString = disciplineString + discipline + ";";
        }
        setDisciplines(disciplineString);
    }

    const handleCreateClinician = async () => {
        setError("");
        let input: CreateClinicianInput = {
            status: ClinicianStatus.PENDING,
            name: name, 
            personId: user.id,
            usefNumber: usefNumber,
            price: parseFloat(price),
            location: city + ", " + state,
            disciplines: disciplines,
            experience,
            personal,
            preferences,
            hasIHSAExperience,
            hasIEAExperience,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        };
        const createResult = await createClinician(input);
        if (createResult.isSuccess) {
            if (onChange) onChange(createResult.result);
            setClinician(createResult.result);
        } else {
            setError(createResult.message);
        }
    }


    const handleUpdateClinician = async () => {
        setError("");
        if (clinician) {
            const input: UpdateClinicianInput = {
                id: clinician.id,
                name: name,
                usefNumber: usefNumber,
                price: parseFloat(price),
                location: city + ", " + state,
                disciplines: disciplines,
                experience,
                personal,
                preferences,
                hasIHSAExperience,
                hasIEAExperience,
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const updateResult = await updateClinician(input);
            if (updateResult.isSuccess) {
                if (onChange) onChange(updateResult.result);
                setClinician(updateResult.result);
            } else {
                setError(updateResult.message);
            }
        } else {
            setError("No clinician to edit.");
        }
    }

    const handleAddClinician = async () => {
        const isValid = verifyForm();
        if (isValid) {
            await handleCreateClinician();
            setIsEditing(false);
        }
    }

    const handleEditClinician = async () => {
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateClinician();
            setIsEditing(false);
        }
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            {isEditing ?
                <>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Name</IonLabel>
                                <IonInput 
                                    id="nameInput"
                                    type="text"
                                    value={name}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setName(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">City</IonLabel>
                                <IonInput 
                                    id="cityInput"
                                    type="text"
                                    value={city}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setCity(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <SelectState selectedValue={state} onInputChange={handleProvStateInputChange} />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">USEF Number</IonLabel>
                                <IonInput 
                                    id="usefInput"
                                    type="text"
                                    value={usefNumber}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setUSEFNumber(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonLabel position="stacked" className="description pl-3 text-dark">Disciplines</IonLabel>
                            <SelectDisciplines selectedDisciplines={getEventDisciplinesAsArray(disciplines)} onSelect={handleDisciplinesSelect} />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Judging or Clinician Experience</IonLabel>
                                <IonTextarea 
                                    id="experienceInput"
                                    rows={5}
                                    value={experience}
                                    placeholder="Examples: how long you have been a trainer or judge, specific horse shows you attend, your philosophy around training or judging..."
                                    aria-required={true}
                                    onIonChange={e => {
                                        setExperience(e.detail.value!);
                                    }}
                                ></IonTextarea>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Other Information About You</IonLabel>
                                <IonTextarea 
                                    id="infoInput"
                                    rows={5}
                                    value={personal}
                                    disabled={false}
                                    placeholder="Examples: personal background information, experience as a rider or trainer, board positions..."
                                    onIonChange={e => {
                                        setPersonal(e.detail.value!);
                                    }}
                                ></IonTextarea>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none">
                                <IonLabel slot="start">IHSA Experience</IonLabel>
                                <IonToggle id="ihsaToggle" color="tertiary" checked={hasIHSAExperience} onIonChange={e => setHasIHSAExperience(e.detail.checked)}/>
                            </IonItem> 
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none">
                                <IonLabel slot="start">IEA Experience</IonLabel>
                                <IonToggle id="ieaToggle" color="tertiary" checked={hasIEAExperience} onIonChange={e => setHasIEAExperience(e.detail.checked)}/>
                            </IonItem> 
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Video Preferences</IonLabel>
                                <IonInput 
                                    id="preferenceInput"
                                    type="text"
                                    value={preferences}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setPreferences(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel position="stacked" className="description pl-3 text-dark">Price Per Video</IonLabel>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    id="priceInput"
                                    type="number"
                                    value={price}
                                    min={0}
                                    max={100}
                                    onChange={e => {
                                        setPrice(e.target.value);
                                    }}
                                />
                            </InputGroup>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="4" className="ion-text-center">
                            <IonButton
                                className="ion-margin-top"
                                color="secondary"
                                expand="block"
                                onClick={() => setIsEditing(false)}
                            >
                                Cancel
                            </IonButton>
                        </IonCol>
                        <IonCol sizeMd="4" className="ion-text-center">
                            <IonButton
                                id="saveBtn"
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={clinician ? handleEditClinician : handleAddClinician}
                            >
                                Save
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>  
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Name</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={clinician?.name}
                                    disabled={true}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Location</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={clinician?.location}
                                    disabled={true}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">USEF Number</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={clinician?.usefNumber}
                                    disabled={true}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Disciplines</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={getFormattedDisciplineString(clinician?.disciplines || "")}
                                    disabled={true}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Judging or Clinician Experience</IonLabel>
                                <IonTextarea 
                                    rows={5}
                                    value={clinician?.experience}
                                    disabled={true}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Other Information About You</IonLabel>
                                <IonTextarea 
                                    rows={5}
                                    value={clinician?.personal}
                                    disabled={true}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none">
                                <IonLabel slot="start">IHSA Experience</IonLabel>
                                <IonToggle color="tertiary" checked={clinician?.hasIHSAExperience!} disabled={true}/>
                            </IonItem> 
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none">
                                <IonLabel slot="start">IEA Experience</IonLabel>
                                <IonToggle color="tertiary" checked={clinician?.hasIEAExperience!} disabled={true}/>
                            </IonItem> 
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Video Preferences</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={clinician?.preferences}
                                    disabled={true}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel position="stacked" className="description pl-3 text-dark">Price Per Video</IonLabel>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type="number"
                                    value={clinician?.price!}
                                    disabled={true}
                                />
                            </InputGroup>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="4" className="ion-text-center">
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={() => setIsEditing(!isEditing)}
                            >
                                Edit
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default ClinicianForm;