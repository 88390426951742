import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToggle,
    IonToolbar,
} from "@ionic/react";
import React, { createRef, useContext, useEffect, useState } from "react";
import { BeddingRequest, Event, EventEntry, EventEntryFee, EventInvoice, EventResult, Fee, StablingRequest } from "../../../models";
import { getEventEntryFeesByEventIdByEntryId } from "../../../utilities/eventEntryFee/EventEntryFee";
import { getStablingRequestsByEventId } from "../../../utilities/stablingRequest/StablingRequest";
import { generateInvoicePDF, InvoicePDFData, InvoicePDFSettings } from "../../../utilities/reports/InvoicePDF";
import { getEventResultsByEntryId } from "../../../utilities/eventResult/EventResult";
import Spinner from "../../Spinners/Spinner";
import { sendInvoiceEmail } from "../../../utilities/emails/InvoiceEmail";
import { getContactById } from "../../../utilities/contact/Contact";
import { close } from "ionicons/icons";
import { CreateEventInvoiceInput, UpdateEventEntryInput } from "../../../API";
import moment from "moment";
import { PersonContext } from "../../../context/PersonContext";
import { createEventInvoice, deleteEventInvoice, getEventInvoicesByEventIdEntryId } from "../../../utilities/eventInvoice/EventInvoice";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { getEventClassEntriesByEventIdEntryId } from "../../../utilities/eventClassEntry/EventClassEntry";
import EntryCheckInForm from "./EntryCheckInForm";
import { formatStablingRequests } from "../../../utilities/stablingRequest/FormatStablingRequests";
import { getBeddingRequestsByEntryId } from "../../../utilities/beddingRequest/BeddingRequest";
import { formattedOptionGroup, getFormattedEntryClassList } from "../../../utilities/eventClassEntry/FormattedEventEntryClasses";
import { updateEventEntry } from "../../../utilities/eventEntry/EventEntry";
import ErrorAlert from "../../Errors/ErrorAlert";

interface _Props {
    event: Event
    entry: EventEntry
    onCalculate?: Function
    onUpdateEntry: Function
}

interface FormattedEventFee {
    id: string
    name: string
    amount: number
    quantity: number
    tax: string
    total: number
    isPerClass: boolean
    isAfterStartDate: boolean
    startDate?: string
    startTime?: string
}

interface FormattedStablingRequest {
    horseName?: (string | null)
    trainerName?: (string | null)
    type?: (string | null)
    quantity?: (string | null)
    price?: (string | null)
    tax?: (string | null)
    totalPrice?: (string | null)
    splitCost?: (string | null)
    entries?: EventEntry[]
}

const ViewEntryCurrentInvoice: React.FC<_Props> = ({event, entry, onCalculate, onUpdateEntry}) => {
    const user = useContext(PersonContext);
    const paymentAmountRef = createRef<HTMLInputElement>();

    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [totalEntryFees, setTotalEntryFees] = useState<number | null | undefined>();
    const [taxEntryFees, setTaxEntryFees] = useState<number | null | undefined>();
    const [taxTotalEntryFees, setTaxTotalEntryFees] = useState<number | null | undefined>();
    const [formattedClassData, setFormattedClassData] = useState<formattedOptionGroup[] | null | undefined>();
    const [totalEventFees, setTotalEventFees] = useState<number | null | undefined>();
    const [previousEventEntryFees, setPreviousEventEntryFees] = useState<EventEntryFee[] | null | undefined>();
    const [previousFormattedEventEntryFees, setPreviousFormattedEventEntryFees] = useState<FormattedEventFee[] | null | undefined>();
    const [totalStablingCost, setTotalStablingCost] = useState<number | null | undefined>();
    const [stablingRequests, setStablingRequests] = useState<StablingRequest[] | null | undefined>();
    const [beddingRequests, setBeddingRequests] = useState<BeddingRequest[] | null | undefined>();
    const [formattedStablingRequests, setFormattedStablingRequests] = useState<FormattedStablingRequest[] | null | undefined>();
    const [totalPrizeMoney, setTotalPrizeMoney] = useState<number | null | undefined>();
    const [eventResults, setEventResults] = useState<EventResult[] | null | undefined>();

    const [showModal, setShowModal] = useState(false);
    const [didModalFocus, setDidModalFocus] = useState(false);
    const [showCreditModal, setShowCreditModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [entryInvoices, setEntryInvoices] = useState<EventInvoice[] | null | undefined>();
    const [totalRecordedPayments, setTotalRecordedPayments] = useState<number | null | undefined>();
    const [payerName, setPayerName] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [currentPaymentAmount, setCurrentPaymentAmount] = useState<number | null | undefined>();
    const [paymentNotes, setPaymentNotes] = useState("");
    const [creditName, setCreditName] = useState("");
    const [creditAmount, setCreditAmount] = useState(0);
    const [creditNotes, setCreditNotes] = useState("");
    const [isCheckedOut, setIsCheckedOut] = useState<boolean>(entry.isCheckedOut || false);

    const [error, setError] = useState("");

    const getFinalFeeCost = (total?: (number | null), taxPercent?: (number | null)) => {
        if (!total) total = totalEntryFees;
        if (!taxPercent) taxPercent = taxEntryFees;
        if (total && taxPercent) {
            const finalCost = total + total * (taxPercent || 0)/100;
            setTaxTotalEntryFees(finalCost);

            // Not currently using remainingBalance anywhere
            // if (event.id && entry.id) calculateRemainingBalance(event.id, entry.id);
        }
    }

    const getFormattedClassData = async (entry: EventEntry) => {
        setIsDownloading(true);
        // On the invoice page, get the class data only if the EventClassEntry was marked accepted
        const dataResult = await getFormattedEntryClassList(entry, true);
        if (dataResult.isSuccess) {
            setTotalEntryFees(dataResult.result.totalEntryFees);
            if (taxEntryFees) getFinalFeeCost(dataResult.result.totalEntryFees, taxEntryFees);
            else if (event) getFinalFeeCost(dataResult.result.totalEntryFees, event.invoiceOptions?.classFeeTax);
            if (onCalculate) onCalculate(dataResult.result.totalEntryFees);
            setFormattedClassData(dataResult.result.classList);
            setIsDownloading(false);
        } else {
            setIsDownloading(false);
        }
    }

    // Not being called - remainingBalance value is never used
    // const calculateRemainingBalance = async (eventId: string, entryId: string, eventInvoices?: EventInvoice[]) => {
    //     let invoices: EventInvoice[] = eventInvoices || entryInvoices || [];
    //     if (invoices && invoices.length > 0) {
    //         const queryResult = await getEventInvoicesByEventIdEntryId(eventId, entryId);
    //         if (queryResult.isSuccess) {
    //             invoices = queryResult.result;
    //         }
    //     }
    //     const totalDue = parseFloat(((taxTotalEntryFees || totalEntryFees || 0) + (totalEventFees || 0) + (totalStablingCost || 0) - (totalPrizeMoney || 0)).toFixed(2));
    //     let amountPaid = 0;
    //     invoices.forEach(invoice => {
    //         amountPaid = amountPaid + (invoice?.amountToDeductFromInvoice || 0);
    //     });
    //     setTotalRecordedPayments(amountPaid);
    //     if (totalDue-amountPaid >= 0) setRemainingBalance(totalDue-amountPaid);
    // }

    const getInvoices = async (eventId: string, entryId: string) => {
        const queryResult = await getEventInvoicesByEventIdEntryId(eventId, entryId);
        if (queryResult.isSuccess) {
            const currentEntryInvoices: EventInvoice[] = queryResult.result;
            setEntryInvoices(currentEntryInvoices);

            // Not currently using remainingBalance anywhere
            // calculateRemainingBalance(eventId, entryId, currentEntryInvoices);
        } else {
            setEntryInvoices([]);
        }
    }

    const getAllData = async () => {
        setIsLoading(true);
        if (entry) { 
            setIsCollapsed(false);
            await getFormattedClassData(entry);
            await getEventEntryFeesByEntry(entry);
            if (entry.primarycontact?.personalEmail) setEmailAddress(entry.primarycontact?.personalEmail);
        }
        if (event && entry) {
            await getStablingRequestsByEventByEntry(event, entry);
            await getInvoices(event.id, entry.id);
            await getEventResultsByEntry(entry);
        }
        if (event && event.invoiceOptions && event.invoiceOptions.classFeeTax) {
            setTaxEntryFees(event.invoiceOptions.classFeeTax);
            if (totalEntryFees) getFinalFeeCost(totalEntryFees, event.invoiceOptions.classFeeTax);
            else if (entry) await getFormattedClassData(entry);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getAllData();
    }, [entry, event]);

    const calculateFeeQuantity = async (eventEntryFee: EventEntryFee) => {
        const fee: Fee | null | undefined = eventEntryFee.fee;
        // Check if the fee is based on the number of classes entered
        if (fee && fee.isPerClass) {
            // Check if class data has already been gathered for this entry
            if (formattedClassData) {
                // TO DO
            } else {
                const classQueryResult = await getEventClassEntriesByEventIdEntryId(event.id, entry.id);
                if (classQueryResult.isSuccess) {
                    const classList = classQueryResult.result;
                    return classList.length;
                }
            }
        } 
        return eventEntryFee.quantity || 0;
    }

    const calculateFeeCost = (eventEntryFee: EventEntryFee, quantity: number) => {
        const rawAmount = eventEntryFee.amount;
        const taxA = eventEntryFee.fee ? (eventEntryFee.fee.taxA ? eventEntryFee.fee.taxA/100 : 0) : 0;
        const taxB = eventEntryFee.fee ? (eventEntryFee.fee.taxB ? eventEntryFee.fee.taxB/100 : 0) : 0;

        let total = rawAmount;
        total = total * quantity;
        if (taxA && taxB) total = total + (total * (taxA + taxB));
        else if (taxA) total = total + (total * taxA);
        else if (taxB) total = total + (total * taxB);

        return total
    }

    const calculateEventFeeTotal = (formattedEventEntryFees?: FormattedEventFee[]) => {
        let total = 0;
        formattedEventEntryFees?.forEach(eventEntryFee => {
            total = total + eventEntryFee.total;
        })
        setTotalEventFees(total);

        // Not currently using remainingBalance anywhere
        // if (event.id && entry.id) calculateRemainingBalance(event.id, entry.id);
    }

    const formatEventFees = async (eventEntryFees: EventEntryFee[]) => {
        let result: FormattedEventFee[] = [];
        for (let i = 0; i < eventEntryFees.length; i++) {
            const currentEventEntryFee = eventEntryFees[i];
            const fee = currentEventEntryFee.fee;
            const quantity = await calculateFeeQuantity(currentEventEntryFee);
            let formattedObject: FormattedEventFee = {
                id: currentEventEntryFee.id,
                name: currentEventEntryFee.name,
                amount: currentEventEntryFee.amount,
                quantity: quantity,
                tax: currentEventEntryFee.fee?.taxA ? currentEventEntryFee.fee?.taxA.toString() + "%" : "",
                total: calculateFeeCost(currentEventEntryFee, quantity),
                isPerClass: !!fee?.isPerClass,
                isAfterStartDate: !!fee?.isStartedOnEntryDate,
                startDate: fee?.isStartedOnEntryDate ? fee.startDate || "" : "",
                startTime: fee?.isStartedOnEntryDate ? fee.startTime || "" : ""
            };

            result.push(formattedObject);
        }

        setPreviousFormattedEventEntryFees(result);
        calculateEventFeeTotal(result);
    }

    const getEventEntryFeesByEntry = async (eventEntry: EventEntry) => {
        const queryResult = await getEventEntryFeesByEventIdByEntryId(eventEntry.eventId, eventEntry.id);
        if (queryResult.isSuccess) {
            await formatEventFees(queryResult.result);
            setPreviousEventEntryFees(queryResult.result);
        }
    }

    const getTotalCost = (stablingRequests: StablingRequest[], beddingRequests?: BeddingRequest[]) => {
        let total = 0;
        if (stablingRequests) {
            stablingRequests.forEach(stablingRequest => {
                if (stablingRequest.entryIds && stablingRequest.entryIds.length > 1) {
                    // If its a split cost, divide by number of entries
                    total = total + ((stablingRequest.totalPrice || 0) / stablingRequest.entryIds.length);
                } else {
                    total = total + (stablingRequest.totalPrice || 0);
                }
            });
        }
        if (beddingRequests) {
            beddingRequests.forEach(beddingRequest => {
                if (beddingRequest.entryIds && beddingRequest.entryIds.length > 1) {
                    // If its a split cost, divide by number of entries
                    total = total + ((beddingRequest.totalPrice || 0) / beddingRequest.entryIds.length);
                } else {
                    total = total + (beddingRequest.totalPrice || 0);
                }
            });
        }
        setTotalStablingCost(total);

        // Not currently using remainingBalance anywhere
        // if (event.id && entry.id) calculateRemainingBalance(event.id, entry.id);
    }

    const formatCurrentStablingRequests = async (stablingRequests: StablingRequest[]) => {
        const formattedArray = await formatStablingRequests(stablingRequests);
        setFormattedStablingRequests(formattedArray);
    }

    const getStablingRequestsByEventByEntry = async (event: Event, entry: EventEntry) => {
        let requestList: StablingRequest[] = [];
        let beddingRequestList: BeddingRequest[] = [];
        const queryResult = await getStablingRequestsByEventId(event.id);
        if (queryResult.isSuccess) {
            const allStablingRequests = queryResult.result;
            allStablingRequests.forEach((sr: StablingRequest) => {
                const currentEntries = sr.entryIds;
                if (currentEntries && currentEntries.length) {
                    currentEntries.forEach(currentEntry => {
                        if (currentEntry === entry.id) requestList.push(sr);
                    });
                }
            });
        } 
        const beddingQueryResult = await getBeddingRequestsByEntryId(entry.id);
        if (beddingQueryResult.isSuccess) {
            beddingRequestList = beddingQueryResult.result;
        }
        getTotalCost(requestList, beddingRequestList);
        setStablingRequests(requestList);
        setBeddingRequests(beddingRequestList);
        formatCurrentStablingRequests(requestList);
    }

    const printInvoice = async () => {
        setIsDownloading(true);
        const dataObject: InvoicePDFData = {
            formattedClassData,
            previousEventEntryFees: previousEventEntryFees,
            formattedStablingRequests,
            beddingRequests: beddingRequests,
            eventResults
        };
        const settingsObject: InvoicePDFSettings = {
            isDue: true,
            isDownload: true,
            isPrintOnly: true,
            includeRiderInfo: true,
            includeHorseInfo: true,
            includeOwnerInfo: true,
            includeTrainerInfo: true,
            includeBarnInfo: true,
            includePrimaryContactInfo: true,
            includeEmails: true,
            includePhoneNumbers: true,
            includeMemberships: true
        };
        await generateInvoicePDF(entry, event, dataObject, settingsObject);
        setIsDownloading(false);
    }

    const downloadInvoice = async () => {
        setIsDownloading(true);
        const dataObject: InvoicePDFData = {
            formattedClassData,
            previousEventEntryFees: previousEventEntryFees,
            formattedStablingRequests,
            beddingRequests: beddingRequests,
            eventResults
        };
        const settingsObject: InvoicePDFSettings = {
            isDue: true,
            isDownload: true,
            isPrintOnly: false,
            includeRiderInfo: true,
            includeHorseInfo: true,
            includeOwnerInfo: true,
            includeTrainerInfo: true,
            includeBarnInfo: true,
            includePrimaryContactInfo: true,
            includeEmails: true,
            includePhoneNumbers: true,
            includeMemberships: true
        };
        await generateInvoicePDF(entry, event, dataObject, settingsObject);
        setIsDownloading(false);
    }

    const emailInvoice = async () => {
        setIsDownloading(true);
        setShowEmailModal(false);
        const dataObject: InvoicePDFData = {
            formattedClassData,
            previousEventEntryFees: previousEventEntryFees,
            formattedStablingRequests,
            beddingRequests: beddingRequests,
            eventResults
        };
        const settingsObject: InvoicePDFSettings = {
            isDue: true,
            isDownload: false,
            isPrintOnly: false,
            includeRiderInfo: true,
            includeHorseInfo: true,
            includeOwnerInfo: true,
            includeTrainerInfo: true,
            includeBarnInfo: true,
            includePrimaryContactInfo: true,
            includeEmails: true,
            includePhoneNumbers: true,
            includeMemberships: true
        };
        const pdf = await generateInvoicePDF(entry, event, dataObject, settingsObject);
        const primaryContact = entry.primarycontact;
        const total = ((taxTotalEntryFees || totalEntryFees || 0) + (totalEventFees || 0) + (totalStablingCost || 0) - (totalPrizeMoney || 0) - (totalRecordedPayments || 0)).toFixed(2);
        let showEmail = "hello@ringsidepro.com";
        const queryShowContactResult = await getContactById(event.contactId || "");
        if (queryShowContactResult.isSuccess) showEmail = queryShowContactResult.result.workEmail;
        if (pdf && emailAddress) await sendInvoiceEmail(emailAddress, primaryContact?.name || "", event.name, showEmail, total, pdf); 
        else setError("Could not send the email with the invoice attachment.");
        setIsDownloading(false);
    }

    const calculateTotalPrizeMoney = (results: EventResult[]) => {
        let total = 0;
        results.forEach(result => {
            total = total + (result.prizeMoney || 0);
        });
        setTotalPrizeMoney(total);

        // Not currently using remainingBalance anywhere
        // if (event.id && entry.id) calculateRemainingBalance(event.id, entry.id);
    }

    const getEventResultsByEntry = async (entry: EventEntry) => {
        const queryResult = await getEventResultsByEntryId(entry.id);
        if (queryResult.isSuccess) {
            setEventResults(queryResult.result);
            calculateTotalPrizeMoney(queryResult.result);
        }
    }

    const handleRecordPaymentClick = () => {
        // Open the Record Payment Modal
        setShowModal(true);

        // Reset the state tracking if the modal's form focus has been set
        setDidModalFocus(false);
    }

    useEffect(() => {
        // When the Record Payment Modal is open, set the focus to the Amount field
        // This feature was requested by RHSC (they do not use the Payer Name input often)
       if (!didModalFocus) {
            setTimeout(() => {
                if (paymentAmountRef.current && (currentPaymentAmount === null || currentPaymentAmount === undefined)) {
                    paymentAmountRef.current.focus();
                    setDidModalFocus(true);
                }
            }, 500);
       }

    }, [paymentAmountRef]);

    const handleSubmit = async () => {
        setIsLoading(true);

        const totalDue = parseFloat(((taxTotalEntryFees || totalEntryFees || 0) + (totalEventFees || 0) + (totalStablingCost || 0) - (totalPrizeMoney || 0)).toFixed(2));

        let status = "";
        if (currentPaymentAmount === totalDue) {
            status = "fully paid";
        }
        if (currentPaymentAmount && currentPaymentAmount < totalDue) {
            status = "partially paid";
        }

        const input: CreateEventInvoiceInput = {
            eventId: event.id,
            status: status, 
            statusNote: paymentNotes,
            number: 1,
            entryNumber: entry.number,
            entryId: entry.id,
            payerName: payerName,
            payerType: paymentMethod,
            prizeMoneyAmount: totalPrizeMoney,
            totalDue: totalDue,
            amountToDeductFromInvoice: currentPaymentAmount,
            createdBy: user.id,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
        const createResult = await createEventInvoice(input);
        if (createResult.isSuccess) {
            let updatedEventEntryInvoices = [];
            if (entryInvoices && entryInvoices.length > 0) {
                updatedEventEntryInvoices = [createResult.result].concat(entryInvoices);
            } else {
                updatedEventEntryInvoices = [createResult.result];
            }
            setEntryInvoices(updatedEventEntryInvoices);

            // Not currently using remainingBalance anywhere
            // if (event.id && entry.id) calculateRemainingBalance(event.id, entry.id);

            setPayerName("");
            setPaymentMethod("");
            setPaymentNotes("");
            setCurrentPaymentAmount(0);

            // Check if entry needs to be updated
            if (entry.isCheckedOut !== isCheckedOut) {
                const eventEntryInput: UpdateEventEntryInput = {
                    id: entry.id,
                    isCheckedOut: isCheckedOut
                };
                const updateResult = await updateEventEntry(eventEntryInput);
                if (updateResult.isSuccess) {
                    const updatedEntry: EventEntry = updateResult.result;
                    onUpdateEntry(updatedEntry);
                } else {
                    setError("Could not update the entry's checked out status.");
                }
            }
        } else {
            const message = "Could not create the payment record: " + createResult.message; 
            setError(message);
        }
        setShowModal(false);
        setIsLoading(false);
    }

    const handleSubmitNewCredit = async () => {
        setIsLoading(true);

        const input: CreateEventInvoiceInput = {
            eventId: event.id,
            status: "", 
            statusNote: creditName,
            number: 1,
            entryNumber: entry.number,
            entryId: entry.id,
            payerName: creditName,
            payerType: "Credit",
            prizeMoneyAmount: totalPrizeMoney,
            amountToDeductFromInvoice: creditAmount,
            createdBy: user.id,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
        const createResult = await createEventInvoice(input);
        if (createResult.isSuccess) {
            let updatedEventEntryInvoices = [];
            if (entryInvoices && entryInvoices.length > 0) {
                updatedEventEntryInvoices = [createResult.result].concat(entryInvoices);
            } else {
                updatedEventEntryInvoices = [createResult.result];
            }
            setEntryInvoices(updatedEventEntryInvoices);

            // Not currently using remainingBalance anywhere
            // if (event.id && entry.id) calculateRemainingBalance(event.id, entry.id);

            setCreditName("");
            setCreditNotes("");
            setCreditAmount(0);
        } else {
            const message = "Could not create the credit record: " + createResult.message; 
            setError(message);
        }
        setShowCreditModal(false);
        setIsLoading(false);
    }

    const handleDelete = async (invoice: EventInvoice) => {
        const deleteResult = await deleteEventInvoice({id: invoice.id});
        if (deleteResult) {
            const resultArray: EventInvoice[] = [];
            if (entryInvoices) {
                entryInvoices.forEach(result => {
                    if (result && result.id !== invoice.id) resultArray.push(result);
                });
            }
            setEntryInvoices(resultArray);

            // Not currently using remainingBalance anywhere
            // if (event.id && entry.id) calculateRemainingBalance(event.id, entry.id);
        } else {    
            setError("Could not delete this payment record.");
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <IonRow className="ion-align-items-center ">
                <IonCol sizeXs="12" sizeMd="4">
                    <IonCard className="ion-padding stretch" color="white">
                        <IonCardContent>
                            <EntryCheckInForm entry={entry} onSubmit={(updatedEventEntry: EventEntry) => onUpdateEntry(updatedEventEntry)} />
                        </IonCardContent>
                    </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4">
                    <IonCard className="ion-padding stretch" color="white">
                        <IonCardContent>
                            {isDownloading ?
                                <Spinner />
                                :
                                <>
                                    <IonRow>
                                        <IonCol size="12">
                                            <p className="description link" onClick={printInvoice}>Print Invoice</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12">
                                            <p className="description link" onClick={downloadInvoice}>Download Invoice</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12">
                                            <p className="description link" onClick={() => setShowEmailModal(true)}>Email Invoice</p>
                                        </IonCol>
                                    </IonRow>
                                </>
                            }
                        </IonCardContent>
                    </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4">
                    <IonCard className="ion-padding stretch" color="white">
                        <IonCardContent>
                            <IonRow>
                                <IonCol size="12" className="text-center">
                                    <IonButton color="primary" onClick={handleRecordPaymentClick}>
                                        Record Payment
                                    </IonButton>
                                </IonCol>
                                <IonCol size="12" className="text-center">
                                    <IonButton color="success" onClick={() => setShowCreditModal(true)}>
                                        Add Credit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
            {entryInvoices && (
                <IonRow>
                    <IonCol>
                        <IonCard className="ion-padding" color="white">
                            <IonCardTitle>
                                <h3>Payments</h3>
                            </IonCardTitle>
                            <IonCardContent>
                                <IonList className="bg-white">
                                    {entryInvoices.map((invoice, index) => (
                                        <IonItem key={index}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                                        <p>Payer: {invoice?.payerName}</p>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                                        <p>Amount: ${invoice?.amountToDeductFromInvoice?.toFixed(2)}</p>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                                        <p>Method: {invoice?.payerType}</p>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                                        <IonButton color="danger" onClick={() => handleDelete(invoice)}><IonIcon icon={close}/></IonButton>
                                                    </IonCol>
                                                </IonRow>
                                                {invoice?.statusNote && (
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Notes: {invoice.statusNote}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                )}
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                    {/* <IonItem>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol className="font-weight-bold ion-text-right">
                                                    Remaining Balance: ${remainingBalance.toFixed(2)}
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem> */}
                                </IonList>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            )}
            <IonCard className="ion-padding" color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}> 
                        <IonCol>
                            {entry ? 
                                <>
                                    <IonRow><h3>Total: ${((taxTotalEntryFees || totalEntryFees || 0) + (totalEventFees || 0) + (totalStablingCost || 0) - (totalPrizeMoney || 0)).toFixed(2)}</h3></IonRow>
                                </>
                                : 
                                <h2>Loading ...</h2> 
                            }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {isLoading ?
                            <Spinner />
                            :    
                            <>
                                <h3>Entry Fees: </h3>
                                <p className="description text-info">(displays the "accepted" classes for this entry.)</p>
                                {formattedClassData && (
                                    <IonList className="bg-white">
                                        {formattedClassData.map((formattedOption, index) => (
                                            <>
                                                {formattedOption.division ? 
                                                    <>
                                                        <IonItem key={index}>
                                                            <IonLabel>
                                                                <IonRow>
                                                                    <IonCol className="ion-text-wrap" sizeXs="12" sizeMd="4">
                                                                        <h3>
                                                                            {"Division: " + formattedOption.division.label + " - $" + formattedOption.division.entryFee}
                                                                        </h3>
                                                                    </IonCol>
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        <h3>Quantity: 1</h3>
                                                                    </IonCol>
                                                                    {taxEntryFees && (
                                                                        <IonCol sizeXs="12" sizeMd="4">
                                                                            <h3>
                                                                                Tax: {taxEntryFees}%
                                                                            </h3>
                                                                        </IonCol>
                                                                    )}
                                                                </IonRow>
                                                            </IonLabel>
                                                        </IonItem> 
                                                        {(formattedOption.classes && formattedOption.classes.length > 0) && (
                                                            <>
                                                                {formattedOption.classes.map((classData, i) => (
                                                                    <IonItem key={i}>
                                                                        <IonLabel>
                                                                            <IonRow>
                                                                                <IonCol className="ion-text-wrap" sizeXs="12" offsetMd="4" sizeMd="4">
                                                                                    <h3>
                                                                                        {"Class: " + classData.label}
                                                                                    </h3>
                                                                                </IonCol>
                                                                            </IonRow>
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                ))}
                                                            </>
                                                        )}
                                                    </>
                                                    : 
                                                    <>
                                                        <IonItem key={index}>
                                                            <IonLabel>
                                                                <IonRow>
                                                                    <IonCol className="ion-text-wrap" sizeXs="12" sizeMd="4">
                                                                        <h3>
                                                                            {"Class: " + (formattedOption.class?.number ? + formattedOption.class?.number + " - " : "") + formattedOption.class?.label + " - $" + formattedOption.class?.entryFee}
                                                                        </h3>
                                                                    </IonCol>
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        <h3>Quantity: 1</h3>
                                                                    </IonCol>
                                                                    {taxEntryFees && (
                                                                        <IonCol sizeXs="12" sizeMd="4">
                                                                            <h3>
                                                                                Tax: {taxEntryFees}%
                                                                            </h3>
                                                                        </IonCol>
                                                                    )}
                                                                </IonRow>
                                                            </IonLabel>
                                                        </IonItem>
                                                    </>
                                                }
                                            </>
                                        ))}
                                        
                                        <IonItem>
                                                <IonLabel>
                                                    <p className="text-dark font-weight-bold text-right">
                                                        Total entry fees: ${taxTotalEntryFees?.toFixed(2) || totalEntryFees?.toFixed(2)}
                                                    </p>
                                                </IonLabel>
                                        </IonItem>
                                    </IonList>
                                )}
                                <h3>Other Fees</h3>
                                <IonList className="bg-white">
                                    {previousFormattedEventEntryFees && (
                                        <>
                                            {previousFormattedEventEntryFees.map((formattedEventFee, index) => (
                                                <IonItem key={index}>
                                                    <IonLabel>
                                                        <IonRow>
                                                            <IonCol className="ion-text-wrap" sizeXs="12" sizeMd="4">
                                                                <h3>Name: {formattedEventFee.name} - ${formattedEventFee.amount.toFixed(2)}</h3>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                <h3>Quantity: {formattedEventFee.quantity}</h3>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                <h3>Tax: {formattedEventFee.tax ? formattedEventFee.tax : "n/a"}</h3>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonLabel>
                                                </IonItem>
                                            ))}
                                        </>
                                    )}
                                    <IonItem key="total">
                                        <IonLabel color="dark">
                                            <h3 className="font-weight-bold text-right">
                                                Total Other Fees: {totalEventFees ? "$" + totalEventFees.toFixed(2) : ""}
                                            </h3>
                                        </IonLabel>
                                    </IonItem>
                                </IonList>
                                <h3>Stabling Fees</h3>
                                {formattedStablingRequests && (
                                    <IonList className="bg-white">
                                        {formattedStablingRequests.map((sr, index) => (
                                            <IonItem key={index}>
                                                <IonLabel>
                                                    <IonRow>
                                                        <IonCol className="ion-text-wrap" sizeXs="12" sizeMd="4">
                                                            <h3>{sr ? sr.type : "Price Per Unit"}: ${sr.price}</h3>
                                                        </IonCol>
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <h3>Quantity: {sr.quantity}</h3>
                                                        </IonCol>
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <h3>Tax: {sr.tax}%</h3>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        {(sr.entries && sr.entries?.length > 1) && (
                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                <h3>Split Cost: ${sr.splitCost}</h3>
                                                            </IonCol>
                                                        )}
                                                    </IonRow>
                                                </IonLabel>
                                            </IonItem>
                                        ))}
                                    </IonList>
                                )}
                                {beddingRequests && (
                                    <IonList className="bg-white">
                                        {beddingRequests.map((br, index) => (
                                            <IonItem key={index}>
                                                <IonLabel>
                                                    <IonRow>
                                                        <IonCol className="ion-text-wrap" sizeXs="12" sizeMd="4">
                                                            <h3>{br ? "Shavings" : "Price Per Unit"}: ${br.basePrice}</h3>
                                                        </IonCol>
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <h3>Quantity: {br.quantityNeeded}</h3>
                                                        </IonCol>
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <h3>Tax: {br.taxA || 0}%</h3>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonLabel>
                                            </IonItem>
                                        ))}
                                    </IonList>
                                )}
                                <IonList className="bg-white">
                                    <IonItem>
                                        <IonLabel>
                                            <p className="text-dark font-weight-bold text-right">
                                                Total stabling cost: ${totalStablingCost?.toFixed(2)}
                                            </p>
                                        </IonLabel>
                                    </IonItem>
                                </IonList>
                                {eventResults && (
                                    <>
                                        <h3>Results</h3>
                                        <IonList className="bg-white">
                                            {eventResults.map((result, index) => (
                                                <IonItem key={index}>
                                                    <IonLabel>
                                                        <IonRow>
                                                            {result.eventDivision?.id && (
                                                                <IonCol>
                                                                    <h3>Division: {result.eventDivisionName}</h3>
                                                                </IonCol>
                                                            )}
                                                            {result.eventClass?.id && (
                                                                <IonCol>
                                                                    <h3>Class: {result.eventClass?.number} - {result.eventClass?.name}</h3>
                                                                </IonCol>
                                                            )}
                                                            <IonCol>
                                                                <h3>Place: {result.place}</h3>
                                                            </IonCol>
                                                            <IonCol>
                                                                <h3>Prize Money: {result.prizeMoney ? "$" + result.prizeMoney.toFixed(2) : ""}</h3>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonLabel>
                                                </IonItem>
                                            ))}
                                            <IonItem>
                                                <IonLabel color="dark">
                                                    <p className="text-dark font-weight-bold text-right">
                                                        Total Prize Money: {totalPrizeMoney? "($" + totalPrizeMoney?.toFixed(2) + ")" : "0.00"}
                                                    </p>
                                                </IonLabel>
                                            </IonItem>
                                        </IonList>
                                    </>
                                )}
                                <hr/>
                                <IonRow>
                                    <IonCol>
                                        <h3 className="text-dark font-weight-bold text-right">Total: ${((taxTotalEntryFees || totalEntryFees || 0) + (totalEventFees || 0) + (totalStablingCost || 0) - (totalPrizeMoney || 0)).toFixed(2)}</h3>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                    </IonCardContent>
                )}
            </IonCard>
            <IonModal backdropDismiss={false} isOpen={showModal} id="recordPaymentModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Record Payment
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="recordPaymentModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {entry && (
                        <>
                            {isLoading  ?
                                <Spinner />
                                :
                                <>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="10">
                                            <IonLabel>Payer Name</IonLabel>
                                            <Input
                                                type="text"
                                                value={payerName}
                                                onChange={(e: any) => {
                                                    setPayerName(e.target.value);
                                                }}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="10">
                                            <IonLabel position="floating">Amount</IonLabel>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>$</InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="number"
                                                    innerRef={paymentAmountRef}
                                                    value={currentPaymentAmount || undefined}
                                                    min={0}
                                                    onChange={e => {
                                                        setCurrentPaymentAmount(parseFloat(e.target.value));
                                                    }}
                                                />
                                            </InputGroup>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="10">
                                            <IonLabel>Payment Method</IonLabel>
                                            <Input 
                                                name="paymentMethod" 
                                                type="select" 
                                                value={paymentMethod} 
                                                onChange={e => {
                                                    setPaymentMethod(e.target.value);
                                                }}
                                            >
                                                <option>-</option>
                                                <option>Check</option>
                                                <option>Cash</option>
                                                <option>Credit Card</option>
                                                <option>Debit Card</option>
                                                <option>E-Transfer</option>
                                                <option>Member Credits</option>
                                            </Input>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="10">
                                            <IonLabel>Notes</IonLabel>
                                            <Input 
                                                name="notes" 
                                                type="textarea" 
                                                value={paymentNotes} 
                                                onChange={e => {
                                                    setPaymentNotes(e.target.value);
                                                }}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="10">
                                            <IonLabel position="stacked">Checked Out?</IonLabel>
                                            <IonItem color="white">
                                                <IonToggle color="success" checked={isCheckedOut} onIonChange={e => setIsCheckedOut(e.detail.checked)} />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center ion-padding-top">
                                        <IonCol size="10" className="ion-text-center">
                                            <IonButton expand="block" onClick={handleSubmit}>
                                                Save
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </>
                            }
                        </>
                    )}
                </IonContent>
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showCreditModal} id="recordCreditModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Record Credit
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowCreditModal(false)}
                        >
                            <p id="recordCreditModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10">
                            <IonLabel>Credit Name</IonLabel>
                            <Input
                                type="text"
                                value={creditName}
                                onChange={(e: any) => {
                                    setCreditName(e.target.value);
                                }}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10">
                            <IonLabel>Amount</IonLabel>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type="number"
                                    value={creditAmount}
                                    min={0}
                                    onChange={e => {
                                        setCreditAmount(parseFloat(e.target.value));
                                    }}
                                />
                            </InputGroup>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10">
                            <IonLabel>Notes</IonLabel>
                            <Input 
                                name="notes" 
                                type="textarea" 
                                value={creditNotes} 
                                onChange={e => {
                                    setCreditNotes(e.target.value);
                                }}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-padding-top">
                        <IonCol size="10" className="ion-text-center">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton expand="block" onClick={handleSubmitNewCredit}>
                                    Save
                                </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showEmailModal} id="emailModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Email Invoice
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowEmailModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10">
                            <IonLabel>Email Address</IonLabel>
                            <Input
                                type="text"
                                value={emailAddress}
                                onChange={(e: any) => {
                                    setEmailAddress(e.target.value);
                                }}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-padding-top">
                        <IonCol size="10" className="ion-text-center">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton expand="block" onClick={emailInvoice}>
                                    Send
                                </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        </>
    );
};

export default ViewEntryCurrentInvoice;