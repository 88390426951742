import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonIcon,
    IonRow
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import { EventClass, EventClassEntry, EventEntry } from "../../models";
import { createEventClassEntry, getEventClassEntriesByEventClassId } from "../../utilities/eventClassEntry/EventClassEntry";
import { Input, Label } from "reactstrap";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { getEventEntriesByEventId } from "../../utilities/eventEntry/EventEntry";
import ErrorAlert from "../Errors/ErrorAlert";
import { checkmarkCircleOutline, closeOutline } from "ionicons/icons";
import BasicTooltip from "../Tooltip/BasicTooltip";
import { CreateEventClassEntryInput, EntryOption, UpdateEventClassInput } from "../../API";
import { updateEventClass } from "../../utilities/eventClass/EventClass";
import { sortEventClassEntriesByNumber } from "../../utilities/eventClassEntry/SortEventClassEntries";

interface _Props {
    eventClass: EventClass
    onUpdateEventClass: Function
}

const VerifyEntriesTable: React.FC<_Props> = ({eventClass, onUpdateEventClass}) => {
    const user = useContext(PersonContext);

    const [eventEntries, setEventEntries] = useState<EventEntry[] | undefined>();
    const [eventClassEntries, setEventClassEntries] = useState<EventClassEntry[] | undefined>();

    const [totalNumberCompeted, setTotalNumberCompeted] = useState(0);
    const [currentEntryNumber, setCurrentEntryNumber] = useState("");

    const [verifiedEntries, setVerifiedEntries] = useState<EventClassEntry[] | undefined | null>();
    const [missingEntries, setMissingEntries] = useState<EventEntry[] | undefined | null>(); //Typed into the box, but was not entered in the class
    const [unjudgedEntries, setUnjudgedEntries] = useState<EventClassEntry[] | undefined | null>(); //Was entered in the class, but was never typed into the box

    const [error, setError] = useState<string | null | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isHandlingEntry, setIsHandlingEntry] = useState(false);

    async function getEventClassEntries(eventClassId: string) {
        const queryResult = await getEventClassEntriesByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            const eventClassEntries: EventClassEntry[] = queryResult.result;
            const sorted = sortEventClassEntriesByNumber(eventClassEntries);
            setEventClassEntries(sorted || eventClassEntries);
            setUnjudgedEntries(sorted || eventClassEntries);
            return eventClassEntries;
        } else {
            setError("Sorry, a problem occurred. No event class entries found. Please go back and try again.");
        }
    }

    async function getEventEntries(eventId: string) {
        const queryResult = await getEventEntriesByEventId(eventId);
        if (queryResult.isSuccess) {
            const eventEntries = queryResult.result;
            setEventEntries(eventEntries);
            return eventEntries;
        } else {
            setError("Sorry, a problem occurred. No event class entries found. Please go back and try again.");
        }
    }

    const getData = async (eventClass: EventClass) => {
        if (!eventClassEntries) await getEventClassEntries(eventClass.id);
        await getEventEntries(eventClass.eventId);
        setIsLoading(false);
    }

    useEffect(() => {
        if (eventClass) {
            getData(eventClass);
        }
    }, [eventClass]);

    const onKeyPress = (target: any) => {
        // Check for the "Enter" key
        if (target.charCode==13) {
            handleSave();
        }
    }

    const handleVerifiedEventClassEntry = (found: EventClassEntry, backNumber: string) => {
        // Add an entry in the competed count
        setTotalNumberCompeted((prevValue) => prevValue + 1);

        // Add the entry to the list of verified entries
        if (verifiedEntries) {
            const updatedVerifiedEntries = verifiedEntries.concat([found]);
            setVerifiedEntries(() => updatedVerifiedEntries);
        } else {
            setVerifiedEntries(() => [found]);
        }

        // If this entry number has been verified, remove it from the list of unjudged entries
        if (unjudgedEntries) {
            let updatedEntryArray = [];
            for (let i = 0; i < unjudgedEntries.length; i++) {
                const current = unjudgedEntries[i];
                if (current.eventEntry?.number && current.eventEntry.number.toString() !== backNumber) updatedEntryArray.push(current);
            }
            setUnjudgedEntries(updatedEntryArray);
        }
    }

    const checkIsEventClassEntry = (backNumber: string) => {
        if (eventClassEntries) {
            const found = eventClassEntries.find(ece => (ece.eventEntry?.number ? ece.eventEntry?.number.toString() : "") === backNumber);
            if (found) {
                // Handle updating the lists 
                handleVerifiedEventClassEntry(found, backNumber);

                // Return true to signify that the entry number is verified
                return true;
            } else {
                return false;
            }
        } else {
            setError("No entries were found in this class.");
        }
    }

    const checkIsEventEntry = (backNumber: string) => {
        if (eventEntries) {
            const found = eventEntries.find(ee => (ee.number ? ee.number.toString() : "") === backNumber);
            if (found) {
                if (missingEntries) {
                    // Add the EventEntry to the list of Not Entered - user can choose to add the entry to the class
                    const updatedMissingEntries = missingEntries.concat([found]);
                    setMissingEntries(updatedMissingEntries);
                } else {
                    setMissingEntries([found]);
                }
                return true;
            } else {
                return false;
            }
        } else {
            setError("No entries were found in this class.");
        }
    }

    const handleSave = async () => {
        setError(undefined);
        const isVerified = checkIsEventClassEntry(currentEntryNumber);
        if (isVerified) {
            setCurrentEntryNumber("");
        } else {
            const isEventEntry = checkIsEventEntry(currentEntryNumber);
            if (isEventEntry) {
                setCurrentEntryNumber("");
            } else {
                setError("This back number does not correspond to anyone at this event. Please double check the number.");
            }
        }
    }

    const handleAddNotEnteredNumber = async (eventEntry: EventEntry) => {
        setError(undefined);
        setIsHandlingEntry(true);

        //First, specify if this new event entry should be charged by entering the whole division or just entering the class
        let selectedEntryOption = EntryOption.classOnly;
        const optionsToEnterClass = eventClass.entryOption;
        if (optionsToEnterClass) {
            if (optionsToEnterClass === "DIVISION_ONLY") selectedEntryOption = EntryOption.divisionOnly;
        } else {
            const classIsPartOfDivision = !!(eventClass.eventDivision);
            if (classIsPartOfDivision) {
                const divisionFee = eventClass.eventDivision?.entryFee;
                const classFee = eventClass.entryFee;
                if (divisionFee && !classFee) selectedEntryOption = EntryOption.divisionOnly;
            }
        }

        //Next, create the new EventClassEntry
        const createEventClassEntryInput: CreateEventClassEntryInput = {
            eventId: eventEntry.eventId,
            eventEntryId: eventEntry.id,
            // eventClassEntryEventEntryId: eventEntry.id,
            eventClassId: eventClass.id,
            // eventClassEntryEventClassId: eventClass.id,
            riderId: eventEntry.riderId,
            // eventClassEntryRiderId: eventEntry.riderId,
            status: "accepted",
            selectedEntryOption: selectedEntryOption,
            createdBy: user.id,
            lastUpdatedBy: user.id
        }
        const createResult = await createEventClassEntry(createEventClassEntryInput);
        if (createResult.isSuccess) {
            const newEventClassEntry: EventClassEntry = createResult.result;

            // Next, handle updating the lists 
            if (eventClassEntries) {
                const updatedEventClassEntries = eventClassEntries.concat([newEventClassEntry]);
                await setEventClassEntries(() => updatedEventClassEntries);
            } else {
                await setEventClassEntries(() => [newEventClassEntry]);
            }
            handleRemoveNotEnteredNumber(eventEntry);
            handleVerifiedEventClassEntry(newEventClassEntry, (eventEntry.number ? eventEntry.number.toString() : ""))
        } else {
            setError("Sorry, we could not add this entry to this class. Please contact us for support: hello@ringsidepro.com");
        }
        setIsHandlingEntry(false);
    }

    const handleRemoveNotEnteredNumber = (eventEntry: EventEntry) => {
        setError(undefined);
        if (missingEntries) {
            const filteredArray = missingEntries.filter(entry => entry.id !== eventEntry.id);
            setMissingEntries(filteredArray);
        } else {
            setError("No entries found in the Not Entered category.")
        }
    }

    const handleDone = async () => {
        setError(undefined);
        let updateInput: UpdateEventClassInput = {
            id: eventClass.id,
            completedVerifyEntries: true
        };
        if (verifiedEntries && verifiedEntries.length > 0) {
            updateInput["numberEntriesThatCompeted"] = verifiedEntries?.length;
        }
        const updateResult = await updateEventClass(updateInput);
        if (updateResult.isSuccess) {
            const updatedEventClass: EventClass = updateResult.result;

            // send the parent component the updated class with the boolean for verification marked to true
            onUpdateEventClass(updatedEventClass);
        } else {
            setError("Could not update this class.");
        }
    }

    return (
       <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {error ? 
                        <ErrorAlert error={error} width="12"/>
                        :
                        <IonRow className="mt-2"></IonRow>
                    }
                    {isHandlingEntry ? 
                        <Spinner />
                        :
                        <IonRow>
                            {eventClass.completedVerifyEntries && (
                                <IonCol size="12">
                                    <p className="font-weight-bold ion-text-wrap">Someone has already verified these entries. You can verify them again if you'd like to double check they are correct.</p>
                                </IonCol>
                            )}
                            <IonCol sizeXs="8" sizeMd="2">
                                <Label><BasicTooltip label="Entry Number" tip="Enter a back number, then press the ENTER key or press Next." /></Label>
                                <Input 
                                    className="small-input"
                                    type="text"
                                    value={currentEntryNumber}
                                    onKeyPressCapture={(e) => onKeyPress(e)}
                                    onChange={e => {
                                        setCurrentEntryNumber(e.target.value);
                                    }}
                                />
                                <IonButton color="tertiary" expand="full" onClick={handleSave}>Next</IonButton>
                                <IonButton color="success" expand="full" className="mt-5" onClick={handleDone}><IonIcon icon={checkmarkCircleOutline} /> Done</IonButton>
                            </IonCol>
                            <IonCol sizeXs="12" offsetMd="2" sizeMd="4">
                                <IonCard className="stretch">
                                    <IonCardContent>
                                        <h2>Entered ({(eventClassEntries && eventClassEntries.length > 0) ? eventClassEntries.length : "0"})</h2>
                                        {(eventClassEntries && eventClassEntries.length > 0) ? 
                                            <>
                                                {eventClassEntries.map((entry, index) => (
                                                    <p key={index}>{entry.eventEntry?.number}</p>
                                                ))}
                                            </>
                                            :
                                            <>
                                                <p>None found</p>
                                            </>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <IonCard className="stretch">
                                    <IonCardContent>
                                        <h2>Not Entered</h2>
                                        {(missingEntries && missingEntries.length > 0) ? 
                                            <>
                                                {missingEntries.map((entry, index) => (
                                                    <>
                                                        <div key={index}>
                                                            <span className="text-dark">{entry.number}</span>
                                                            - 
                                                            <IonButton size="small" color="success" onClick={() => handleAddNotEnteredNumber(entry)}>Add</IonButton>
                                                            <IonButton size="small" color="danger" onClick={() => handleRemoveNotEnteredNumber(entry)}><IonIcon icon={closeOutline}/></IonButton>
                                                        </div>
                                                    </>
                                                ))}
                                            </>
                                            :
                                            <>
                                                <p>None found</p>
                                            </>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeXs="12" offsetMd="4" sizeMd="4">
                                <IonCard className="stretch">
                                    <IonCardContent>
                                        <h2>Judged ({(verifiedEntries && verifiedEntries.length > 0) ? verifiedEntries.length : "0"})</h2>
                                        {(verifiedEntries && verifiedEntries.length > 0) ? 
                                            <>
                                                {verifiedEntries.map((entry, index) => (
                                                    <p key={index}>{entry.eventEntry?.number}</p>
                                                ))}
                                            </>
                                            :
                                            <>
                                                <p>None found</p>
                                            </>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <IonCard className="stretch">
                                    <IonCardContent>
                                        <h2><BasicTooltip label="Not Judged" tip="Any entries that you have NOT verified yet. Will help you check if an entry was NOT listed on a judge's card." /></h2>
                                        {(unjudgedEntries && unjudgedEntries.length > 0) ? 
                                            <>
                                                {unjudgedEntries.map((entry, index) => (
                                                    <p key={index}>{entry.eventEntry?.number}</p>
                                                ))}
                                            </>
                                            :
                                            <>
                                                <p>None found</p>
                                            </>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    }
                </>
            }
       </>
    );
};

export default VerifyEntriesTable;
