import React, { useContext, useEffect, useState } from "react";
import {
    IonCard,
    IonCol,
    IonRow,
    IonRadio,
    IonRadioGroup,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonChip
} from "@ionic/react";
import { Barn, BarnMember, Contact, Event } from "../../../models";
import { trash } from "ionicons/icons";
import Spinner from "../../Spinners/Spinner";
import SelectBarnFromAllBarns from "../../Barn/SelectBarnFromAllBarns";
import { formatPhoneNumberForDisplay } from "../../../utilities/contact/FormatPhoneNumber";
import { PersonContext } from "../../../context/PersonContext";
import { getBarnMembersByPersonId } from "../../../utilities/barnMember/BarnMember";
import { getAddressById } from "../../../utilities/address/Address";
import { getContactById } from "../../../utilities/contact/Contact";
import { formatAddress } from "../../../utilities/address/FormatAddress";

interface SelectBarnSectionProps {
    event: Event;
}

interface formattedBarnInfo {
    id: string;
    barn: Barn;
    addressString: string;
    contactString: string;
    websiteString?: string;
}

const SelectBarnSection: React.FC<SelectBarnSectionProps> = () => {
    const user = useContext(PersonContext);
    const [selectedBarn, setSelectedBarn] = useState<Barn | null | undefined>(null);
    const [barns, setBarns] = useState<Barn[]>([]);
    const [formattedBarns, setFormattedBarns] = useState<formattedBarnInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [newlySelectedBarns, setNewlySelectedBarns] = useState<string[]>([]);
    const [initialBarns, setInitialBarns] = useState<string[]>([]);

    useEffect(() => {
        if (user) fetchBarnMemberships();
    }, [user, user.id]);

    const fetchBarnMemberships = async () => {
        setIsLoading(true);
        try {
            const barnMembershipsResult = await getBarnMembersByPersonId(user.id);
            if (barnMembershipsResult.isSuccess) {
                const barnMembers: BarnMember[] = barnMembershipsResult.result;
                const barns = barnMembers.map(barnMember => barnMember.barn);
                barns.sort((a, b) => a.name.localeCompare(b.name));
                setBarns(barns);

                const formattedBarnsPromises = barns.map(async (barn) => await formatBarn(barn));
                const formattedBarns = await Promise.all(formattedBarnsPromises);

                setFormattedBarns(formattedBarns);
                setSelectedBarn(barns[0] || null);

                // Track initial barns
                const initialBarnIds = barns.map(barn => barn.id);
                setInitialBarns(initialBarnIds);
            }
        } catch (error) {
            console.error("Error fetching barn members:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatBarn = async (barn: Barn): Promise<formattedBarnInfo> => {
        let addressString = "Address not provided",
            contactString = "Contact not provided", 
            websiteString = "Website not provided";

        if (barn.barnLocationId) {
            const addressResult = await getAddressById(barn.barnLocationId);
            if (addressResult.isSuccess) {
                const address = addressResult.result;
                addressString = formatAddress(address);
            }
        }

        if (barn.barnContactId) {
            const contactResult = await getContactById(barn.barnContactId);
            if (contactResult.isSuccess) {
                const contact: Contact = contactResult.result;
                if (contact.work) contactString = formatPhoneNumberForDisplay(contact.work);
                if (contact.website) websiteString = contact.website;
            }
        }

        return {
            id: barn.id,
            barn,
            addressString,
            contactString,
            websiteString
        };
    };

    const handleBarnSelection = async (barn: Barn | undefined) => {
        try {
            setSelectedBarn(barn || null);

            if (barn) {
                const isBarnAlreadyInList = formattedBarns.some(b => b.barn.id === barn.id);
                if (!isBarnAlreadyInList) {
                    const formattedBarn = await formatBarn(barn);
                    setFormattedBarns(prevBarns => [formattedBarn, ...prevBarns]);  
                }

                // Check if barn is newly selected
                if (!newlySelectedBarns.includes(barn.id)) {
                    setNewlySelectedBarns(prevSelected => [...prevSelected, barn.id]);
                }
            }
        } catch (error) {
            console.error("Error handling barn selection:", error);
        }
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <IonRow className="info-section-boarder-rd">
                <h1 className="info-card-descriptor-rd">Select a Barn</h1>
                <IonCol>                
                    <SelectBarnFromAllBarns 
                        barn={selectedBarn}
                        onSelect={handleBarnSelection}
                        placeholder="Search for a barn..."
                    />
                </IonCol>
                <IonRow className="barns-row-rd">
                    {formattedBarns && formattedBarns.length > 0 ? (
                        <IonRadioGroup className="barn-radio-group-rd" value={selectedBarn ? selectedBarn.id : formattedBarns[0]?.id}>
                            {formattedBarns.map((barnInfo) => (
                                <IonCard key={barnInfo.id} color="white" className="barn-card-container-rd">
                                    <IonRow className="barn-container-row-rd">
                                        <IonCol size="auto" className="barn-left-col-rd">
                                            <IonRadio
                                                className="barn-radio-button-rd"
                                                value={barnInfo.id}
                                                onClick={() => setSelectedBarn(barnInfo.barn)}
                                            />
                                        </IonCol>
                                        <IonCol className="barn-middle-col-rd">
                                            <IonCardHeader className="barn-card-header-rd">
                                                <IonCardSubtitle className="barn-card-subtitle-rd">{barnInfo.addressString}</IonCardSubtitle>
                                                <IonCardTitle className="barn-card-title-rd">{barnInfo.barn.name}</IonCardTitle>
                                            </IonCardHeader>
                                            <IonCardContent className="barn-card-content-rd">
                                                <IonRow className="barn-card-rows-rd">
                                                    <div className="barn-card-text-container-rd">
                                                        <p className="barn-card-text-rd">
                                                            {barnInfo.contactString}
                                                        </p>
                                                        {barnInfo.websiteString !== "Website not provided" ? (
                                                            <a className="barn-card-text-rd" href={barnInfo.websiteString} target="_blank" rel="noopener noreferrer">
                                                                {barnInfo.websiteString}
                                                            </a>
                                                        ) : (
                                                            <p className="barn-card-text-rd">Website not provided</p>
                                                        )}
                                                    </div>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCol>
                                        <IonCol size="auto" className="barn-right-col-rd">
                                            {newlySelectedBarns.includes(barnInfo.id) ? (
                                                <IonChip color="secondary">+ Become a Member</IonChip>
                                            ) : initialBarns.includes(barnInfo.id) ? (
                                                <IonChip color="primary">Member</IonChip>
                                            ) : (
                                                <IonChip color="primary">Member</IonChip>
                                            )}
                                            <IonIcon className="trash-icon-rd" icon={trash} />
                                        </IonCol>
                                    </IonRow>
                                </IonCard>
                            ))}
                        </IonRadioGroup>
                    ) : (
                        <IonRow className="center-text-row-rd">
                            <p className="warning-message-rd">Use the Search Bar above to find a Barn.</p>
                        </IonRow>
                    )}
                </IonRow>
            </IonRow>
        </>
    );
};

export default SelectBarnSection;
