import React, { useContext, useEffect, useState } from "react";
import { Organization } from "../../models";
import CONSTANT from "../../constant/constant";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import CheckoutForm from "./CheckoutForm";
import { createTransferPaymentIntent } from "../../utilities/stripe/CreateTransfer";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { Stripe } from "@stripe/stripe-js";

interface _Props {
    organization: Organization
    price: number
    returnURL: string
    handleSubmit: Function
}

const StripeTransferPaymentForm: React.FC<_Props> = ({returnURL, price, organization, handleSubmit}) => {
    const user = useContext(PersonContext);
    
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
    const [clientSecret, setClientSecret] = useState("");
    const [error, setError] = useState("");

    const fetchStripeObject = async (accountId: string) => {
        // If there is no accountId, do not run the loadStripe function.
        if (accountId) {
            // This is the test publishable API key.
            // Pass the stripeAccount so the paymentIntent will belong to this Stripe Account
            const result = loadStripe(
                "pk_live_EJGhy2cBbcqIpfN95FhieHL5006DOpYcQi",
                {
                    stripeAccount: accountId
                }
            );
            // When we have got the Stripe object, pass it into our useState.
            if (result) {
                setStripePromise(result);
                setUpPaymentIntent();
            }
        }
    };

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads.
        // Avoid recreating the Stripe object on every render.
        if (organization.stripeId) fetchStripeObject(organization.stripeId);
    }, [organization]);

    async function setUpPaymentIntent() {
        if (organization.stripeId) {
            const description = "Created during Membership Application payment step for: " + organization.name;
            const apiResult = await createTransferPaymentIntent(
                organization.stripeId, 
                price, 
                organization.id === "e05fc919-2793-4ead-acef-3f6efb584b67" ? 3.39 : CONSTANT.MEMBERSHIP.ONLINE_PAYMENT_FEE,
                user.email, 
                description
            );
            if (apiResult.isSuccess) {
                setClientSecret(apiResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again or contact hello@ringsidepro.com");
            }
        } else {
            setError("Sorry, this organization cannot accept online payments.");
        }
    }

    const options = {
        // pass the client secret from the paymentIntent step
        clientSecret: clientSecret
    };

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {organization.stripeId ? 
                <>
                    {clientSecret ? 
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm returnURL={returnURL} handleSubmit={handleSubmit} />
                        </Elements>
                        :
                        <div className="ion-text-center">
                            <Spinner />
                        </div>
                    }
                </>
                :
                <p>This organization cannot accept payments.</p>
            }
        </>
    );
};

export default StripeTransferPaymentForm; 