import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import "./theme/variables.css";
import "./theme/theme.css";
import "./theme/alerts.css";
import "./theme/avatar.css";
import "./theme/badge.css";
import "./theme/buttons.css";
import "./theme/breadcrumbs.css";
import "./theme/cards.css";
import "./theme/carousel.css";
import "./theme/checklist.css";
import "./theme/datepicker.css";
import "./theme/footer.css";
import "./theme/forms.css";
import "./theme/heading.css";
import "./theme/icons.css";
import "./theme/images.css";
import "./theme/jumbotron.css";
import "./theme/layout.css";
import "./theme/lists.css";
import "./theme/main.css";
import "./theme/modals.css";
import "./theme/nav.css";
import "./theme/pagination.css";
import "./theme/phoneInput.css";
import "./theme/popover.css";
import "./theme/profile-page.css";
import "./theme/progress.css";
import "./theme/reactdatetime.css";
import "./theme/section.css";
import "./theme/select2.css";
import "./theme/separator.css";
import "./theme/spinner.css";
import "./theme/stripe.css";
import "./theme/tables.css";
import "./theme/tooltip.css";
import "./theme/video.css";

// Stripe
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Amplify from "aws-amplify";
import UnauthLayout from './layouts/UnauthLayout';
import awsmobile from "./aws-exports";
Amplify.configure(awsmobile);
// Amplify.Logger.LOG_LEVEL = 'DEBUG';


// const stripePromise = loadStripe("pk_test_sXlren9AWlEtWHVJwhKW6LbK00Q06Uoo4o");
const stripePromise = loadStripe("pk_live_EJGhy2cBbcqIpfN95FhieHL5006DOpYcQi");

const App: React.FC = () => {
  return (
    <IonApp>
        <Elements stripe={stripePromise}>
          <IonReactRouter>
              <UnauthLayout />
          </IonReactRouter>
        </Elements>
    </IonApp>
  );
};

export default App;
