import React from "react";
import {
    IonCol,
    IonRow,
    IonCard,
    IonCardTitle,
    IonButton,
    IonCardSubtitle,
    IonCardHeader,
    IonCardContent,
    IonIcon
} from "@ionic/react";
import { chevronUp, trash } from "ionicons/icons";
import "../../theme/cards.css";
import EventInfoSection from "../Entry/EntryForm/EventInfoSection";
import { Event } from "../../models";
import { ExpandedCardStates } from "../../pages/dashboard/events/EventNewEntryPage";
import EntryCardChip, { ChipTitleOption } from "../Chips/EntryCardChip";
import EntryCard from "../Entry/EntryForm/EntryCard";

interface EntrySectionCardProps {
    event: Event;
    miniTitle?: string;
    title: string;
    subtitle: string;
    chipTitle: ChipTitleOption;
    trashIcon: boolean;
    expandButtonTitle: string;
    isExpand: boolean;
    cardContent: ExpandedCardStates;
    toggleExpansion: (isExpand: boolean) => void;
    onSubmit?: () => void;
}

const EntrySectionCard: React.FC<EntrySectionCardProps> = ({
    event,
    miniTitle = "",
    title,
    subtitle,
    chipTitle,
    trashIcon,
    expandButtonTitle,
    isExpand,
    cardContent,
    toggleExpansion,
    onSubmit
}) => {
    return (
        <IonRow className="ion-justify-content-center center-stacked-rows">
            <IonCol size="11" className="ion-padding-left">
                <IonCard color="white" mode="md" className="card-component-rd">
                    <IonCardHeader className="card-header-rd">
                        <IonRow className="ion-align-items-center ">
                            <IonCol size="8" className="left-col-rd">
                                {miniTitle && (
                                    <IonCardSubtitle className="mini-title-rd">{miniTitle}</IonCardSubtitle>
                                )}
                                <IonCardTitle className="card-title-rd">{title}</IonCardTitle>
                                <IonCardSubtitle className="card-subtitle-rd">{subtitle}</IonCardSubtitle>
                                <EntryCardChip chipTitle={chipTitle}>{chipTitle}</EntryCardChip>
                            </IonCol>
                            <IonCol size="4" className="ion-text-right right-col-rd">
                                <IonRow className="ion-align-items-center ion-justify-content-end">
                                    <IonCol className="button-col-rd">
                                        {isExpand ? (
                                            <IonIcon className="collapse-icon-rd" icon={chevronUp} onClick={() => toggleExpansion(false)} />
                                        ) : (
                                            <IonButton expand="block" className="card-start-button-rd" color="tertiary" onClick={() => toggleExpansion(true)}>
                                                {expandButtonTitle}
                                            </IonButton>
                                        )}
                                    </IonCol>
                                    
                                    <IonCol className={`icon-col-rd ${!trashIcon ? 'hidden' : ''}`}>
                                        {trashIcon && (
                                            <IonIcon className="trash-icon-rd" icon={trash} />
                                        )}
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>

                    {isExpand && (
                        <IonCardContent className="card-content-rd">
                            <IonRow>
                                {cardContent === "header" && (
                                    <EventInfoSection event={event} />
                                )}
                                {cardContent === "entry" && (
                                    <EntryCard event={event} />
                                )}
                                {/* Add more card content conditions as needed */}
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonButton className="card-complete-button-rd" onClick={onSubmit}>
                                    Continue
                                </IonButton>
                            </IonRow>
                        </IonCardContent>
                    )}
                </IonCard>
            </IonCol>
        </IonRow>
    );
};

export default EntrySectionCard;
