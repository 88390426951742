import {
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    ListGroup,
    ListGroupItem,
    Row
} from "reactstrap";
import {Link} from "react-router-dom";
import React, { useState } from "react";
import { IonContent, IonHeader, IonPage } from "@ionic/react";
import WebNavbar from "../../components/Navbars/WebNavbar";
import Footer from "../../components/Footers/Footer";

const SampleFeedbackPage: React.FC = () => {
    
    const [openedCollapses, setOpenedCollapses] = useState<string[]>(["collapseOne"]);

    const collapsesToggle = (collapse: string) => {
        if (openedCollapses.includes(collapse)) {
            setOpenedCollapses([]);
        } else {
            setOpenedCollapses([collapse]);
        }
    };

    return (
        <IonPage id="webAboutClinicsPage">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                <section className="mb-6 pt-3 pb-6 bg-default">
                    <Container fluid>
                        <Row className="justify-content-center text-center">
                            <Col md="6">
                                <div className="justify-content-center text-center">
                                    <h2 className="py-3 main-title text-white">
                                        <strong><span className="text-white">Sample Feedback</span></strong>
                                    </h2>
                                    <p className="lead text-white">
                                        Want to see what types of feedback you'll receive? Here is just one example!
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section section-lg pt-lg-0 mt--7">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <div>
                                            <video controls width="100%" src={"https://ringsidepro-basic-video.s3.amazonaws.com/ANRC.mp4"} autoPlay={false} playsInline className="video-height" data-hj-allow-iframe=""></video>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <p>This video is from the 2018 ANRC collegiate horse show. It is from the equitation over fences phase, which had 3 feet jumps. Even though I typically do not enjoy equitation classes, I had a lot of fun doing this with my horse after we had not shown together for a while! If you have any advice about dealing with show nerves, I would love to hear it!</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="mt--5 pt-4 pb-6 bg-white">
                    <Container fluid>
                        <Row className="justify-content-center text-center">
                            <Col md="6">
                                <div className="justify-content-center text-center">
                                    <h2 className="py-3 main-title">
                                        <strong><span className="text-dark">Clinician Feedback</span></strong>
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="container accordian-container">
                                    <div>
                                        <div className="accordion mt-5">
                                            <Card className="card-frame">
                                                <CardHeader role="tab" className="justify-content-between align-items-center d-flex" onClick={() => collapsesToggle("collapseOne")} aria-expanded={openedCollapses.includes("collapseOne")}>
                                                    <h5 className="text-info">Winn Alden</h5>
                                                    <i className="ni ni-bold-down"></i>
                                                </CardHeader>
                                                <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseOne")}>
                                                    <CardBody>
                                                        <ListGroup>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Technical Feedback</CardTitle>
                                                                <CardText>
                                                                    One little thing I’d like to see to raise the score of this round is a little more power in the canter to first jump. 
                                                                    Not faster but a more 3-beat canter; it’s almost a 4-beat canter. 
                                                                    The first lead change is a couple strides late. I would deduct relative to rest of the course and the class. 
                                                                    In this case, it’s not a killer. I love the short reins, long arm! 
                                                                    You definitely need more weight in the heel; I feel that you may be pinching on your knee a bit. 
                                                                    The biggest thing for this particular round is that jump distances are a bit inconsistent.  
                                                                    Little long, little short. This may be caused by moments of a little nerves but I feel like the rider remained calm and didn’t let her nerves get the better of her.  
                                                                    Looks like the pair are a little worried about the combination and the best thing to do is to practice them in lessons and even poles on the ground. 
                                                                    A great thing to do would be to practice riding on the flat and even doing poles and cavalettis with stirrups one or two holes longer.  
                                                                    As funny as it may feel at first, you would be surprised at how much stronger you will feel and more in control of your body. 
                                                                    Also doing a line of 5 or 6 jumps with any combination of bounce strides, one strides and 2 strides will enable the rider to focus on nothing but jumping into the line and then just allowing her body to follow horse.
                                                                    Let the horse and jumps do the rest. If the horse gets quick at all with that many jumps in a row, start with less. 
                                                                    Start with just trotting the rails on ground. I also live for circle exercises over jumps set on circle at 12,3,6 and 9 like on a clock. 
                                                                    You go around several times each direction practicing a smooth pace (trot or canter) and riding the center of jumps and keeping the horse bent in direction of travel staying on same track. 
                                                                    You also really start to understand what aids in both directions work to keep your horse soft, bent and balanced. 
                                                                    There’s theory of what aids are used but all horses are different.
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Style Feedback</CardTitle>
                                                                <CardText>
                                                                    The horse looks pleasant and happy doing his job. It looks like the pair just needs to fine tune turning and bending lines to smooth things out and as I mentioned earlier, lengthen leg. 
                                                                    I don’t know if it was a course requirement or trainer choice or if others will agree, but I’d like to see a nice finish circle at the end. 
                                                                    I feel like you only pull right up if it’s called for in a test or a handy class. 
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline" color="primary">
                                                                    Turnout Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    The horse and rider are both turned out beautifully. It is equitation so a pair of leather boots are always nice but not required and I can’t find fault with the turnout of this pair.
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">
                                                                    Other Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    Having confidence in your horse is a lot of just believing. You have to believe and know that if you don’t let doubts in your mind, it will translate to the horse and it’s amazing what you can will yourself and horse to do together. 
                                                                    Build confidence by gradually testing you and your horse. 
                                                                    Don’t be afraid to make a mistake, don’t hold it against the horse and don’t over react. 
                                                                    Sometimes just acting like something is not a big deal(even if it is), will keep horse relaxed and make progress easier. 
                                                                    One trick I do too when I’m extra nervous or know I’m having a nice round, I shove my heels down a little extra, take a slow blink and tell myself something like "keep both legs on", "look up" or any little reminder that can make a big difference. 
                                                                    Yes, I’m nervous for one reason or another every time I walk I to ring no matter the class. If I wasn’t, I wouldn’t care.
                                                                </CardText>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                            <Card className="card-plain">
                                                <CardHeader role="tab" className="justify-content-between align-items-center d-flex" onClick={() => collapsesToggle("collapseTwo")} aria-expanded={openedCollapses.includes("collapseTwo")}>
                                                    <h5 className="text-info">Chris Wynne</h5>
                                                    <i className="ni ni-bold-down"></i>
                                                </CardHeader>
                                                <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseTwo")}>
                                                    <CardBody>
                                                        <ListGroup>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Technical Feedback</CardTitle>
                                                                <CardText>
                                                                    Basically course was ridden well. The things as a judge that stuck out to me were the stalking of jump 3 and riding past the distance after the in and out. 
                                                                    These were mistakes but not major. Probably puts the score in the 74-78 range depending on the how the other riders have been scored. 
                                                                    This is a nice horse and a rider that has good style. The horse appears to be different off the right lead versus the left. Stronger on the right. 
                                                                    The thing that needs to happen is that the jumps match off both leads in the equitation and the hunter ring.
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Style Feedback</CardTitle>
                                                                <CardText>
                                                                    Horse and rider style as I said earlier are both very nice. 
                                                                    The small thing I would like to see is the horse use both leads. But in saying that, I think the rider realizes she has more options and stride when the horse lands right. 
                                                                    Meaning that even though the style may be a little off by landing right the majority of the time, it probably makes the whole course match better by the stride staying more consistent.  
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline" color="primary">
                                                                    Turnout Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    Both are turned out perfectly.
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">
                                                                    Other Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    The exercise that I would suggest is cantering oxer to oxer lines off of short turns. 
                                                                    This should make the rider keep cantering to the jumps which seems to be the weakness on occasion. 
                                                                    The line should be set on the 12 foot stride and preferably 6-7 strides long.
                                                                </CardText>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                            <Card className="card-plain">
                                                <CardHeader role="tab" className="justify-content-between align-items-center d-flex" onClick={() => collapsesToggle("collapseThree")} aria-expanded={openedCollapses.includes("collapseThree")}>
                                                    <h5 className="text-info">Mike Rosser</h5>
                                                    <i className="ni ni-bold-down"></i>
                                                </CardHeader>
                                                <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseThree")}>
                                                    <CardBody>
                                                        <ListGroup>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Technical Feedback</CardTitle>
                                                                <CardText>
                                                                    A nice trip. Very smooth, but the second half course was not as good as the first half. 
                                                                    In my opinion, the rider got tired as the trip went. 
                                                                    She just needs to keep working at it, and I think she will get stronger. 
                                                                    She has a good riding future ahead for her.
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Style Feedback</CardTitle>
                                                                <CardText>
                                                                I loved the horse, a nice jumper, good mover. The rider has a good eye for the jumps. She need to work on not gripping with her knees and more weight in her heels.  
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline" color="primary">
                                                                    Turnout Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    Great turn out of  horse and rider. I would not change a thing!
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">
                                                                    Other Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    Keep working,  she has a great future in her riding.
                                                                </CardText>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                            <Card className="card-plain">
                                                <CardHeader role="tab" className="justify-content-between align-items-center d-flex" onClick={() => collapsesToggle("collapseFour")} aria-expanded={openedCollapses.includes("collapseFour")}>
                                                    <h5 className="text-info">Teddi Ismond</h5>
                                                    <i className="ni ni-bold-down"></i>
                                                </CardHeader>
                                                <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseFour")}>
                                                    <CardBody>
                                                        <ListGroup>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Technical Feedback</CardTitle>
                                                                <CardText>
                                                                Course was executed well. I would suggest that you look earlier for your roll back turns to help you stay on track better, which would make the roll back turn neater. 
                                                                Also as you came out of the turn to your second to last jump, make sure you use outside leg more which would keep your horse coming out of his turn better, then the distance would have come up a bit better specially when you are going away from the ingate. 
                                                                Good job overall on keeping your rhythm smooth to the jumps.
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Style Feedback</CardTitle>
                                                                <CardText>
                                                                The first thing that I saw was it looks like you could go down a hole with your stirrups. 
                                                                The angle of your lower leg is too far back which pitches you into your knee. 
                                                                When you go to leave for a longer jump make sure you sink into the saddle instead of jumping ahead of the motion (that may come from your stirrup being too short). 
                                                                Upper body and arm were nice. Executed well!  
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline" color="primary">
                                                                    Turnout Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    Your turn out was very nice, good job! 
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">
                                                                    Other Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    
                                                                </CardText>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                            <Card className="card-plain">
                                                <CardHeader role="tab" className="justify-content-between align-items-center d-flex" onClick={() => collapsesToggle("collapseFive")} aria-expanded={openedCollapses.includes("collapseFive")}>
                                                    <h5 className="text-info">Mary O'Connor</h5>
                                                    <i className="ni ni-bold-down"></i>
                                                </CardHeader>
                                                <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseFive")}>
                                                    <CardBody>
                                                        <ListGroup>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Technical Feedback</CardTitle>
                                                                <CardText>
                                                                    Analysis of round: Opening circle—poop! Right at the canter transition. Unfortunate. 
                                                                    Initial pace ends up being a little sedate… long approach to Jump 1 was a missed opportunity to do some pace adjusting there, but it maintained at quiet. 
                                                                    Jump 1, was ok but your pace was slow, landing was quiet and on the counter lead - your horse fell to the inside of the turn, and stayed quiet to your leg through the turn to the lead change - which was a couple steps late behind but did happen (phew, I had my doubts it would). 
                                                                    Jump 2 showed up ok, pretty much on stride, landing again on the right lead. This time was a good thing because Jump 3 was on a broken line off a right bend. 
                                                                    You kept flowing down the 9 strides to the Swedish Jump 3 and got there just right, holding a decent pace on the turn to the next. 
                                                                    Slight but noticeable adjustment out in front of the gate/Jump 4, but both of you are on the same page for that obstacle. 
                                                                    Again the horse lands the right lead, another lead change needed. 
                                                                    This one, off the better pace works out nicely. Wondering if maybe you got preoccupied with that change, because here I think you were a beat late with your eyes in planning this turn; 
                                                                    a little half halt taken on the far side took three strides to execute, and left you with only three more strides to the jump, which put you in front of the arches/Jump 5 with a decent distance but a pace that caused the jump to be more effort than flow over that oxer. 
                                                                    Landing on the left lead afterward gave you a nice, uninterrupted turn toward the in and out off a decent pace (heading home makes that easier). 
                                                                    Jumps 6 AB to 7 were your nicest three of the course, really nothing to critique there, those are the ones we want every time! 
                                                                    (In fact, out of that pace, they actually might turn up that way much more often). 
                                                                    You held that good pace until you were out in front of Jump 8, when it looks like you looked, but didn’t see that you were right where you should have been, just needed to keep on going! 
                                                                    Instead you took back, for the chip! 
                                                                    That long, weaker than it needed to be Jump 9 was the right distance, out of the wrong (too tentative) pace! 
                                                                    A direct line to the gate from the last jump is not my favorite way to conclude a round, but since I can't know whether this was required, so I'll leave that aside. 
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Style Feedback</CardTitle>
                                                                <CardText>
                                                                Your position is quite good throughout; what's most important about "style" however, is whether it's fully functional. 
                                                                At times during this round, form was preserved at the expense of function. 
                                                                (More below in "other feedback").   
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline" color="primary">
                                                                    Turnout Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    Super turnout--first and lasting impression is "this rider cares, this horse is well taken care of, and no detail is overlooked!"  
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">
                                                                    Other Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    My impression (only based on one video that started with 'poop!' - so I wouldn't be surprised if next time, without that slow start you might nail it the very next round...) is that your horse might be habitually a beat behind your aids. 
                                                                    Sometimes a rider can be TOO thoughtful about the mechanics of riding, worrying too much about position and forget to analyze what's happening in real time. 
                                                                    Keeping it simple, there are three main considerations when riding: Pace, Track, and Balance. 
                                                                    I would suggest exercises concentrating on each of these three things. 
                                                                    Transitions (asking and expecting a prompt response to both upward and downward transitions) are most important in establishing pace. 
                                                                    Once pace is established, defining an exact track (as opposed to just doing laps at whatever gait you're working at) and maintaining a consistent pace is more difficult than it sounds. 
                                                                    (If it was easy, no one would ever miss a distance!) 
                                                                    But drill down on pace control, along with track, and relax about your position (it's good, you can always go back to it, but don't worry about it for now!) 
                                                                    Work over rails on the ground (randomly, just scatter them everywhere) to get really familiar and develop muscle memory about short approaches, long approaches, angled, bending, oblique, and direct approaches out of VARIOUS paces (slow, fast, medium, collected, extended) and be able to keep the same tempo no matter what adjustments you need to make. 
                                                                    A miss to a rail on the ground is no harm, no foul. 
                                                                    The more you do, the better you'll get at it, and it WILL translate to jumps. 
                                                                    But remember that it all starts with transitions, and your horse's response to your aids. 
                                                                    A trainer can say "it looks too slow," but only the rider really knows for how long the horse failed to respond to an aid--the rider is the one who has to fix it!
                                                                </CardText>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                            <Card className="card-plain">
                                                <CardHeader role="tab" className="justify-content-between align-items-center d-flex" onClick={() => collapsesToggle("collapseSix")} aria-expanded={openedCollapses.includes("collapseSix")}>
                                                    <h5 className="text-info">Sissy Wickes</h5>
                                                    <i className="ni ni-bold-down"></i>
                                                </CardHeader>
                                                <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseSix")}>
                                                    <CardBody>
                                                        <ListGroup>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Technical Feedback</CardTitle>
                                                                <CardText>
                                                                    My first impression of this rider is that her position needs to be adjusted. 
                                                                    Her leg is too far behind the girth which results in her upper body being too far forward and her heel "catching" on the horse's side. 
                                                                    I would like to see her leg in a more forward position which would allow her back to relax and her weight to be in the middle of the horse. 
                                                                    Because she is ahead of the motion and her leg is fixated on the horse's side, she has limited ability to shorten the horse's stride which resulted in the chip at fence #7, an oxer. 
                                                                    Overall, the rider did a nice job navigating a twisty course. 
                                                                    She trusted her horse and kept a great rhythm. 
                                                                    A few minor position changes and a lot of flat work practice could lead to great results
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">Style Feedback</CardTitle>
                                                                <CardText>
                                                                    The rider rides in nice style. 
                                                                    Again, I would make some position changes. 
                                                                    But, in general, she stays off of the horse's back, her hands are quiet, she has a nice release over the jump, and she keeps her eye ahead of her on course and in the air. 
                                                                    I quite like her style.
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline" color="primary">
                                                                    Turnout Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    Perfectly turned out: clean, well fitting clothes, well fitting tack. Well done!  
                                                                </CardText>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <CardTitle className="text-underline">
                                                                    Other Feedback
                                                                </CardTitle>
                                                                <CardText>
                                                                    I would recommend a few weeks of intensive flatwork both without stirrups and with stirrups working on the two point. 
                                                                    The rider needs to get her lower leg in front of her and learn how to use it to balance herself and the horse. 
                                                                    She has plenty of natural talent and now needs to work on the finer points. 
                                                                    A very nice video!
                                                                </CardText>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section mt-3" id="testimonials">
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col md="6">
                                <div className="justify-content-center text-center">
                                    <h2 className="py-3 main-title">
                                        <strong><span className="text-dark">Rider Testimonials</span></strong>
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Chelsea Kulp
                                        </h4>
                                        <p className="description mt-3">
                                        “I can’t recommend RingSide Pro enough! My clinician provided invaluable feedback within 24 hours! I learned so much about our trip through the detailed, jump by jump feedback I received.”
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Kiki Konopnicki
                                        </h4>
                                        <p className="description mt-3">
                                            “The feedback was specific and helpful, giving me concrete and constructive criticism to improve my riding. Clinics Pro is an incredibly useful training tool that will certainly help any rider improve.”
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Alex Indeglia
                                        </h4>
                                        <p className="description mt-3">
                                        “It was so easy to send in a video, and I got my feedback almost immediately!  I loved that I not only got feedback on my riding, but I also received great training exercises for my horse. I’ve already seen an improvement!”
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section mt-3">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Ready to submit a video?
                                        </h4>
                                        <p className="mt-3">
                                            You can submit a video for <strong className="text-bold">under $40</strong>. <Link to="/auth/register" className="link text-info">Create an Account</Link>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
}

export default SampleFeedbackPage;
