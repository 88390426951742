import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol, 
    IonContent, 
    IonHeader,  
    IonPage, 
    IonRow, 
    IonText
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Card, CardBody, Container } from "reactstrap";
import DisplayEventTable from "../../components/Event/DisplayEventTable";
import Footer from "../../components/Footers/Footer";
import WebNavbar from "../../components/Navbars/WebNavbar";
import {Link} from "react-router-dom";
import EventSearchBar from "../../components/Search/EventSearchBar";
import PaginationButtons from "../../components/Search/PaginationButtons";
import { AlgoliaEvent } from "../../interfaces/AlgoliaEvent";
import logo from "./../../assets/img/brand/test2.png";
import katieC from "../../assets/img/general/KatieC.png";
import stars from "../../assets/img/general/stars.png";
import PartnersCarousel from "../../components/Carousel/PartnersCarousel";


const WebHomePage: React.FC = () => {
    const history = useHistory();

    const [eventsList, setEventsList] = useState<AlgoliaEvent[] | null | undefined>();
    const [selectedFilter, setSelectedFilter] = useState<"past" | "current" | "future" | "all">("all");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const handleSearchResults = (results: AlgoliaEvent[]) => {
        setEventsList(results);
    };

    const handlePageReset = (page: number, totalPages: number) => {
        setCurrentPage(page);
        setTotalPages(totalPages);
    };

    const handlePageSelection = (page: number) => {
        setCurrentPage(page);
    };

    const handleSelectedEvent = (event: AlgoliaEvent) => {
        const path = "/show/" + event.objectID;
        history.push(path);
    };

    const handleSelectedFilter = (selection: "past" | "current" | "future" | "all") => {
        setSelectedFilter(selection);
    };

    return (
        <IonPage id="webHomePage">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                <section className="bg-dark">
                    <IonRow className="bg-white ion-justify-content-center py-2">
                        <p className="ion-text-center ion-wrap-text">Need to communicate across your barn, team or association? Our text alerts can help! <a className="link" href="/text-alerts#org-alerts">Learn More!</a></p>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                                <IonCol sizeXs="6" sizeSm="4" sizeMd="3" className="ion-text-center">
                                    <img
                                        className="img"
                                        alt="RingSide Pro"
                                        src={logo}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                                <IonCol size="12" className="ion-text-center">
                                    <IonText><h3 className="font-weight-bold text-white">Be ringside, wherever you are.</h3></IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeSm="8" sizeMd="8" sizeXl="6"> 
                                    <EventSearchBar selectedPage={currentPage} onChangePage={handlePageReset} selectedFilter={selectedFilter} onSearch={handleSearchResults} />
                                    <IonRow>
                                        <IonCol>
                                            <IonCard mode="md" className="ion-padding bg-white">
                                                <IonCardContent>
                                                    <DisplayEventTable eventsList={eventsList} onSelect={handleSelectedEvent} onFilter={handleSelectedFilter} />
                                                    <PaginationButtons totalPageCount={totalPages} currentPage={currentPage} onSelectPage={handlePageSelection} />
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-dark">Our Services</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeLg="4">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Online Entries
                                        </h4>
                                        <p className="description mt-3">
                                            Affordable online entries for events of all sizes. Create your event schedule, accept entries, track results and print reports. <a className="link" href="/online-entries">Learn more</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeLg="4">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-dark text-uppercase">
                                            Text Alerts
                                        </h4>
                                        <p className="description mt-3">
                                            Reliable and Easy to Use. Show organizers send announcements via text message. Show participants sign up for free. <a className="link" href="/text-alerts">Learn more</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeLg="4">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-light text-uppercase">
                                            Online Clinics
                                        </h4>
                                        <p className="description mt-3">
                                            Elevate your riding. Our clinicians are ready to help you reach your goals. Discover the reasonable prices and world-class instructors. <a className="link" href="/online-clinics">Learn more</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-dark">A Few of Our Clients</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <PartnersCarousel />
                    </Container>
                </section>
                <section id="testimonials" className="py-5 bg-light overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="6" sizeSm="8" sizeMd="3" offsetMd="1" className="ion-text-center">
                                <img
                                    className="img"
                                    alt="Tesimonial"
                                    src={katieC}
                                />
                            </IonCol>
                            <IonCol sizeXs="10" sizeSm="8" sizeMd="6" className="ion-text-center">
                                <IonRow>
                                    <IonCol sizeXs="12" sizeSm="8" offsetSm="2" sizeMd="8" offsetMd="2" className="ion-text-left">
                                        <IonCard color="white" className="ion-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <img
                                                        className="img"
                                                        alt="rating"
                                                        src={stars}
                                                    />
                                                </IonCol>
                                            </IonRow>
                                            <IonText>
                                                <h2 className="font-weight-bold text-dark">
                                                    "I implore show management to please utilize this incredible resource."
                                                </h2>
                                            </IonText>
                                            <IonText>
                                                <h3 className="font-weight-bold text-dark">
                                                    - Katie Cooper
                                                </h3>
                                                <p className="font-weight-bold text-dark">
                                                    Trainer, Professional Rider
                                                </p>
                                            </IonText>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-white">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText>
                                    <h2 className="font-weight-bold text-dark">Text Alerts</h2>
                                    <h4 className="font-weight-bold text-dark">For Barns, Teams, Associations and More</h4>
                                    <p className="text-dark">Our signature text message alert service that has been used by 100+ horse shows can now also be used for your barn, team or association!</p>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeLg="4">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-dark text-uppercase">
                                            Freedom Farm
                                        </h4>
                                        <p className="description mt-3">
                                            A boarding and show barn located in Virginia. With ~50 subscribers, they send mass text message alerts with info about when the vet and farrier are coming, horse show packing lists, and barn party announcements! "This service saves me time and helps me ensure I never leave someone out!" - Laura W. (Barn Manager)
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeLg="4">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Los Angeles Equestrian Center
                                        </h4>
                                        <p className="description mt-3">
                                            A large property with ~500 subscribers. They send alerts to specific groups using the "filters" built into the system. They can easily send alerts to all trainers or all boarders in Barn B. 
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeLg="4">
                                <a href="/gitnb">
                                    <Card className="shadow border-0" href="/gitnb">
                                        <CardBody className="py-5">
                                            <h4 className="h3 text-success text-uppercase">
                                                Green Is The New Blue
                                            </h4>
                                            <p className="description text-dark mt-3">
                                                A nonprofit organization with ~1,000 subscribers. They use our text alerts to offer Green ProTips, a text message service that delivers tips and action items to eco-minded equestrians with the goal of reducing the environmental impacts of equine-related activities. 
                                            </p>
                                        </CardBody>
                                    </Card>
                                </a>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-dark py-3">
                            <IonCol size="12" className="ion-text-center">
                                <a href="/text-alerts">
                                    <IonButton color="success">Learn More</IonButton>
                                </a>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-dark">
                    <Container>
                        <IonRow className="justify-content-center">
                            <IonCol size="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-dark text-center text-uppercase">
                                            Ready to get started?
                                        </h4>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="6" sizeXl="3" offsetXl="3">
                                                <p className="text-center">
                                                    <Link to="/auth/register" className="link text-info">Create an Account</Link>
                                                </p>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6" sizeXl="3" >
                                                <p className="text-center">
                                                    <Link to="/auth/login" className="link text-info">Log In</Link>
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                        <hr />
                                        <IonRow>
                                            <IonCol size="12">
                                                <p className="text-center">
                                                    If you would like to support RingSide Pro, <a className="link" href="https://www.buymeacoffee.com/ringsidepro">click here.</a>
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default WebHomePage;
